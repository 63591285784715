export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: unknown;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: unknown;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: unknown;
  /** The day, does not include a time. */
  Date: unknown;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** An IPv4 or IPv6 host address, and optionally its subnet. */
  InternetAddress: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: unknown;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: unknown;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
  Uuid: string;
};

export type AccountDto = {
  __typename?: 'AccountDto';
  city?: Maybe<Scalars['String']>;
  complianceCredential?: Maybe<ComplianceCredentialDto>;
  country?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  isGrowFlow: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isSandbox: Scalars['Boolean'];
  isStandAlone: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationDto>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorDto>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AccountDtoConnection = {
  __typename?: 'AccountDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AccountDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AccountDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountDtoEdge = {
  __typename?: 'AccountDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AccountDto;
};

export type AccountFilterInput = {
  and?: InputMaybe<Array<AccountFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  complianceCredential?: InputMaybe<ComplianceCredentialFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isDisabled?: InputMaybe<BooleanOperationFilterInput>;
  isGrowFlow?: InputMaybe<BooleanOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<BooleanOperationFilterInput>;
  isStandAlone?: InputMaybe<BooleanOperationFilterInput>;
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  line1?: InputMaybe<StringOperationFilterInput>;
  line2?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountFilterInput>>;
  organization?: InputMaybe<AllOrganizationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postalCode?: InputMaybe<StringOperationFilterInput>;
  region?: InputMaybe<StringOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  vendor?: InputMaybe<VendorFilterInput>;
  vendorId?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type AccountRoleDto = {
  __typename?: 'AccountRoleDto';
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type AccountRolesFilterInput = {
  accounts?: InputMaybe<ListFilterInputTypeOfAccountUserFilterInput>;
  and?: InputMaybe<Array<AccountRolesFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  groupName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountRolesFilterInput>>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type AccountSortInput = {
  city?: InputMaybe<SortEnumType>;
  complianceCredential?: InputMaybe<ComplianceCredentialSortInput>;
  country?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isDisabled?: InputMaybe<SortEnumType>;
  isGrowFlow?: InputMaybe<SortEnumType>;
  isInactive?: InputMaybe<SortEnumType>;
  isPaid?: InputMaybe<SortEnumType>;
  isStandAlone?: InputMaybe<SortEnumType>;
  isVerified?: InputMaybe<SortEnumType>;
  line1?: InputMaybe<SortEnumType>;
  line2?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organization?: InputMaybe<AllOrganizationSortInput>;
  phone?: InputMaybe<SortEnumType>;
  postalCode?: InputMaybe<SortEnumType>;
  region?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  vendor?: InputMaybe<VendorSortInput>;
  vendorId?: InputMaybe<SortEnumType>;
};

export type AccountUserDto = {
  __typename?: 'AccountUserDto';
  account?: Maybe<AccountDto>;
  homeModule?: Maybe<Scalars['String']>;
  isDisabled: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<Maybe<AccountRoleDto>>>;
  user?: Maybe<UserDto>;
};

export type AccountUserFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<AccountUserFilterInput>>;
  homeModule?: InputMaybe<StringOperationFilterInput>;
  isDisabled?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AccountUserFilterInput>>;
  roles?: InputMaybe<ListFilterInputTypeOfAccountRolesFilterInput>;
  user?: InputMaybe<UserFilterInput>;
};

export type AccountUserSortInput = {
  account?: InputMaybe<AccountSortInput>;
  homeModule?: InputMaybe<SortEnumType>;
  isDisabled?: InputMaybe<SortEnumType>;
  lastLogin?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
};

export type Activity = {
  __typename?: 'Activity';
  Action?: Maybe<Scalars['String']>;
  EnvName?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  Timestamp?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
};

export type AddNonCannabisCostComponentInput = {
  license?: InputMaybe<CannabisLicenseInput>;
  name?: InputMaybe<Scalars['String']>;
  nonCannabisSources?: InputMaybe<Array<InputMaybe<NonCannabisSourceInput>>>;
  package?: InputMaybe<PackageReferenceInput>;
  product?: InputMaybe<ProductReferenceInput>;
  recipe?: InputMaybe<RecipeReferenceInput>;
  uOM?: InputMaybe<Scalars['String']>;
  unitRatio: Scalars['Decimal'];
};

export type AddPlantsToHarvestInput = {
  harvest?: InputMaybe<HarvestReferenceInput>;
  license: CannabisLicenseInput;
};

export type AddUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type AdjustPackageInput = {
  adjustmentQuantity: Scalars['Decimal'];
  adjustmentReason?: InputMaybe<Scalars['String']>;
  adjustmentTypeId: Scalars['Int'];
  complianceId?: InputMaybe<Scalars['String']>;
  inventoryId: Scalars['Uuid'];
  license?: InputMaybe<CannabisLicenseInput>;
  newQuantity: Scalars['Decimal'];
  wholesaleInventoryId: Scalars['Int'];
};

export type AllAccountDto = {
  __typename?: 'AllAccountDto';
  complianceCredential?: Maybe<ComplianceCredentialDto>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<AllVendorDto>;
};

export type AllOrganizationDto = {
  __typename?: 'AllOrganizationDto';
  accounts?: Maybe<Array<Maybe<AllAccountDto>>>;
  accountsCount: Scalars['Int'];
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
  organizationStatus: OrganizationStatus;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePlanKey?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type AllOrganizationDtoConnection = {
  __typename?: 'AllOrganizationDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AllOrganizationDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AllOrganizationDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AllOrganizationDtoEdge = {
  __typename?: 'AllOrganizationDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AllOrganizationDto;
};

export type AllOrganizationFilterInput = {
  accounts?: InputMaybe<ListFilterInputTypeOfAccountFilterInput>;
  and?: InputMaybe<Array<AllOrganizationFilterInput>>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AllOrganizationFilterInput>>;
  organizationKey?: InputMaybe<StringOperationFilterInput>;
  organizationStatus?: InputMaybe<OrganizationStatusOperationFilterInput>;
  primaryEmail?: InputMaybe<StringOperationFilterInput>;
  primaryPhone?: InputMaybe<StringOperationFilterInput>;
  stripeCustomerId?: InputMaybe<StringOperationFilterInput>;
  stripePlanKey?: InputMaybe<StringOperationFilterInput>;
  stripeSubscriptionId?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type AllOrganizationSortInput = {
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isInactive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organizationKey?: InputMaybe<SortEnumType>;
  organizationStatus?: InputMaybe<SortEnumType>;
  primaryEmail?: InputMaybe<SortEnumType>;
  primaryPhone?: InputMaybe<SortEnumType>;
  stripeCustomerId?: InputMaybe<SortEnumType>;
  stripePlanKey?: InputMaybe<SortEnumType>;
  stripeSubscriptionId?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type AllUserDto = {
  __typename?: 'AllUserDto';
  createTimestamp?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isGrowFlow: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<UserRoleDto>>>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  userName?: Maybe<Scalars['String']>;
};

export type AllVendorDto = {
  __typename?: 'AllVendorDto';
  code?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  jurisdiction?: Maybe<JurisdictionDto>;
  licenseNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AllocateCostToPlantGroupInput = {
  allocatedBy?: InputMaybe<Scalars['String']>;
  cost: Scalars['Decimal'];
  costDescription?: InputMaybe<Scalars['String']>;
  costType?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  license: CannabisLicenseInput;
  phase: GrowthPhase;
  plantGroup?: InputMaybe<PlantGroupReferenceInput>;
};

export type ApiRequest = Node & {
  __typename?: 'ApiRequest';
  body?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  direction: ApiRequestType;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Transaction` that is related to this `ApiRequest`. */
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ApiRequest` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ApiRequestCondition = {
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `direction` field. */
  direction?: InputMaybe<ApiRequestType>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

export enum ApiRequestType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

/** A connection to a list of `ApiRequest` values. */
export type ApiRequestsConnection = {
  __typename?: 'ApiRequestsConnection';
  /** A list of edges which contains the `ApiRequest` and cursor to aid in pagination. */
  edges: Array<ApiRequestsEdge>;
  /** A list of `ApiRequest` objects. */
  nodes: Array<ApiRequest>;
  /** The count of *all* `ApiRequest` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ApiRequest` edge in the connection. */
export type ApiRequestsEdge = {
  __typename?: 'ApiRequestsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ApiRequest` at the end of the edge. */
  node: ApiRequest;
};

/** Methods to use when ordering `ApiRequest`. */
export enum ApiRequestsOrderBy {
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DirectionAsc = 'DIRECTION_ASC',
  DirectionDesc = 'DIRECTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type ArcCore_Jurisdiction = {
  __typename?: 'ArcCore_Jurisdiction';
  Id: Scalars['Int'];
  IsActive?: Maybe<Scalars['Boolean']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  RegionAbbreviation?: Maybe<Scalars['String']>;
  RegionName?: Maybe<Scalars['String']>;
};

export type ArchiveOrganizationInput = {
  key: Scalars['String'];
  unArchive?: InputMaybe<Scalars['Boolean']>;
};

export type Auth0AppGlobalProfile = {
  __typename?: 'Auth0AppGlobalProfile';
  analytics_userid?: Maybe<Scalars['String']>;
};

export type Auth0AppMetadata = {
  __typename?: 'Auth0AppMetadata';
  global?: Maybe<Auth0AppGlobalProfile>;
};

export type Auth0Identity = {
  __typename?: 'Auth0Identity';
  connection?: Maybe<Scalars['String']>;
  isSocial?: Maybe<Scalars['Boolean']>;
  profileData?: Maybe<Auth0UserIdentifyProfile>;
  provider?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Auth0User = {
  __typename?: 'Auth0User';
  app_metadata?: Maybe<Auth0AppMetadata>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  identities?: Maybe<Array<Maybe<Auth0Identity>>>;
  last_ip?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['String']>;
  logins_count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_metadata?: Maybe<Auth0UserMetadata>;
};

export type Auth0UserIdentifyProfile = {
  __typename?: 'Auth0UserIdentifyProfile';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Auth0UserMetadata = {
  __typename?: 'Auth0UserMetadata';
  user_profile?: Maybe<Auth0UserProfile>;
};

export type Auth0UserProfile = {
  __typename?: 'Auth0UserProfile';
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type BatchFilterInput = {
  and?: InputMaybe<Array<BatchFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPartialHarvest?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BatchFilterInput>>;
  roomId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type BatchSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isInactive?: InputMaybe<SortEnumType>;
  isPartialHarvest?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  roomId?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type BillingPortalLink = {
  __typename?: 'BillingPortalLink';
  url: Scalars['String'];
};

export type BillingStatus = {
  __typename?: 'BillingStatus';
  daysPastDue?: Maybe<Scalars['Int']>;
  failedPaymentAttempts?: Maybe<Scalars['Int']>;
  gracePeriod?: Maybe<GracePeriod>;
  hasActiveSubscription?: Maybe<Scalars['Boolean']>;
  hasPaymentMethod?: Maybe<Scalars['Boolean']>;
  lastPayment?: Maybe<Scalars['Datetime']>;
  lockoutStatus?: Maybe<LockoutStatus>;
  message?: Maybe<Scalars['String']>;
  subscriptionBillingStatus?: Maybe<SubscriptionBillingStatus>;
  totalAmountDue?: Maybe<Scalars['Int']>;
  trialEnd?: Maybe<Scalars['Datetime']>;
  trialStart?: Maybe<Scalars['Datetime']>;
  unpaidInvoiceCount?: Maybe<Scalars['Int']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type BrandFilterInput = {
  and?: InputMaybe<Array<BrandFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BrandFilterInput>>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type BrandSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type CannabisLicense = {
  __typename?: 'CannabisLicense';
  licenseNum?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CannabisLicenseInput = {
  licenseNum?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CategoryFilterInput = {
  and?: InputMaybe<Array<CategoryFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CategoryFilterInput>>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type CategorySortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export enum CcrsApplicationScope {
  Both = 'BOTH',
  Retail = 'RETAIL',
  Unknown = 'UNKNOWN',
  Wholesale = 'WHOLESALE'
}

export type CcrsApplicationScopeOperationFilterInput = {
  eq?: InputMaybe<CcrsApplicationScope>;
  in?: InputMaybe<Array<CcrsApplicationScope>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<CcrsApplicationScope>;
  nin?: InputMaybe<Array<CcrsApplicationScope>>;
};

export type CcrsCsvUploadDto = {
  __typename?: 'CcrsCsvUploadDto';
  applicationScope: CcrsApplicationScope;
  ccrsManifestUpload?: Maybe<CcrsManifestUploadDto>;
  ccrsManifestUploadId?: Maybe<Scalars['Int']>;
  childCcrsCsvUploads?: Maybe<Array<Maybe<CcrsCsvUploadDto>>>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  csvErrorGoogleCloudLink?: Maybe<Scalars['String']>;
  csvErrorLink?: Maybe<Scalars['String']>;
  csvFileName?: Maybe<Scalars['String']>;
  csvLink?: Maybe<Scalars['String']>;
  csvUploadImageLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  entityName?: Maybe<Scalars['String']>;
  errorType?: Maybe<CcrsCsvUploadErrorType>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isSandBox: Scalars['Boolean'];
  licenseNumber?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  logDirectoryLink?: Maybe<Scalars['String']>;
  logLink?: Maybe<Scalars['String']>;
  numberOfErrorRecords?: Maybe<Scalars['Int']>;
  numberOfRecords: Scalars['Int'];
  parentCcrsCsvUpload?: Maybe<CcrsCsvUploadDto>;
  parentCcrsCsvUploadId?: Maybe<Scalars['Int']>;
  primaryManifestCcrsCsvUpload?: Maybe<CcrsCsvUploadDto>;
  primaryManifestCcrsCsvUploadId?: Maybe<Scalars['Int']>;
  processingDuration: Scalars['Long'];
  reportGeneratedAt: Scalars['DateTime'];
  resultsJsonLink?: Maybe<Scalars['String']>;
  status: SyncStatus;
  statusName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type CcrsCsvUploadDtoConnection = {
  __typename?: 'CcrsCsvUploadDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CcrsCsvUploadDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CcrsCsvUploadDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CcrsCsvUploadDtoEdge = {
  __typename?: 'CcrsCsvUploadDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CcrsCsvUploadDto;
};

export enum CcrsCsvUploadErrorType {
  AuthenticationError = 'AUTHENTICATION_ERROR',
  CcrsFailedProcesingFile = 'CCRS_FAILED_PROCESING_FILE',
  ErrorProcessingFailed = 'ERROR_PROCESSING_FAILED'
}

export type CcrsCsvUploadFilterInput = {
  and?: InputMaybe<Array<CcrsCsvUploadFilterInput>>;
  applicationScope?: InputMaybe<CcrsApplicationScopeOperationFilterInput>;
  ccrsManifestUpload?: InputMaybe<CcrsManifestUploadFilterInput>;
  ccrsManifestUploadId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  childCcrsCsvUploads?: InputMaybe<ListFilterInputTypeOfCcrsCsvUploadFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  csvErrorLink?: InputMaybe<StringOperationFilterInput>;
  csvFileName?: InputMaybe<StringOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  entityName?: InputMaybe<StringOperationFilterInput>;
  errorType?: InputMaybe<NullableOfCcrsCsvUploadErrorTypeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isSandBox?: InputMaybe<BooleanOperationFilterInput>;
  licenseNumber?: InputMaybe<StringOperationFilterInput>;
  log?: InputMaybe<StringOperationFilterInput>;
  logDirectoryLink?: InputMaybe<StringOperationFilterInput>;
  logLink?: InputMaybe<StringOperationFilterInput>;
  numberOfErrorRecords?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  numberOfRecords?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CcrsCsvUploadFilterInput>>;
  parentCcrsCsvUpload?: InputMaybe<CcrsCsvUploadFilterInput>;
  parentCcrsCsvUploadId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  processingDuration?: InputMaybe<ComparableInt64OperationFilterInput>;
  reportGeneratedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<SyncStatusOperationFilterInput>;
  statusName?: InputMaybe<StringOperationFilterInput>;
  transactionId?: InputMaybe<StringOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type CcrsCsvUploadSortInput = {
  applicationScope?: InputMaybe<SortEnumType>;
  ccrsManifestUpload?: InputMaybe<CcrsManifestUploadSortInput>;
  ccrsManifestUploadId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  csvErrorLink?: InputMaybe<SortEnumType>;
  csvFileName?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  entityName?: InputMaybe<SortEnumType>;
  errorType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isSandBox?: InputMaybe<SortEnumType>;
  licenseNumber?: InputMaybe<SortEnumType>;
  log?: InputMaybe<SortEnumType>;
  logDirectoryLink?: InputMaybe<SortEnumType>;
  logLink?: InputMaybe<SortEnumType>;
  numberOfErrorRecords?: InputMaybe<SortEnumType>;
  numberOfRecords?: InputMaybe<SortEnumType>;
  parentCcrsCsvUpload?: InputMaybe<CcrsCsvUploadSortInput>;
  parentCcrsCsvUploadId?: InputMaybe<SortEnumType>;
  processingDuration?: InputMaybe<SortEnumType>;
  reportGeneratedAt?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusName?: InputMaybe<SortEnumType>;
  transactionId?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export enum CcrsFileName {
  Area = 'AREA',
  AreaAccess = 'AREA_ACCESS',
  Inventory = 'INVENTORY',
  InventoryAdjustment = 'INVENTORY_ADJUSTMENT',
  InventoryTransfer = 'INVENTORY_TRANSFER',
  Labtest = 'LABTEST',
  Manifest = 'MANIFEST',
  Plant = 'PLANT',
  PlantDestruction = 'PLANT_DESTRUCTION',
  PlantTransfer = 'PLANT_TRANSFER',
  Product = 'PRODUCT',
  Sale = 'SALE',
  Strain = 'STRAIN',
  StrainsAll = 'STRAINS_ALL',
  Waste = 'WASTE'
}

export type CcrsManifestUploadDto = {
  __typename?: 'CcrsManifestUploadDto';
  applicationScope: CcrsApplicationScope;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isSandBox: Scalars['Boolean'];
  licenseNumber?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  logDirectoryLink?: Maybe<Scalars['String']>;
  logLink?: Maybe<Scalars['String']>;
  manifestId?: Maybe<Scalars['String']>;
  manifestLink?: Maybe<Scalars['String']>;
  manifestPdfLink?: Maybe<Scalars['String']>;
  manifestUploadImageLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  payLoad?: Maybe<Scalars['String']>;
  processingDuration: Scalars['Long'];
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  resultsJsonLink?: Maybe<Scalars['String']>;
  status: SyncStatus;
  statusName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type CcrsManifestUploadDtoConnection = {
  __typename?: 'CcrsManifestUploadDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CcrsManifestUploadDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CcrsManifestUploadDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CcrsManifestUploadDtoEdge = {
  __typename?: 'CcrsManifestUploadDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CcrsManifestUploadDto;
};

export type CcrsManifestUploadFilterInput = {
  and?: InputMaybe<Array<CcrsManifestUploadFilterInput>>;
  applicationScope?: InputMaybe<CcrsApplicationScopeOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isSandBox?: InputMaybe<BooleanOperationFilterInput>;
  licenseNumber?: InputMaybe<StringOperationFilterInput>;
  log?: InputMaybe<StringOperationFilterInput>;
  logDirectoryLink?: InputMaybe<StringOperationFilterInput>;
  logLink?: InputMaybe<StringOperationFilterInput>;
  manifestId?: InputMaybe<StringOperationFilterInput>;
  manifestLink?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CcrsManifestUploadFilterInput>>;
  payLoad?: InputMaybe<StringOperationFilterInput>;
  processingDuration?: InputMaybe<ComparableInt64OperationFilterInput>;
  purchaseOrderNumber?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<SyncStatusOperationFilterInput>;
  statusName?: InputMaybe<StringOperationFilterInput>;
  transactionId?: InputMaybe<StringOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type CcrsManifestUploadSortInput = {
  applicationScope?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isSandBox?: InputMaybe<SortEnumType>;
  licenseNumber?: InputMaybe<SortEnumType>;
  log?: InputMaybe<SortEnumType>;
  logDirectoryLink?: InputMaybe<SortEnumType>;
  logLink?: InputMaybe<SortEnumType>;
  manifestId?: InputMaybe<SortEnumType>;
  manifestLink?: InputMaybe<SortEnumType>;
  payLoad?: InputMaybe<SortEnumType>;
  processingDuration?: InputMaybe<SortEnumType>;
  purchaseOrderNumber?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusName?: InputMaybe<SortEnumType>;
  transactionId?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export enum CcrsReportingCadence {
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export type ChangeLog = {
  __typename?: 'ChangeLog';
  cost: Scalars['Decimal'];
  event?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  quantity: Scalars['Decimal'];
  timestamp: Scalars['DateTime'];
};

export type CombinePackagesInput = {
  inputs?: InputMaybe<Array<InputMaybe<PackageConversionInput>>>;
  inventoryConversionId: Scalars['Int'];
  license?: InputMaybe<CannabisLicenseInput>;
  outputPackage?: InputMaybe<PackageReferenceInput>;
  outputProduct?: InputMaybe<ProductReferenceInput>;
  outputQuantity: Scalars['Decimal'];
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<Scalars['Decimal']>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']>;
  gt?: InputMaybe<Scalars['Uuid']>;
  gte?: InputMaybe<Scalars['Uuid']>;
  in?: InputMaybe<Array<Scalars['Uuid']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Uuid']>;
  lte?: InputMaybe<Scalars['Uuid']>;
  neq?: InputMaybe<Scalars['Uuid']>;
  ngt?: InputMaybe<Scalars['Uuid']>;
  ngte?: InputMaybe<Scalars['Uuid']>;
  nin?: InputMaybe<Array<Scalars['Uuid']>>;
  nlt?: InputMaybe<Scalars['Uuid']>;
  nlte?: InputMaybe<Scalars['Uuid']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableInt64OperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  in?: InputMaybe<Array<Scalars['Long']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  neq?: InputMaybe<Scalars['Long']>;
  ngt?: InputMaybe<Scalars['Long']>;
  ngte?: InputMaybe<Scalars['Long']>;
  nin?: InputMaybe<Array<Scalars['Long']>>;
  nlt?: InputMaybe<Scalars['Long']>;
  nlte?: InputMaybe<Scalars['Long']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComplianceCredentialDto = {
  __typename?: 'ComplianceCredentialDto';
  code?: Maybe<Scalars['String']>;
  complianceId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isSandbox: Scalars['Boolean'];
  licenseNumber?: Maybe<Scalars['String']>;
};

export type ComplianceCredentialFilterInput = {
  and?: InputMaybe<Array<ComplianceCredentialFilterInput>>;
  ccaPassword?: InputMaybe<StringOperationFilterInput>;
  ccaReportingEnabled?: InputMaybe<BooleanOperationFilterInput>;
  ccaStartTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  gFPassword?: InputMaybe<StringOperationFilterInput>;
  gFUsername?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isMetrcMock?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isSandbox?: InputMaybe<BooleanOperationFilterInput>;
  jurisdiction?: InputMaybe<JurisdictionOperationFilterInput>;
  locationId?: InputMaybe<StringOperationFilterInput>;
  metrcUIDisabled?: InputMaybe<BooleanOperationFilterInput>;
  metrcUIPassword?: InputMaybe<StringOperationFilterInput>;
  metrcUIUsername?: InputMaybe<StringOperationFilterInput>;
  oldBtPassword?: InputMaybe<StringOperationFilterInput>;
  oldBtUsername?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ComplianceCredentialFilterInput>>;
  password?: InputMaybe<StringOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  username?: InputMaybe<StringOperationFilterInput>;
};

export type ComplianceCredentialSortInput = {
  ccaPassword?: InputMaybe<SortEnumType>;
  ccaReportingEnabled?: InputMaybe<SortEnumType>;
  ccaStartTime?: InputMaybe<SortEnumType>;
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  gFPassword?: InputMaybe<SortEnumType>;
  gFUsername?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isMetrcMock?: InputMaybe<SortEnumType>;
  isSandbox?: InputMaybe<SortEnumType>;
  jurisdiction?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  metrcUIDisabled?: InputMaybe<SortEnumType>;
  metrcUIPassword?: InputMaybe<SortEnumType>;
  metrcUIUsername?: InputMaybe<SortEnumType>;
  oldBtPassword?: InputMaybe<SortEnumType>;
  oldBtUsername?: InputMaybe<SortEnumType>;
  password?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  username?: InputMaybe<SortEnumType>;
};

export type ComplianceProviderDto = {
  __typename?: 'ComplianceProviderDto';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type ComplianceProviderFilterInput = {
  and?: InputMaybe<Array<ComplianceProviderFilterInput>>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ComplianceProviderFilterInput>>;
  shortName?: InputMaybe<StringOperationFilterInput>;
};

export type ComplianceProviderSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  shortName?: InputMaybe<SortEnumType>;
};

export type ConversionInput = {
  inventoryConversionSourceId: Scalars['Int'];
  quantityUsed: Scalars['Decimal'];
  wholesaleInventoryId: Scalars['Int'];
};

export type ConversionSourceInput = {
  __typename?: 'ConversionSourceInput';
  costUsed: Scalars['Decimal'];
  inventoryConversionSourceId: Scalars['Int'];
  quantityUsed: Scalars['Decimal'];
};

export type ConversionsView = {
  __typename?: 'ConversionsView';
  id: Scalars['Uuid'];
  sourceInputs?: Maybe<Array<Maybe<ConversionSourceInput>>>;
  wholesaleInventoryId: Scalars['Int'];
};

export type ConvertToRetailPackageInput = {
  inputs?: InputMaybe<Array<InputMaybe<ConversionInput>>>;
  inventoryConversionId: Scalars['Int'];
  license?: InputMaybe<CannabisLicenseInput>;
  outputComplianceId?: InputMaybe<Scalars['String']>;
  outputInventoryId: Scalars['Uuid'];
  outputProductId: Scalars['Int'];
  outputProductName?: InputMaybe<Scalars['String']>;
  outputQuantity: Scalars['Decimal'];
  outputTypeName?: InputMaybe<Scalars['String']>;
  outputWholesaleInventoryId: Scalars['Int'];
};

export type CostAllocatedToPlantFromGroup = {
  __typename?: 'CostAllocatedToPlantFromGroup';
  allocatedBy?: Maybe<Scalars['String']>;
  amountAllocated: Scalars['Decimal'];
  costDescription?: Maybe<Scalars['String']>;
  costType?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  groupType?: Maybe<Scalars['String']>;
  phase: GrowthPhase;
  plant?: Maybe<PlantReference>;
  plantGroupId: Scalars['Uuid'];
  plantGroupName?: Maybe<Scalars['String']>;
  streamId: Scalars['Uuid'];
};

export type CostAllocatedToPlantGroup = {
  __typename?: 'CostAllocatedToPlantGroup';
  allocatedBy?: Maybe<Scalars['String']>;
  amountAllocated: Scalars['Decimal'];
  costDescription?: Maybe<Scalars['String']>;
  costType?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  groupType?: Maybe<Scalars['String']>;
  phase: GrowthPhase;
  plantGroupId: Scalars['Uuid'];
  plantGroupName?: Maybe<Scalars['String']>;
  plantGroupType: PlantGroupType;
  plants?: Maybe<Array<Maybe<PlantReference>>>;
  streamId: Scalars['Uuid'];
  wholesaleGroupReferenceId: Scalars['Int'];
};

export type CostAllocation = {
  __typename?: 'CostAllocation';
  allocatedBy?: Maybe<Scalars['String']>;
  amountAllocated: Scalars['Decimal'];
  costDescription?: Maybe<Scalars['String']>;
  costType?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  plantGroupId: Scalars['Uuid'];
};

export type CostAllocationInput = {
  allocatedBy?: InputMaybe<Scalars['String']>;
  amountAllocated: Scalars['Decimal'];
  costDescription?: InputMaybe<Scalars['String']>;
  costType?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  plantGroupId: Scalars['Uuid'];
};

export type CostAllocationLog = {
  __typename?: 'CostAllocationLog';
  costAllocationLogPlantEvents: Array<CostAllocationLogPlantEvents>;
  costPerPlant: Scalars['Decimal'];
  data: CostAllocatedToPlantGroup;
  dataId: Scalars['String'];
  description: Scalars['String'];
  eventId: Scalars['String'];
  eventTimestamp: Scalars['DateTime'];
  eventType: Scalars['String'];
  licenses: Array<CannabisLicense>;
  plantCount: Scalars['Int'];
  tenantId: Scalars['String'];
};

export type CostAllocationLogPlantEvents = {
  __typename?: 'CostAllocationLogPlantEvents';
  licenseNumber: Scalars['String'];
  plantEvents: Array<PlantCostAllocationEvent>;
};

export type CostComponent = {
  __typename?: 'CostComponent';
  costType: CostComponentType;
  name?: Maybe<Scalars['String']>;
  quantity: Scalars['Decimal'];
  totalCost: Scalars['Decimal'];
  uOM?: Maybe<Scalars['String']>;
  unitCost: Scalars['Decimal'];
};

export enum CostComponentType {
  AllocatedLabor = 'ALLOCATED_LABOR',
  AllocatedOverhead = 'ALLOCATED_OVERHEAD',
  Cannabis = 'CANNABIS',
  DirectLabor = 'DIRECT_LABOR',
  NonCannabis = 'NON_CANNABIS'
}

export type CreateAccountInput = {
  accountName?: InputMaybe<Scalars['String']>;
  createSandbox: Scalars['Boolean'];
  jurisdictionCode?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Uuid']>;
  organizationStripeCustomerId?: InputMaybe<Scalars['String']>;
  organizationStripeSubscriptionId?: InputMaybe<Scalars['String']>;
};

export type CreateDejavooPaymentArgs = {
  amount: Scalars['Int'];
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['String']>;
  terminal: Terminal;
};

/** All input for the create `FeatureUsageLicense` mutation. */
export type CreateFeatureUsageLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FeatureUsageLicense` to be created by this mutation. */
  featureUsageLicense: FeatureUsageLicenseInput;
};

/** The output of our create `FeatureUsageLicense` mutation. */
export type CreateFeatureUsageLicensePayload = {
  __typename?: 'CreateFeatureUsageLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `FeatureUsage` that is related to this `FeatureUsageLicense`. */
  featureUsageByFeatureusage?: Maybe<FeatureUsage>;
  /** The `FeatureUsageLicense` that was created by this mutation. */
  featureUsageLicense?: Maybe<FeatureUsageLicense>;
  /** An edge for our `FeatureUsageLicense`. May be used by Relay 1. */
  featureUsageLicenseEdge?: Maybe<FeatureUsageLicensesEdge>;
  /** Reads a single `License` that is related to this `FeatureUsageLicense`. */
  licenseByLicense?: Maybe<License>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `FeatureUsageLicense` mutation. */
export type CreateFeatureUsageLicensePayloadFeatureUsageLicenseEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureUsageLicensesOrderBy>>;
};

export type CreateHarvestInput = {
  harvest?: InputMaybe<HarvestReferenceInput>;
  license: CannabisLicenseInput;
};

export type CreateHarvestPackageInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  harvestBatchId: Scalars['Int'];
  harvestName?: InputMaybe<Scalars['String']>;
  initialQuantity: Scalars['Decimal'];
  inventoryId: Scalars['Uuid'];
  inventoryTypeName?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<CannabisLicenseInput>;
  strain?: InputMaybe<Scalars['String']>;
  wholesaleInventoryId: Scalars['Int'];
};

/** All input for the create `License` mutation. */
export type CreateLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `License` to be created by this mutation. */
  license: LicenseInput;
};

/** The output of our create `License` mutation. */
export type CreateLicensePayload = {
  __typename?: 'CreateLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `License` that was created by this mutation. */
  license?: Maybe<License>;
  /** An edge for our `License`. May be used by Relay 1. */
  licenseEdge?: Maybe<LicensesEdge>;
  /** Reads a single `Organization` that is related to this `License`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `License` mutation. */
export type CreateLicensePayloadLicenseEdgeArgs = {
  orderBy?: InputMaybe<Array<LicensesOrderBy>>;
};

/** All input for the create `LockoutGracePeriod` mutation. */
export type CreateLockoutGracePeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LockoutGracePeriod` to be created by this mutation. */
  lockoutGracePeriod: LockoutGracePeriodInput;
};

/** The output of our create `LockoutGracePeriod` mutation. */
export type CreateLockoutGracePeriodPayload = {
  __typename?: 'CreateLockoutGracePeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `License` that is related to this `LockoutGracePeriod`. */
  licenseByLicense?: Maybe<License>;
  /** The `LockoutGracePeriod` that was created by this mutation. */
  lockoutGracePeriod?: Maybe<LockoutGracePeriod>;
  /** An edge for our `LockoutGracePeriod`. May be used by Relay 1. */
  lockoutGracePeriodEdge?: Maybe<LockoutGracePeriodsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LockoutGracePeriod` mutation. */
export type CreateLockoutGracePeriodPayloadLockoutGracePeriodEdgeArgs = {
  orderBy?: InputMaybe<Array<LockoutGracePeriodsOrderBy>>;
};

export type CreateMockPaymentArgs = {
  amount: Scalars['Int'];
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
};

export type CreateNonCannabisPackageInput = {
  canBeRecipeIngredient: Scalars['Boolean'];
  initialQuantity: Scalars['Decimal'];
  license?: InputMaybe<CannabisLicenseInput>;
  package?: InputMaybe<PackageReferenceInput>;
  product?: InputMaybe<ProductReferenceInput>;
  remainingQuantity: Scalars['Decimal'];
};

/** All input for the create `Note` mutation. */
export type CreateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Note` to be created by this mutation. */
  note: NoteInput;
};

/** The output of our create `Note` mutation. */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Note` that was created by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our create `Note` mutation. */
export type CreateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the create `Organization` mutation. */
export type CreateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Organization` to be created by this mutation. */
  organization: OrganizationInput;
};

/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was created by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

export type CreatePaytenderPaymentArgs = {
  customer: PaytenderCustomer;
  merchant: PaytenderMerchant;
  order: PaytenderOrder;
};

export type CreatePublicBillingPortalLinkInput = {
  organizationKey: Scalars['String'];
  returnUrl: Scalars['String'];
};

/** All input for the create `ReupLineItem` mutation. */
export type CreateReupLineItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ReupLineItem` to be created by this mutation. */
  reupLineItem: ReupLineItemInput;
};

/** The output of our create `ReupLineItem` mutation. */
export type CreateReupLineItemPayload = {
  __typename?: 'CreateReupLineItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ReupPurchaseOrder` that is related to this `ReupLineItem`. */
  purchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupLineItem` that was created by this mutation. */
  reupLineItem?: Maybe<ReupLineItem>;
  /** An edge for our `ReupLineItem`. May be used by Relay 1. */
  reupLineItemEdge?: Maybe<ReupLineItemsEdge>;
};


/** The output of our create `ReupLineItem` mutation. */
export type CreateReupLineItemPayloadReupLineItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupLineItemsOrderBy>>;
};

/** All input for the create `ReupProduct` mutation. */
export type CreateReupProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ReupProduct` to be created by this mutation. */
  reupProduct: ReupProductInput;
};

/** The output of our create `ReupProduct` mutation. */
export type CreateReupProductPayload = {
  __typename?: 'CreateReupProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupProduct` that was created by this mutation. */
  reupProduct?: Maybe<ReupProduct>;
  /** An edge for our `ReupProduct`. May be used by Relay 1. */
  reupProductEdge?: Maybe<ReupProductsEdge>;
};


/** The output of our create `ReupProduct` mutation. */
export type CreateReupProductPayloadReupProductEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupProductsOrderBy>>;
};

/** All input for the create `ReupPurchaseOrder` mutation. */
export type CreateReupPurchaseOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ReupPurchaseOrder` to be created by this mutation. */
  reupPurchaseOrder: ReupPurchaseOrderInput;
};

/** The output of our create `ReupPurchaseOrder` mutation. */
export type CreateReupPurchaseOrderPayload = {
  __typename?: 'CreateReupPurchaseOrderPayload';
  /** Reads a single `License` that is related to this `ReupPurchaseOrder`. */
  buyer?: Maybe<License>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupPurchaseOrder` that was created by this mutation. */
  reupPurchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** An edge for our `ReupPurchaseOrder`. May be used by Relay 1. */
  reupPurchaseOrderEdge?: Maybe<ReupPurchaseOrdersEdge>;
};


/** The output of our create `ReupPurchaseOrder` mutation. */
export type CreateReupPurchaseOrderPayloadReupPurchaseOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupPurchaseOrdersOrderBy>>;
};

export type CreateShortenedLinkInput = {
  customurl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expireIn?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  reuse?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['String']>;
  visitCount?: InputMaybe<Scalars['Int']>;
};

export type CreateShortenedLinkResponse = {
  __typename?: 'CreateShortenedLinkResponse';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  visitCount?: Maybe<Scalars['Int']>;
};

/** All input for the create `TaxRate` mutation. */
export type CreateTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TaxRate` to be created by this mutation. */
  taxRate: TaxRateInput;
};

/** The output of our create `TaxRate` mutation. */
export type CreateTaxRatePayload = {
  __typename?: 'CreateTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was created by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our create `TaxRate` mutation. */
export type CreateTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** All input for the create `UserLinkedIdentity` mutation. */
export type CreateUserLinkedIdentityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserLinkedIdentity` to be created by this mutation. */
  userLinkedIdentity: UserLinkedIdentityInput;
};

/** The output of our create `UserLinkedIdentity` mutation. */
export type CreateUserLinkedIdentityPayload = {
  __typename?: 'CreateUserLinkedIdentityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserLinkedIdentity` that was created by this mutation. */
  userLinkedIdentity?: Maybe<UserLinkedIdentity>;
  /** An edge for our `UserLinkedIdentity`. May be used by Relay 1. */
  userLinkedIdentityEdge?: Maybe<UserLinkedIdentitiesEdge>;
};


/** The output of our create `UserLinkedIdentity` mutation. */
export type CreateUserLinkedIdentityPayloadUserLinkedIdentityEdgeArgs = {
  orderBy?: InputMaybe<Array<UserLinkedIdentitiesOrderBy>>;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type CreateWholesaleOrganizationInput = {
  name?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteFeatureUsageLicenseByFeatureusageAndLicense` mutation. */
export type DeleteFeatureUsageLicenseByFeatureusageAndLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
};

/** All input for the `deleteFeatureUsageLicenseByNodeId` mutation. */
export type DeleteFeatureUsageLicenseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FeatureUsageLicense` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFeatureUsageLicense` mutation. */
export type DeleteFeatureUsageLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `FeatureUsageLicense` mutation. */
export type DeleteFeatureUsageLicensePayload = {
  __typename?: 'DeleteFeatureUsageLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFeatureUsageLicenseNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `FeatureUsage` that is related to this `FeatureUsageLicense`. */
  featureUsageByFeatureusage?: Maybe<FeatureUsage>;
  /** The `FeatureUsageLicense` that was deleted by this mutation. */
  featureUsageLicense?: Maybe<FeatureUsageLicense>;
  /** An edge for our `FeatureUsageLicense`. May be used by Relay 1. */
  featureUsageLicenseEdge?: Maybe<FeatureUsageLicensesEdge>;
  /** Reads a single `License` that is related to this `FeatureUsageLicense`. */
  licenseByLicense?: Maybe<License>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `FeatureUsageLicense` mutation. */
export type DeleteFeatureUsageLicensePayloadFeatureUsageLicenseEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureUsageLicensesOrderBy>>;
};

/** All input for the `deleteLicenseByNodeId` mutation. */
export type DeleteLicenseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `License` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLicense` mutation. */
export type DeleteLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `License` mutation. */
export type DeleteLicensePayload = {
  __typename?: 'DeleteLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLicenseNodeId?: Maybe<Scalars['ID']>;
  /** The `License` that was deleted by this mutation. */
  license?: Maybe<License>;
  /** An edge for our `License`. May be used by Relay 1. */
  licenseEdge?: Maybe<LicensesEdge>;
  /** Reads a single `Organization` that is related to this `License`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `License` mutation. */
export type DeleteLicensePayloadLicenseEdgeArgs = {
  orderBy?: InputMaybe<Array<LicensesOrderBy>>;
};

/** All input for the `deleteLockoutGracePeriodByNodeId` mutation. */
export type DeleteLockoutGracePeriodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LockoutGracePeriod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLockoutGracePeriod` mutation. */
export type DeleteLockoutGracePeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LockoutGracePeriod` mutation. */
export type DeleteLockoutGracePeriodPayload = {
  __typename?: 'DeleteLockoutGracePeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLockoutGracePeriodNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `License` that is related to this `LockoutGracePeriod`. */
  licenseByLicense?: Maybe<License>;
  /** The `LockoutGracePeriod` that was deleted by this mutation. */
  lockoutGracePeriod?: Maybe<LockoutGracePeriod>;
  /** An edge for our `LockoutGracePeriod`. May be used by Relay 1. */
  lockoutGracePeriodEdge?: Maybe<LockoutGracePeriodsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LockoutGracePeriod` mutation. */
export type DeleteLockoutGracePeriodPayloadLockoutGracePeriodEdgeArgs = {
  orderBy?: InputMaybe<Array<LockoutGracePeriodsOrderBy>>;
};

/** All input for the `deleteOrganizationByEnvironmentId` mutation. */
export type DeleteOrganizationByEnvironmentIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
};

/** All input for the `deleteOrganizationByNodeId` mutation. */
export type DeleteOrganizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Organization` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOrganization` mutation. */
export type DeleteOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOrganizationNodeId?: Maybe<Scalars['ID']>;
  /** The `Organization` that was deleted by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `deleteReupLineItemByNodeId` mutation. */
export type DeleteReupLineItemByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReupLineItem` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReupLineItem` mutation. */
export type DeleteReupLineItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ReupLineItem` mutation. */
export type DeleteReupLineItemPayload = {
  __typename?: 'DeleteReupLineItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLineItemNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `ReupPurchaseOrder` that is related to this `ReupLineItem`. */
  purchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupLineItem` that was deleted by this mutation. */
  reupLineItem?: Maybe<ReupLineItem>;
  /** An edge for our `ReupLineItem`. May be used by Relay 1. */
  reupLineItemEdge?: Maybe<ReupLineItemsEdge>;
};


/** The output of our delete `ReupLineItem` mutation. */
export type DeleteReupLineItemPayloadReupLineItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupLineItemsOrderBy>>;
};

/** All input for the `deleteReupProductByNodeId` mutation. */
export type DeleteReupProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReupProduct` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReupProduct` mutation. */
export type DeleteReupProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ReupProduct` mutation. */
export type DeleteReupProductPayload = {
  __typename?: 'DeleteReupProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProductNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupProduct` that was deleted by this mutation. */
  reupProduct?: Maybe<ReupProduct>;
  /** An edge for our `ReupProduct`. May be used by Relay 1. */
  reupProductEdge?: Maybe<ReupProductsEdge>;
};


/** The output of our delete `ReupProduct` mutation. */
export type DeleteReupProductPayloadReupProductEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupProductsOrderBy>>;
};

/** All input for the `deleteReupPurchaseOrderByNodeId` mutation. */
export type DeleteReupPurchaseOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReupPurchaseOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReupPurchaseOrder` mutation. */
export type DeleteReupPurchaseOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ReupPurchaseOrder` mutation. */
export type DeleteReupPurchaseOrderPayload = {
  __typename?: 'DeleteReupPurchaseOrderPayload';
  /** Reads a single `License` that is related to this `ReupPurchaseOrder`. */
  buyer?: Maybe<License>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPurchaseOrderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupPurchaseOrder` that was deleted by this mutation. */
  reupPurchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** An edge for our `ReupPurchaseOrder`. May be used by Relay 1. */
  reupPurchaseOrderEdge?: Maybe<ReupPurchaseOrdersEdge>;
};


/** The output of our delete `ReupPurchaseOrder` mutation. */
export type DeleteReupPurchaseOrderPayloadReupPurchaseOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupPurchaseOrdersOrderBy>>;
};

export type DeleteShortenedLinkResponse = {
  __typename?: 'DeleteShortenedLinkResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `deleteTaxRateByNodeId` mutation. */
export type DeleteTaxRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TaxRate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTaxRate` mutation. */
export type DeleteTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['Int'];
};

/** The output of our delete `TaxRate` mutation. */
export type DeleteTaxRatePayload = {
  __typename?: 'DeleteTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTaxRateNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was deleted by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our delete `TaxRate` mutation. */
export type DeleteTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

export type DeleteUserByGlobalIdInputV2 = {
  globalUserId: Scalars['UUID'];
};

export type DeleteUserByGlobalIdPayloadV2 = {
  __typename?: 'DeleteUserByGlobalIdPayloadV2';
  deletedGlobalUserId?: Maybe<Scalars['UUID']>;
};

/** All input for the `deleteUserByGlobalUserId` mutation. */
export type DeleteUserByGlobalUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteUserLinkedIdentityByLinkedGlobalUserId` mutation. */
export type DeleteUserLinkedIdentityByLinkedGlobalUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A global_user_id that has been merged into the corresponding primary_global_user_id. A linked_global_user_id should only ever show up once and it should only be allowed to map to one primary_global_user_id. */
  linkedGlobalUserId: Scalars['UUID'];
};

/** All input for the `deleteUserLinkedIdentityByNodeId` mutation. */
export type DeleteUserLinkedIdentityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserLinkedIdentity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserLinkedIdentity` mutation. */
export type DeleteUserLinkedIdentityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserLinkedIdentity` mutation. */
export type DeleteUserLinkedIdentityPayload = {
  __typename?: 'DeleteUserLinkedIdentityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserLinkedIdentityNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserLinkedIdentity` that was deleted by this mutation. */
  userLinkedIdentity?: Maybe<UserLinkedIdentity>;
  /** An edge for our `UserLinkedIdentity`. May be used by Relay 1. */
  userLinkedIdentityEdge?: Maybe<UserLinkedIdentitiesEdge>;
};


/** The output of our delete `UserLinkedIdentity` mutation. */
export type DeleteUserLinkedIdentityPayloadUserLinkedIdentityEdgeArgs = {
  orderBy?: InputMaybe<Array<UserLinkedIdentitiesOrderBy>>;
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type DestroyInventoryInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  destroyExplanation?: InputMaybe<Scalars['String']>;
  destroyReasonId: Scalars['Int'];
  license?: InputMaybe<CannabisLicenseInput>;
  quantityToDestroy: Scalars['Decimal'];
  roomId?: InputMaybe<Scalars['Int']>;
  wholesaleInventoryId: Scalars['Int'];
};

export type DestroyPlantsInput = {
  license: CannabisLicenseInput;
  plants?: InputMaybe<Array<InputMaybe<PlantReferenceInput>>>;
  reason?: InputMaybe<Scalars['String']>;
};

export type DisposalDto = {
  __typename?: 'DisposalDto';
  batchId?: Maybe<Scalars['Int']>;
  complianceId?: Maybe<Scalars['String']>;
  conversionId?: Maybe<Scalars['Int']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  disposalAt?: Maybe<Scalars['DateTime']>;
  disposalCert?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  gFBatchId: Scalars['Int'];
  holdEndsAt?: Maybe<Scalars['DateTime']>;
  holdStartsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  inventoryId?: Maybe<Scalars['Int']>;
  isDeleted: Scalars['Boolean'];
  isDisposed: Scalars['Boolean'];
  isWet: Scalars['Boolean'];
  lastPrinted?: Maybe<Scalars['DateTime']>;
  method?: Maybe<Scalars['String']>;
  plantId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Decimal'];
  reason?: Maybe<MjfDisposalReasonType>;
  room?: Maybe<RoomDto>;
  source?: Maybe<MjfDisposalSourceType>;
  strain?: Maybe<StrainDto>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  typeOfInventoryDestroyReasonId?: Maybe<Scalars['Int']>;
  typeOfPlantDestroyReasonId?: Maybe<Scalars['Int']>;
  typeOfPlantWasteMethodId?: Maybe<Scalars['Int']>;
  unit?: Maybe<UnitOfMeasure>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  wasteInventoryComplianceId?: Maybe<Scalars['String']>;
  wasteInventoryId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type DisposalDtoConnection = {
  __typename?: 'DisposalDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DisposalDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DisposalDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DisposalDtoEdge = {
  __typename?: 'DisposalDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DisposalDto;
};

export type DisposalFilterInput = {
  and?: InputMaybe<Array<DisposalFilterInput>>;
  batchId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  conversionId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  disposalAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  disposalCert?: InputMaybe<StringOperationFilterInput>;
  explanation?: InputMaybe<StringOperationFilterInput>;
  gFBatchId?: InputMaybe<ComparableInt32OperationFilterInput>;
  holdEndsAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  holdStartsAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  inventoryId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isDisposed?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isWet?: InputMaybe<BooleanOperationFilterInput>;
  lastPrinted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  method?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DisposalFilterInput>>;
  plantId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  quantity?: InputMaybe<ComparableDecimalOperationFilterInput>;
  reason?: InputMaybe<NullableOfMjfDisposalReasonTypeOperationFilterInput>;
  room?: InputMaybe<RoomFilterInput>;
  source?: InputMaybe<NullableOfMjfDisposalSourceTypeOperationFilterInput>;
  strain?: InputMaybe<StrainFilterInput>;
  typeOfInventoryDestroyReasonId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  typeOfPlantDestroyReasonId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  typeOfPlantWasteMethodId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  unit?: InputMaybe<NullableOfUnitOfMeasureOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  wasteInventoryComplianceId?: InputMaybe<StringOperationFilterInput>;
  wasteInventoryId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type DisposalSortInput = {
  batchId?: InputMaybe<SortEnumType>;
  complianceId?: InputMaybe<SortEnumType>;
  conversionId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  disposalAt?: InputMaybe<SortEnumType>;
  disposalCert?: InputMaybe<SortEnumType>;
  explanation?: InputMaybe<SortEnumType>;
  gFBatchId?: InputMaybe<SortEnumType>;
  holdEndsAt?: InputMaybe<SortEnumType>;
  holdStartsAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inventoryId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isDisposed?: InputMaybe<SortEnumType>;
  isWet?: InputMaybe<SortEnumType>;
  lastPrinted?: InputMaybe<SortEnumType>;
  method?: InputMaybe<SortEnumType>;
  plantId?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<SortEnumType>;
  room?: InputMaybe<RoomSortInput>;
  source?: InputMaybe<SortEnumType>;
  strain?: InputMaybe<StrainSortInput>;
  typeOfInventoryDestroyReasonId?: InputMaybe<SortEnumType>;
  typeOfPlantDestroyReasonId?: InputMaybe<SortEnumType>;
  typeOfPlantWasteMethodId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  wasteInventoryComplianceId?: InputMaybe<SortEnumType>;
  wasteInventoryId?: InputMaybe<SortEnumType>;
};

export type DotNetAssemblyDto = {
  __typename?: 'DotNetAssemblyDto';
  imageRuntimeVersion?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmployeeCreateDtoInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dateOfHire?: InputMaybe<Scalars['DateTime']>;
  driversLicense?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type EmployeeDto = {
  __typename?: 'EmployeeDto';
  complianceId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfHire?: Maybe<Scalars['DateTime']>;
  driversLicense?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  user?: Maybe<UserDto>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type EmployeeDtoConnection = {
  __typename?: 'EmployeeDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EmployeeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EmployeeDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EmployeeDtoEdge = {
  __typename?: 'EmployeeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmployeeDto;
};

export type EmployeeFilterInput = {
  and?: InputMaybe<Array<EmployeeFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  dateOfBirth?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dateOfHire?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  driversLicense?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
  vehicleId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  vendorId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type EmployeeInviteDtoInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type EmployeeSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  dateOfBirth?: InputMaybe<SortEnumType>;
  dateOfHire?: InputMaybe<SortEnumType>;
  driversLicense?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<SortEnumType>;
  vehicleId?: InputMaybe<SortEnumType>;
  vendorId?: InputMaybe<SortEnumType>;
};

export type EmployeeUpdateDtoInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dateOfHire?: InputMaybe<Scalars['DateTime']>;
  driversLicense?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type Environment = {
  __typename?: 'Environment';
  ClientId?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['Int']>;
  DeletedAt?: Maybe<Scalars['Int']>;
  Notes?: Maybe<Scalars['String']>;
  PrimaryContactEmail?: Maybe<Scalars['String']>;
  PrimaryContactName?: Maybe<Scalars['String']>;
  SaPw?: Maybe<Scalars['String']>;
  SaUn?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StripeId?: Maybe<Scalars['String']>;
  Tz?: Maybe<Scalars['String']>;
  UpdatedAt?: Maybe<Scalars['Int']>;
};

export type EnvironmentInput = {
  ClientId?: InputMaybe<Scalars['String']>;
  CreatedAt?: InputMaybe<Scalars['Int']>;
  DeletedAt?: InputMaybe<Scalars['Int']>;
  Notes?: InputMaybe<Scalars['String']>;
  PrimaryContactEmail?: InputMaybe<Scalars['String']>;
  PrimaryContactName?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Status?: InputMaybe<Scalars['String']>;
  StripeId?: InputMaybe<Scalars['String']>;
  Tz?: InputMaybe<Scalars['String']>;
  UpdatedAt?: InputMaybe<Scalars['Int']>;
};

export type Feature = Node & {
  __typename?: 'Feature';
  active: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  includedLicenseCount?: Maybe<Scalars['Int']>;
  jurisdiction?: Maybe<Scalars['String']>;
  livemode: Scalars['Boolean'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  planType: PlanType;
  /** Reads and enables pagination through a set of `Price`. */
  prices: PricesConnection;
  productType: ProductType;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type FeaturePricesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};

/** The fields on `feature` to look up the row to connect. */
export type FeatureFeaturesPkeyConnect = {
  id: Scalars['String'];
};

/** A filter to be used against `Feature` object types. All fields are combined with a logical ‘and.’ */
export type FeatureFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeatureFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `includedLicenseCount` field. */
  includedLicenseCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `jurisdiction` field. */
  jurisdiction?: InputMaybe<StringFilter>;
  /** Filter by the object’s `livemode` field. */
  livemode?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeatureFilter>>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FeatureNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `feature` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeatureOnPriceForPricesFeatureIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `price` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `price` being updated. */
  patch: PricePatch;
};

/** The fields on `feature` to look up the row to update. */
export type FeatureOnPriceForPricesFeatureIdFkeyUsingFeaturesPkeyUpdate = {
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `feature` being updated. */
  patch: UpdateFeatureOnPriceForPricesFeatureIdFkeyPatch;
};

/** Represents an update to a `Feature`. Fields that are set will be updated. */
export type FeaturePatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  includedLicenseCount?: InputMaybe<Scalars['Int']>;
  jurisdiction?: InputMaybe<Scalars['String']>;
  livemode?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  planType?: InputMaybe<PlanType>;
  pricesUsingId?: InputMaybe<PricesFeatureIdFkeyInverseInput>;
  productType?: InputMaybe<ProductType>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type FeatureUsage = Node & {
  __typename?: 'FeatureUsage';
  /** Reads and enables pagination through a set of `FeatureUsageLicense`. */
  featureUsageLicensesByFeatureusage: FeatureUsageLicensesConnection;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  override?: Maybe<Scalars['Int']>;
  /** Reads a single `Price` that is related to this `FeatureUsage`. */
  price?: Maybe<Price>;
  priceId: Scalars['String'];
  quantity: Scalars['Int'];
  stripeId?: Maybe<Scalars['String']>;
  /** Reads a single `StripeSubscription` that is related to this `FeatureUsage`. */
  stripeSubscription?: Maybe<StripeSubscription>;
  stripeSubscriptionId: Scalars['Int'];
};


export type FeatureUsageFeatureUsageLicensesByFeatureusageArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureUsageLicenseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeatureUsageLicensesOrderBy>>;
};

/** The fields on `featureUsage` to look up the row to connect. */
export type FeatureUsageFeatureUsagesPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `featureUsage` to look up the row to connect. */
export type FeatureUsageFeatureUsagesStripeIdKeyConnect = {
  stripeId: Scalars['String'];
};

/** The fields on `featureUsage` to look up the row to connect. */
export type FeatureUsageFeatureUsagesStripeSubscriptionIdPriceIdKeyConnect = {
  priceId: Scalars['String'];
  stripeSubscriptionId: Scalars['Int'];
};

/** A filter to be used against `FeatureUsage` object types. All fields are combined with a logical ‘and.’ */
export type FeatureUsageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeatureUsageFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeatureUsageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeatureUsageFilter>>;
  /** Filter by the object’s `override` field. */
  override?: InputMaybe<IntFilter>;
  /** Filter by the object’s `priceId` field. */
  priceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `stripeId` field. */
  stripeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeSubscriptionId` field. */
  stripeSubscriptionId?: InputMaybe<IntFilter>;
};

export type FeatureUsageLicense = Node & {
  __typename?: 'FeatureUsageLicense';
  createdAt: Scalars['Datetime'];
  /** Reads a single `FeatureUsage` that is related to this `FeatureUsageLicense`. */
  featureUsageByFeatureusage?: Maybe<FeatureUsage>;
  featureusage?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  license?: Maybe<Scalars['Int']>;
  /** Reads a single `License` that is related to this `FeatureUsageLicense`. */
  licenseByLicense?: Maybe<License>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `featureUsageLicense` to look up the row to connect. */
export type FeatureUsageLicenseFeatureUsageLicenseFeatureusageLicenseKeyConnect = {
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
};

/** The fields on `featureUsageLicense` to look up the row to delete. */
export type FeatureUsageLicenseFeatureUsageLicenseFeatureusageLicenseKeyDelete = {
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
};

/** The fields on `featureUsageLicense` to look up the row to connect. */
export type FeatureUsageLicenseFeatureUsageLicensePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `featureUsageLicense` to look up the row to delete. */
export type FeatureUsageLicenseFeatureUsageLicensePkeyDelete = {
  id: Scalars['Int'];
};

/** The `featureUsageLicense` to be created by this mutation. */
export type FeatureUsageLicenseFeatureusageFkeyFeatureUsageLicenseCreateInput = {
  featureUsageToFeatureusage?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInput>;
  license?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<FeatureUsageLicenseLicenseFkeyInput>;
};

/** Input for the nested mutation of `featureUsage` in the `FeatureUsageLicenseInput` mutation. */
export type FeatureUsageLicenseFeatureusageFkeyInput = {
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectById?: InputMaybe<FeatureUsageFeatureUsagesPkeyConnect>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<FeatureUsageNodeIdConnect>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByStripeId?: InputMaybe<FeatureUsageFeatureUsagesStripeIdKeyConnect>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByStripeSubscriptionIdAndPriceId?: InputMaybe<FeatureUsageFeatureUsagesStripeSubscriptionIdPriceIdKeyConnect>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateById?: InputMaybe<FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsagesPkeyUpdate>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByStripeId?: InputMaybe<FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsagesStripeIdKeyUpdate>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByStripeSubscriptionIdAndPriceId?: InputMaybe<FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsagesStripeSubscriptionIdPriceIdKeyUpdate>;
};

/** Input for the nested mutation of `featureUsageLicense` in the `FeatureUsageInput` mutation. */
export type FeatureUsageLicenseFeatureusageFkeyInverseInput = {
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  connectByFeatureusageAndLicense?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicenseFeatureusageLicenseKeyConnect>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicensePkeyConnect>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<FeatureUsageLicenseNodeIdConnect>>;
  /** A `FeatureUsageLicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FeatureUsageLicenseFeatureusageFkeyFeatureUsageLicenseCreateInput>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  deleteByFeatureusageAndLicense?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicenseFeatureusageLicenseKeyDelete>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicensePkeyDelete>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<FeatureUsageLicenseNodeIdDelete>>;
  /** Flag indicating whether all other `featureUsageLicense` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `featureUsageLicense` for the far side of the relationship. */
  updateByFeatureusageAndLicense?: InputMaybe<Array<FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsageLicenseFeatureusageLicenseKeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsageLicense` for the far side of the relationship. */
  updateById?: InputMaybe<Array<FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsageLicensePkeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsageLicense` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyNodeIdUpdate>>;
};

/** A filter to be used against `FeatureUsageLicense` object types. All fields are combined with a logical ‘and.’ */
export type FeatureUsageLicenseFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeatureUsageLicenseFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `featureusage` field. */
  featureusage?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `license` field. */
  license?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeatureUsageLicenseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeatureUsageLicenseFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `FeatureUsageLicense` */
export type FeatureUsageLicenseInput = {
  featureUsageToFeatureusage?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInput>;
  featureusage?: InputMaybe<Scalars['Int']>;
  license?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<FeatureUsageLicenseLicenseFkeyInput>;
};

/** The `featureUsageLicense` to be created by this mutation. */
export type FeatureUsageLicenseLicenseFkeyFeatureUsageLicenseCreateInput = {
  featureUsageToFeatureusage?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInput>;
  featureusage?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<FeatureUsageLicenseLicenseFkeyInput>;
};

/** Input for the nested mutation of `license` in the `FeatureUsageLicenseInput` mutation. */
export type FeatureUsageLicenseLicenseFkeyInput = {
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectById?: InputMaybe<LicenseLicensesPkeyConnect>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<LicenseNodeIdConnect>;
  /** A `LicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<FeatureUsageLicenseLicenseFkeyLicensesCreateInput>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteById?: InputMaybe<LicenseLicensesPkeyDelete>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<LicenseNodeIdDelete>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateById?: InputMaybe<LicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyUsingLicensesPkeyUpdate>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `featureUsageLicense` in the `LicenseInput` mutation. */
export type FeatureUsageLicenseLicenseFkeyInverseInput = {
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  connectByFeatureusageAndLicense?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicenseFeatureusageLicenseKeyConnect>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicensePkeyConnect>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<FeatureUsageLicenseNodeIdConnect>>;
  /** A `FeatureUsageLicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FeatureUsageLicenseLicenseFkeyFeatureUsageLicenseCreateInput>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  deleteByFeatureusageAndLicense?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicenseFeatureusageLicenseKeyDelete>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FeatureUsageLicenseFeatureUsageLicensePkeyDelete>>;
  /** The primary key(s) for `featureUsageLicense` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<FeatureUsageLicenseNodeIdDelete>>;
  /** Flag indicating whether all other `featureUsageLicense` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `featureUsageLicense` for the far side of the relationship. */
  updateByFeatureusageAndLicense?: InputMaybe<Array<FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyUsingFeatureUsageLicenseFeatureusageLicenseKeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsageLicense` for the far side of the relationship. */
  updateById?: InputMaybe<Array<FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyUsingFeatureUsageLicensePkeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsageLicense` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<LicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyNodeIdUpdate>>;
};

/** The `license` to be created by this mutation. */
export type FeatureUsageLicenseLicenseFkeyLicensesCreateInput = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType: ProductType;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state: Scalars['String'];
};

/** The globally unique `ID` look up for the row to connect. */
export type FeatureUsageLicenseNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `featureUsageLicense` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FeatureUsageLicenseNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `featureUsageLicense` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `featureUsage` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: FeatureUsagePatch;
};

/** The fields on `featureUsageLicense` to look up the row to update. */
export type FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsageLicenseFeatureusageLicenseKeyUpdate = {
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
  patch: UpdateFeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch;
};

/** The fields on `featureUsageLicense` to look up the row to update. */
export type FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsageLicensePkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
  patch: UpdateFeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `license` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: LicensePatch;
};

/** The fields on `featureUsageLicense` to look up the row to update. */
export type FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyUsingFeatureUsageLicenseFeatureusageLicenseKeyUpdate = {
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
  patch: UpdateFeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyPatch;
};

/** The fields on `featureUsageLicense` to look up the row to update. */
export type FeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyUsingFeatureUsageLicensePkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
  patch: UpdateFeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyPatch;
};

/** Represents an update to a `FeatureUsageLicense`. Fields that are set will be updated. */
export type FeatureUsageLicensePatch = {
  featureUsageToFeatureusage?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInput>;
  featureusage?: InputMaybe<Scalars['Int']>;
  license?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<FeatureUsageLicenseLicenseFkeyInput>;
};

/** A connection to a list of `FeatureUsageLicense` values. */
export type FeatureUsageLicensesConnection = {
  __typename?: 'FeatureUsageLicensesConnection';
  /** A list of edges which contains the `FeatureUsageLicense` and cursor to aid in pagination. */
  edges: Array<FeatureUsageLicensesEdge>;
  /** A list of `FeatureUsageLicense` objects. */
  nodes: Array<Maybe<FeatureUsageLicense>>;
  /** The count of *all* `FeatureUsageLicense` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FeatureUsageLicense` edge in the connection. */
export type FeatureUsageLicensesEdge = {
  __typename?: 'FeatureUsageLicensesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FeatureUsageLicense` at the end of the edge. */
  node?: Maybe<FeatureUsageLicense>;
};

/** Methods to use when ordering `FeatureUsageLicense`. */
export enum FeatureUsageLicensesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FeatureusageAsc = 'FEATUREUSAGE_ASC',
  FeatureusageDesc = 'FEATUREUSAGE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseAsc = 'LICENSE_ASC',
  LicenseDesc = 'LICENSE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type FeatureUsageNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `featureUsage` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `price` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `price` being updated. */
  patch: PricePatch;
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingFeatureUsagesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyPatch;
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingFeatureUsagesStripeIdKeyUpdate = {
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyPatch;
  stripeId: Scalars['String'];
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingFeatureUsagesStripeSubscriptionIdPriceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyPatch;
  priceId: Scalars['String'];
  stripeSubscriptionId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `stripeSubscription` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: StripeSubscriptionPatch;
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingFeatureUsagesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch;
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingFeatureUsagesStripeIdKeyUpdate = {
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch;
  stripeId: Scalars['String'];
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingFeatureUsagesStripeSubscriptionIdPriceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch;
  priceId: Scalars['String'];
  stripeSubscriptionId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `featureUsageLicense` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
  patch: FeatureUsageLicensePatch;
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsagesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch;
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsagesStripeIdKeyUpdate = {
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch;
  stripeId: Scalars['String'];
};

/** The fields on `featureUsage` to look up the row to update. */
export type FeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyUsingFeatureUsagesStripeSubscriptionIdPriceIdKeyUpdate = {
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: UpdateFeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch;
  priceId: Scalars['String'];
  stripeSubscriptionId: Scalars['Int'];
};

/** Represents an update to a `FeatureUsage`. Fields that are set will be updated. */
export type FeatureUsagePatch = {
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInverseInput>;
  override?: InputMaybe<Scalars['Int']>;
  priceId?: InputMaybe<Scalars['String']>;
  priceToPriceId?: InputMaybe<FeatureUsagesPriceIdFkeyInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['Int']>;
  stripeSubscriptionToStripeSubscriptionId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInput>;
};

/** A connection to a list of `FeatureUsage` values. */
export type FeatureUsagesConnection = {
  __typename?: 'FeatureUsagesConnection';
  /** A list of edges which contains the `FeatureUsage` and cursor to aid in pagination. */
  edges: Array<FeatureUsagesEdge>;
  /** A list of `FeatureUsage` objects. */
  nodes: Array<Maybe<FeatureUsage>>;
  /** The count of *all* `FeatureUsage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FeatureUsage` edge in the connection. */
export type FeatureUsagesEdge = {
  __typename?: 'FeatureUsagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FeatureUsage` at the end of the edge. */
  node?: Maybe<FeatureUsage>;
};

/** Methods to use when ordering `FeatureUsage`. */
export enum FeatureUsagesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OverrideAsc = 'OVERRIDE_ASC',
  OverrideDesc = 'OVERRIDE_DESC',
  PriceIdAsc = 'PRICE_ID_ASC',
  PriceIdDesc = 'PRICE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  StripeIdAsc = 'STRIPE_ID_ASC',
  StripeIdDesc = 'STRIPE_ID_DESC',
  StripeSubscriptionIdAsc = 'STRIPE_SUBSCRIPTION_ID_ASC',
  StripeSubscriptionIdDesc = 'STRIPE_SUBSCRIPTION_ID_DESC'
}

/** Input for the nested mutation of `price` in the `FeatureUsageInput` mutation. */
export type FeatureUsagesPriceIdFkeyInput = {
  /** The primary key(s) for `price` for the far side of the relationship. */
  connectById?: InputMaybe<PricePricesPkeyConnect>;
  /** The primary key(s) for `price` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<PriceNodeIdConnect>;
  /** The primary key(s) and patch data for `price` for the far side of the relationship. */
  updateById?: InputMaybe<PriceOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingPricesPkeyUpdate>;
  /** The primary key(s) and patch data for `price` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `featureUsage` in the `PriceInput` mutation. */
export type FeatureUsagesPriceIdFkeyInverseInput = {
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FeatureUsageFeatureUsagesPkeyConnect>>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<FeatureUsageNodeIdConnect>>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByStripeId?: InputMaybe<Array<FeatureUsageFeatureUsagesStripeIdKeyConnect>>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByStripeSubscriptionIdAndPriceId?: InputMaybe<Array<FeatureUsageFeatureUsagesStripeSubscriptionIdPriceIdKeyConnect>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateById?: InputMaybe<Array<FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingFeatureUsagesPkeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<PriceOnFeatureUsageForFeatureUsagesPriceIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByStripeId?: InputMaybe<Array<FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingFeatureUsagesStripeIdKeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByStripeSubscriptionIdAndPriceId?: InputMaybe<Array<FeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingFeatureUsagesStripeSubscriptionIdPriceIdKeyUpdate>>;
};

/** Input for the nested mutation of `stripeSubscription` in the `FeatureUsageInput` mutation. */
export type FeatureUsagesStripeSubscriptionIdFkeyInput = {
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectById?: InputMaybe<StripeSubscriptionStripeSubscriptionsPkeyConnect>;
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<StripeSubscriptionNodeIdConnect>;
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectByScheduleId?: InputMaybe<StripeSubscriptionStripeSubscriptionsScheduleIdKeyConnect>;
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectByStripeId?: InputMaybe<StripeSubscriptionStripeSubscriptionsStripeIdKeyConnect>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateById?: InputMaybe<StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingStripeSubscriptionsPkeyUpdate>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateByScheduleId?: InputMaybe<StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingStripeSubscriptionsScheduleIdKeyUpdate>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateByStripeId?: InputMaybe<StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingStripeSubscriptionsStripeIdKeyUpdate>;
};

/** Input for the nested mutation of `featureUsage` in the `StripeSubscriptionInput` mutation. */
export type FeatureUsagesStripeSubscriptionIdFkeyInverseInput = {
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FeatureUsageFeatureUsagesPkeyConnect>>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<FeatureUsageNodeIdConnect>>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByStripeId?: InputMaybe<Array<FeatureUsageFeatureUsagesStripeIdKeyConnect>>;
  /** The primary key(s) for `featureUsage` for the far side of the relationship. */
  connectByStripeSubscriptionIdAndPriceId?: InputMaybe<Array<FeatureUsageFeatureUsagesStripeSubscriptionIdPriceIdKeyConnect>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateById?: InputMaybe<Array<FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingFeatureUsagesPkeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByStripeId?: InputMaybe<Array<FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingFeatureUsagesStripeIdKeyUpdate>>;
  /** The primary key(s) and patch data for `featureUsage` for the far side of the relationship. */
  updateByStripeSubscriptionIdAndPriceId?: InputMaybe<Array<FeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingFeatureUsagesStripeSubscriptionIdPriceIdKeyUpdate>>;
};

/** A connection to a list of `Feature` values. */
export type FeaturesConnection = {
  __typename?: 'FeaturesConnection';
  /** A list of edges which contains the `Feature` and cursor to aid in pagination. */
  edges: Array<FeaturesEdge>;
  /** A list of `Feature` objects. */
  nodes: Array<Maybe<Feature>>;
  /** The count of *all* `Feature` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Feature` edge in the connection. */
export type FeaturesEdge = {
  __typename?: 'FeaturesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Feature` at the end of the edge. */
  node?: Maybe<Feature>;
};

/** Methods to use when ordering `Feature`. */
export enum FeaturesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IncludedLicenseCountAsc = 'INCLUDED_LICENSE_COUNT_ASC',
  IncludedLicenseCountDesc = 'INCLUDED_LICENSE_COUNT_DESC',
  JurisdictionAsc = 'JURISDICTION_ASC',
  JurisdictionDesc = 'JURISDICTION_DESC',
  LivemodeAsc = 'LIVEMODE_ASC',
  LivemodeDesc = 'LIVEMODE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PlanTypeAsc = 'PLAN_TYPE_ASC',
  PlanTypeDesc = 'PLAN_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type FileModel = {
  __typename?: 'FileModel';
  data?: Maybe<Scalars['String']>;
};

export type FileResponse = {
  __typename?: 'FileResponse';
  binaryB64: Scalars['String'];
};

export type FilterOptionItem = {
  __typename?: 'FilterOptionItem';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type FirebaseTokenResponse = {
  __typename?: 'FirebaseTokenResponse';
  FirebaseToken?: Maybe<Scalars['String']>;
};

export enum Gateway {
  Aeropay = 'AEROPAY',
  Dejavoo = 'DEJAVOO',
  Ellacash = 'ELLACASH',
  Mock = 'MOCK',
  Paytender = 'PAYTENDER',
  RethinkPay = 'RETHINK_PAY'
}

export type GetConversionInput = {
  id?: InputMaybe<Scalars['Uuid']>;
  wholesaleInventoryId?: InputMaybe<Scalars['Int']>;
};

export type GetConversionsInput = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
  wholesaleInventoryIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type GetCostAllocatedToPlantFromGroupEventsInput = {
  costAllocationId?: InputMaybe<Scalars['Uuid']>;
  fromDateTime?: InputMaybe<Scalars['DateTime']>;
  licenses?: InputMaybe<Array<Scalars['String']>>;
  searchTerms?: InputMaybe<Scalars['String']>;
  toDateTime?: InputMaybe<Scalars['DateTime']>;
  wholesalePlantComplianceId?: InputMaybe<Scalars['Uuid']>;
  wholesalePlantId?: InputMaybe<Scalars['Int']>;
};

export type GetCostAllocatedToPlantGroupEventsInput = {
  containsDestroyedPlants?: InputMaybe<Scalars['Boolean']>;
  costAllocationId?: InputMaybe<Scalars['Uuid']>;
  costTypes?: InputMaybe<Array<Scalars['String']>>;
  fromDateTime?: InputMaybe<Scalars['DateTime']>;
  includePlantEvents?: InputMaybe<Scalars['Boolean']>;
  licenses?: InputMaybe<Array<Scalars['String']>>;
  phases?: InputMaybe<Array<GrowthPhase>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  searchTerms?: InputMaybe<Scalars['String']>;
  toDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type GetHarvestByIdInput = {
  id?: InputMaybe<Scalars['Uuid']>;
  wholesaleHarvestId?: InputMaybe<Scalars['Int']>;
};

export type GetHarvestsByIdsInput = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
  wholesaleHarvestIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type GetPackageInput = {
  id?: InputMaybe<Scalars['Uuid']>;
  wholesaleInventoryId?: InputMaybe<Scalars['Int']>;
};

export type GetPackagesInput = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
  wholesaleInventoryIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type GetPlantByIdInput = {
  id?: InputMaybe<Scalars['Uuid']>;
  wholesalePlantId?: InputMaybe<Scalars['Int']>;
};

export type GetPlantsByIdsInput = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
  wholesalePlantIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type GracePeriod = {
  __typename?: 'GracePeriod';
  endsAt?: Maybe<Scalars['String']>;
  lockoutBehavior?: Maybe<LockoutStatus>;
  note?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['String']>;
};

export type GraphileWorkerJob = {
  __typename?: 'GraphileWorkerJob';
  id: Scalars['Int'];
  taskIdentifier: Scalars['String'];
};

export type GraphileWorkerJobStatus = {
  __typename?: 'GraphileWorkerJobStatus';
  jobCompleted: Scalars['Boolean'];
};

export type GrowFlowPackageSource = {
  __typename?: 'GrowFlowPackageSource';
  allocatedCost: Scalars['Decimal'];
  allocatedLoss: Scalars['Decimal'];
  lossPercentOfSourcePackage: Scalars['Decimal'];
  parentId: Scalars['Uuid'];
  percentOfSourcePackage: Scalars['Decimal'];
  sourcePackageCost: Scalars['Decimal'];
  sourcePackageId: Scalars['Uuid'];
  sourcePackageUnitCost: Scalars['Decimal'];
};

export enum GrowthPhase {
  Flowering = 'FLOWERING',
  Harvested = 'HARVESTED',
  Rooted = 'ROOTED',
  Seed = 'SEED',
  Vegging = 'VEGGING'
}

export type Harvest = {
  __typename?: 'Harvest';
  harvestCost: Scalars['Decimal'];
  harvestId: Scalars['Uuid'];
  harvestedWeight: Scalars['Decimal'];
};

export type HarvestDto = {
  __typename?: 'HarvestDto';
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  failure?: Maybe<HarvestDto>;
  harvestDate?: Maybe<Scalars['DateTime']>;
  harvestPlants?: Maybe<Array<Maybe<HarvestPlantDto>>>;
  harvestStatus?: Maybe<MjfBatchStatusType>;
  id?: Maybe<Scalars['Int']>;
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isPartialHarvest: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['Int']>;
  roomName?: Maybe<Scalars['String']>;
  tableId?: Maybe<Scalars['Int']>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  unitOfMeasure: UnitOfMeasure;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type HarvestDtoConnection = {
  __typename?: 'HarvestDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<HarvestDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<HarvestDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type HarvestDtoEdge = {
  __typename?: 'HarvestDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: HarvestDto;
};

export type HarvestDtoInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['String']>;
  createTimestamp?: InputMaybe<Scalars['DateTime']>;
  deleteBy?: InputMaybe<Scalars['String']>;
  deleteTimestamp?: InputMaybe<Scalars['DateTime']>;
  failure?: InputMaybe<HarvestDtoInput>;
  harvestDate?: InputMaybe<Scalars['DateTime']>;
  harvestPlants?: InputMaybe<Array<InputMaybe<HarvestPlantDtoInput>>>;
  harvestStatus?: InputMaybe<MjfBatchStatusType>;
  id?: InputMaybe<Scalars['Int']>;
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isPartialHarvest: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  roomId?: InputMaybe<Scalars['Int']>;
  roomName?: InputMaybe<Scalars['String']>;
  tableId?: InputMaybe<Scalars['Int']>;
  traceabilityCommandIds?: InputMaybe<Array<Scalars['Uuid']>>;
  traceabilityError?: InputMaybe<Scalars['String']>;
  traceabilityStatus?: InputMaybe<TraceabilitySyncStatus>;
  traceabilityTag?: InputMaybe<Scalars['String']>;
  unitOfMeasure: UnitOfMeasure;
  updateBy?: InputMaybe<Scalars['String']>;
  updateTimestamp?: InputMaybe<Scalars['DateTime']>;
};

export type HarvestInput = {
  harvestCost: Scalars['Decimal'];
  harvestId: Scalars['Uuid'];
  harvestedWeight: Scalars['Decimal'];
};

export type HarvestPlantDto = {
  __typename?: 'HarvestPlantDto';
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  flowerWeight: Scalars['Decimal'];
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  strainId?: Maybe<Scalars['Int']>;
  strainName?: Maybe<Scalars['String']>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  wasteWeight: Scalars['Decimal'];
};

export type HarvestPlantDtoInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['String']>;
  createTimestamp?: InputMaybe<Scalars['DateTime']>;
  deleteBy?: InputMaybe<Scalars['String']>;
  deleteTimestamp?: InputMaybe<Scalars['DateTime']>;
  flowerWeight: Scalars['Decimal'];
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  strainId?: InputMaybe<Scalars['Int']>;
  strainName?: InputMaybe<Scalars['String']>;
  traceabilityCommandIds?: InputMaybe<Array<Scalars['Uuid']>>;
  traceabilityError?: InputMaybe<Scalars['String']>;
  traceabilityStatus?: InputMaybe<TraceabilitySyncStatus>;
  traceabilityTag?: InputMaybe<Scalars['String']>;
  updateBy?: InputMaybe<Scalars['String']>;
  updateTimestamp?: InputMaybe<Scalars['DateTime']>;
  wasteWeight: Scalars['Decimal'];
};

export type HarvestReferenceInput = {
  harvestName?: InputMaybe<Scalars['String']>;
  plants?: InputMaybe<Array<InputMaybe<HarvestedPlantInput>>>;
  wholesaleGroupReferenceId: Scalars['Int'];
};

export type HarvestView = {
  __typename?: 'HarvestView';
  changeLogs?: Maybe<Array<Maybe<HarvestViewChangeLog>>>;
  cost: Scalars['Decimal'];
  id: Scalars['Uuid'];
  license?: Maybe<CannabisLicense>;
  name?: Maybe<Scalars['String']>;
  plants?: Maybe<Array<KeyValuePairOfGuidAndHarvestedPlant>>;
  totalWeight: Scalars['Decimal'];
  wholesaleHarvestId: Scalars['Int'];
};

export type HarvestViewChangeLog = {
  __typename?: 'HarvestViewChangeLog';
  event?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  totalCost: Scalars['Decimal'];
};

export type HarvestViewChangeLogInput = {
  event?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  totalCost: Scalars['Decimal'];
};

export type HarvestViewInput = {
  changeLogs?: InputMaybe<Array<InputMaybe<HarvestViewChangeLogInput>>>;
  id: Scalars['Uuid'];
  license?: InputMaybe<CannabisLicenseInput>;
  name?: InputMaybe<Scalars['String']>;
  plants?: InputMaybe<Array<KeyValuePairOfGuidAndHarvestedPlantInput>>;
  wholesaleHarvestId: Scalars['Int'];
};

export type HarvestedPlant = {
  __typename?: 'HarvestedPlant';
  cost: Scalars['Decimal'];
  harvestedAt: Scalars['DateTime'];
  harvestedWeight: Scalars['Decimal'];
  plant?: Maybe<PlantReference>;
};

export type HarvestedPlantInput = {
  cost: Scalars['Decimal'];
  harvestedAt: Scalars['DateTime'];
  harvestedWeight: Scalars['Decimal'];
  plant?: InputMaybe<PlantReferenceInput>;
};

export type ImageDto = {
  __typename?: 'ImageDto';
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type ImageDtoConnection = {
  __typename?: 'ImageDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ImageDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ImageDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ImageDtoEdge = {
  __typename?: 'ImageDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ImageDto;
};

export type ImageFilterInput = {
  and?: InputMaybe<Array<ImageFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ImageFilterInput>>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type ImageSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type ImportPackageFromTraceabilityInput = {
  initialQuantity: Scalars['Decimal'];
  license?: InputMaybe<CannabisLicenseInput>;
  package?: InputMaybe<PackageReferenceInput>;
  product?: InputMaybe<ProductReferenceInput>;
  remainingQuantity: Scalars['Decimal'];
  source?: InputMaybe<Scalars['String']>;
  traceabilityProvider: TraceabilityProvider;
};

export type InsightsLayout = InsightsLayoutGroup | InsightsLayoutRoute;

export type InsightsLayoutGroup = {
  __typename?: 'InsightsLayoutGroup';
  category: Scalars['String'];
  key: Scalars['String'];
  subMenuItems: Array<InsightsLayoutGroupSubMenuItem>;
  title: Scalars['String'];
};

export type InsightsLayoutGroupSubMenuItem = {
  __typename?: 'InsightsLayoutGroupSubMenuItem';
  dashboardId: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
  title: Scalars['String'];
};

export type InsightsLayoutRoute = {
  __typename?: 'InsightsLayoutRoute';
  category: Scalars['String'];
  dashboardId: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
  title: Scalars['String'];
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InventoryDto = {
  __typename?: 'InventoryDto';
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isPlantBatch: Scalars['Boolean'];
  isQASample: Scalars['Boolean'];
  lastPrinted?: Maybe<Scalars['DateTime']>;
  lineItem?: Maybe<LineItemDto>;
  product?: Maybe<ProductDto>;
  remainingQuantity: Scalars['Decimal'];
  room?: Maybe<RoomDto>;
  startQuantity?: Maybe<Scalars['Decimal']>;
  status: InventoryStatus;
  strain?: Maybe<StrainDto>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityQuantity?: Maybe<Scalars['Decimal']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  unit: UnitOfMeasure;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type InventoryDtoConnection = {
  __typename?: 'InventoryDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InventoryDtoEdge = {
  __typename?: 'InventoryDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryDto;
};

export type InventoryFilterInput = {
  and?: InputMaybe<Array<InventoryFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPlantBatch?: InputMaybe<BooleanOperationFilterInput>;
  isQASample?: InputMaybe<BooleanOperationFilterInput>;
  lastPrinted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lineItem?: InputMaybe<LineItemFilterInput>;
  or?: InputMaybe<Array<InventoryFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  remainingQuantity?: InputMaybe<ComparableDecimalOperationFilterInput>;
  room?: InputMaybe<RoomFilterInput>;
  startQuantity?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  status?: InputMaybe<InventoryStatusOperationFilterInput>;
  strain?: InputMaybe<StrainFilterInput>;
  traceabilityQuantity?: InputMaybe<ComparableDecimalOperationFilterInput>;
  unit?: InputMaybe<UnitOfMeasureOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type InventorySampleDto = {
  __typename?: 'InventorySampleDto';
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  lastPrinted?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductDto>;
  room?: Maybe<RoomDto>;
  strain?: Maybe<StrainDto>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  unit: UnitOfMeasure;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type InventorySampleDtoConnection = {
  __typename?: 'InventorySampleDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventorySampleDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventorySampleDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InventorySampleDtoEdge = {
  __typename?: 'InventorySampleDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventorySampleDto;
};

export type InventorySampleFilterInput = {
  and?: InputMaybe<Array<InventorySampleFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lastPrinted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<InventorySampleFilterInput>>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type InventorySampleSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastPrinted?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type InventorySortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isPlantBatch?: InputMaybe<SortEnumType>;
  isQASample?: InputMaybe<SortEnumType>;
  lastPrinted?: InputMaybe<SortEnumType>;
  lineItem?: InputMaybe<LineItemSortInput>;
  product?: InputMaybe<ProductSortInput>;
  remainingQuantity?: InputMaybe<SortEnumType>;
  room?: InputMaybe<RoomSortInput>;
  startQuantity?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  strain?: InputMaybe<StrainSortInput>;
  traceabilityQuantity?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export enum InventoryStatus {
  Destroyed = 'DESTROYED',
  Distributed = 'DISTRIBUTED',
  InActive = 'IN_ACTIVE',
  InStock = 'IN_STOCK',
  InTransit = 'IN_TRANSIT',
  ScheduledForDestruction = 'SCHEDULED_FOR_DESTRUCTION',
  ScheduledForTransport = 'SCHEDULED_FOR_TRANSPORT',
  Unknown = 'UNKNOWN'
}

export type InventoryStatusOperationFilterInput = {
  eq?: InputMaybe<InventoryStatus>;
  in?: InputMaybe<Array<InventoryStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<InventoryStatus>;
  nin?: InputMaybe<Array<InventoryStatus>>;
};

export type InventoryTypeDto = {
  __typename?: 'InventoryTypeDto';
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  quantityType: QuantityType;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type InventoryTypeDtoConnection = {
  __typename?: 'InventoryTypeDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InventoryTypeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<InventoryTypeDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InventoryTypeDtoEdge = {
  __typename?: 'InventoryTypeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InventoryTypeDto;
};

export type InventoryTypeFilterInput = {
  and?: InputMaybe<Array<InventoryTypeFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InventoryTypeFilterInput>>;
  quantityType?: InputMaybe<QuantityTypeOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type InventoryTypeReferenceInput = {
  inventoryTypeId: Scalars['Uuid'];
  typeName?: InputMaybe<Scalars['String']>;
  wholesaleInventoryTypeId: Scalars['Int'];
};

export type InventoryTypeSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  quantityType?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type InventoryView = {
  __typename?: 'InventoryView';
  changeLogs?: Maybe<Array<Maybe<ChangeLog>>>;
  complianceId?: Maybe<Scalars['String']>;
  costComponents?: Maybe<Array<Maybe<CostComponent>>>;
  id: Scalars['Uuid'];
  initialQuantity: Scalars['Decimal'];
  inventoryId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastEvent?: Maybe<Scalars['String']>;
  lastImportSource?: Maybe<Scalars['String']>;
  license?: Maybe<CannabisLicense>;
  lossExpense: Scalars['Decimal'];
  productId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  sourcePackages?: Maybe<Array<Maybe<GrowFlowPackageSource>>>;
  status?: Maybe<Scalars['String']>;
  totalCost: Scalars['Decimal'];
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

export enum Jurisdiction {
  All = 'ALL',
  E2E = 'E2E',
  GfStandAlone = 'GF_STAND_ALONE',
  IsSandbox = 'IS_SANDBOX',
  MetrcBetaV2 = 'METRC_BETA_V2',
  Unknown = 'UNKNOWN',
  UsAkMetrc = 'US_AK_METRC',
  UsAl = 'US_AL',
  UsArBioTrack = 'US_AR_BIO_TRACK',
  UsAz = 'US_AZ',
  UsCaMetrc = 'US_CA_METRC',
  UsCoMetrc = 'US_CO_METRC',
  UsCt = 'US_CT',
  UsDcMetrc = 'US_DC_METRC',
  UsDeBioTrack = 'US_DE_BIO_TRACK',
  UsFl = 'US_FL',
  UsGa = 'US_GA',
  UsHemp = 'US_HEMP',
  UsHiBioTrack = 'US_HI_BIO_TRACK',
  UsIa = 'US_IA',
  UsId = 'US_ID',
  UsIlBioTrackMed = 'US_IL_BIO_TRACK_MED',
  UsIn = 'US_IN',
  UsKs = 'US_KS',
  UsKy = 'US_KY',
  UsLaMetrc = 'US_LA_METRC',
  UsMaMetrc = 'US_MA_METRC',
  UsMdMetrc = 'US_MD_METRC',
  UsMeMetrc = 'US_ME_METRC',
  UsMiMetrc = 'US_MI_METRC',
  UsMn = 'US_MN',
  UsMoMetrc = 'US_MO_METRC',
  UsMs = 'US_MS',
  UsMtMetrc = 'US_MT_METRC',
  UsNc = 'US_NC',
  UsNdBioTrack = 'US_ND_BIO_TRACK',
  UsNe = 'US_NE',
  UsNhBioTrack = 'US_NH_BIO_TRACK',
  UsNj = 'US_NJ',
  UsNmBioTrack = 'US_NM_BIO_TRACK',
  UsNvMetrc = 'US_NV_METRC',
  UsNvMjfRec = 'US_NV_MJF_REC',
  UsNyBioTrack = 'US_NY_BIO_TRACK',
  UsOhMetrc = 'US_OH_METRC',
  UsOk = 'US_OK',
  UsOrMetrc = 'US_OR_METRC',
  UsPaMjfRec = 'US_PA_MJF_REC',
  UsRi = 'US_RI',
  UsSc = 'US_SC',
  UsSdMetrc = 'US_SD_METRC',
  UsTn = 'US_TN',
  UsTx = 'US_TX',
  UsUt = 'US_UT',
  UsVa = 'US_VA',
  UsVt = 'US_VT',
  UsWaBioTrackRec = 'US_WA_BIO_TRACK_REC',
  UsWaMjfRec = 'US_WA_MJF_REC',
  UsWaMjfTest = 'US_WA_MJF_TEST',
  UsWi = 'US_WI',
  UsWvMetrc = 'US_WV_METRC',
  UsWy = 'US_WY'
}

export type JurisdictionDto = {
  __typename?: 'JurisdictionDto';
  complianceProvider?: Maybe<ComplianceProviderDto>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  regionAbbreviation?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  settings?: Maybe<JurisdictionSettingsDto>;
  traceabilityUrl?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type JurisdictionDtoConnection = {
  __typename?: 'JurisdictionDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JurisdictionDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<JurisdictionDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type JurisdictionDtoEdge = {
  __typename?: 'JurisdictionDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: JurisdictionDto;
};

export type JurisdictionOperationFilterInput = {
  eq?: InputMaybe<Jurisdiction>;
  in?: InputMaybe<Array<Jurisdiction>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Jurisdiction>;
  nin?: InputMaybe<Array<Jurisdiction>>;
};

export type JurisdictionSettingsDto = {
  __typename?: 'JurisdictionSettingsDto';
  id: Scalars['Int'];
};

export type KeyValuePairOfGuidAndHarvestedPlant = {
  __typename?: 'KeyValuePairOfGuidAndHarvestedPlant';
  key: Scalars['Uuid'];
  value: HarvestedPlant;
};

export type KeyValuePairOfGuidAndHarvestedPlantInput = {
  key: Scalars['Uuid'];
  value: HarvestedPlantInput;
};

export type LabelDto = {
  __typename?: 'LabelDto';
  config?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  inventoryType?: Maybe<InventoryTypeDto>;
  isDefault: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  lastUsed?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type: LabelType;
  typeName?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  useV2: Scalars['Boolean'];
  width?: Maybe<Scalars['Float']>;
};

export type LabelFilterInput = {
  and?: InputMaybe<Array<LabelFilterInput>>;
  config?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  inventoryType?: InputMaybe<InventoryTypeFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<BooleanOperationFilterInput>;
  isSample?: InputMaybe<BooleanOperationFilterInput>;
  isTemplate?: InputMaybe<BooleanOperationFilterInput>;
  lastUsed?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LabelFilterInput>>;
  type?: InputMaybe<LabelTypeOperationFilterInput>;
  typeName?: InputMaybe<StringOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  useV2?: InputMaybe<BooleanOperationFilterInput>;
};

export type LabelPageDto = {
  __typename?: 'LabelPageDto';
  cellHeight: Scalars['Decimal'];
  cellSpacing: Scalars['Decimal'];
  cellSpacingBottom: Scalars['Decimal'];
  cellSpacingLeft: Scalars['Decimal'];
  cellSpacingRight: Scalars['Decimal'];
  cellSpacingTop: Scalars['Decimal'];
  cellWidth: Scalars['Decimal'];
  cols: Scalars['Int'];
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  height: Scalars['Decimal'];
  horizontalAlign: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  marginBottom: Scalars['Decimal'];
  marginLeft: Scalars['Decimal'];
  marginRight: Scalars['Decimal'];
  marginTop: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  pageType: PageSizes;
  rows: Scalars['Int'];
  showBorder: Scalars['Boolean'];
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  width: Scalars['Decimal'];
};

export type LabelPageFilterInput = {
  and?: InputMaybe<Array<LabelPageFilterInput>>;
  cellHeight?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cellSpacing?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cellSpacingBottom?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cellSpacingLeft?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cellSpacingRight?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cellSpacingTop?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cellWidth?: InputMaybe<ComparableDecimalOperationFilterInput>;
  cols?: InputMaybe<ComparableInt32OperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  height?: InputMaybe<ComparableDecimalOperationFilterInput>;
  horizontalAlign?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  marginBottom?: InputMaybe<ComparableDecimalOperationFilterInput>;
  marginLeft?: InputMaybe<ComparableDecimalOperationFilterInput>;
  marginRight?: InputMaybe<ComparableDecimalOperationFilterInput>;
  marginTop?: InputMaybe<ComparableDecimalOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LabelPageFilterInput>>;
  pageType?: InputMaybe<PageSizesOperationFilterInput>;
  rows?: InputMaybe<ComparableInt32OperationFilterInput>;
  showBorder?: InputMaybe<BooleanOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  width?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type LabelPdfPrintItemModelInput = {
  itemId: Scalars['Int'];
  labelId: Scalars['Int'];
  qty: Scalars['Int'];
};

export type LabelPdfPrintModelInput = {
  items?: InputMaybe<Array<InputMaybe<LabelPdfPrintItemModelInput>>>;
  labelPageId: Scalars['Int'];
  preview: Scalars['Boolean'];
  printHeaderId?: InputMaybe<Scalars['Int']>;
  printHeaderItemId?: InputMaybe<Scalars['Int']>;
  printerId?: InputMaybe<Scalars['Long']>;
  type: LabelType;
};

export type LabelSortInput = {
  config?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inventoryType?: InputMaybe<InventoryTypeSortInput>;
  isDefault?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isPrimary?: InputMaybe<SortEnumType>;
  isSample?: InputMaybe<SortEnumType>;
  isTemplate?: InputMaybe<SortEnumType>;
  lastUsed?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  typeName?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  useV2?: InputMaybe<SortEnumType>;
};

export enum LabelType {
  Batch = 'BATCH',
  Disposal = 'DISPOSAL',
  EmployeeBadgeLabel = 'EMPLOYEE_BADGE_LABEL',
  EmployeeSample = 'EMPLOYEE_SAMPLE',
  Inventory = 'INVENTORY',
  OrderHeader = 'ORDER_HEADER',
  OrderHeaderItem = 'ORDER_HEADER_ITEM',
  Plant = 'PLANT',
  Retail = 'RETAIL',
  Unknown = 'UNKNOWN'
}

export type LabelTypeOperationFilterInput = {
  eq?: InputMaybe<LabelType>;
  in?: InputMaybe<Array<LabelType>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<LabelType>;
  nin?: InputMaybe<Array<LabelType>>;
};

export type License = Node & {
  __typename?: 'License';
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: Maybe<LicenseActivationStatus>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<BillingStatus>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `FeatureUsageLicense`. */
  featureUsageLicensesByLicense: FeatureUsageLicensesConnection;
  id: Scalars['Int'];
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  /** Reads and enables pagination through a set of `LockoutGracePeriod`. */
  lockoutGracePeriodsByLicense: LockoutGracePeriodsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `License`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  productType: ProductType;
  /** Reads and enables pagination through a set of `ProductUsageStat`. */
  productUsageStats: ProductUsageStatsConnection;
  /** Reads and enables pagination through a set of `ReupPurchaseOrder`. */
  reupPurchaseOrdersByBuyerId: ReupPurchaseOrdersConnection;
  state: Scalars['String'];
};


export type LicenseFeatureUsageLicensesByLicenseArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureUsageLicenseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeatureUsageLicensesOrderBy>>;
};


export type LicenseLockoutGracePeriodsByLicenseArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LockoutGracePeriodFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LockoutGracePeriodsOrderBy>>;
};


export type LicenseProductUsageStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProductUsageStatFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductUsageStatsOrderBy>>;
};


export type LicenseReupPurchaseOrdersByBuyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ReupPurchaseOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReupPurchaseOrdersOrderBy>>;
};

export enum LicenseActivationStatus {
  Cancelled = 'CANCELLED',
  New = 'NEW',
  Paused = 'PAUSED',
  Paying = 'PAYING',
  Test = 'TEST',
  Trial = 'TRIAL'
}

/** A filter to be used against `License` object types. All fields are combined with a logical ‘and.’ */
export type LicenseFilter = {
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressLine1` field. */
  addressLine1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressLine2` field. */
  addressLine2?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LicenseFilter>>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `licenseName` field. */
  licenseName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `licenseNumber` field. */
  licenseNumber?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LicenseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LicenseFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
};

export enum LicenseGroup {
  Distributor = 'DISTRIBUTOR',
  Lab = 'LAB',
  MedicalRetail = 'MEDICAL_RETAIL',
  MicroBusiness = 'MICRO_BUSINESS',
  Processor = 'PROCESSOR',
  Producer = 'PRODUCER',
  ProducerProcessor = 'PRODUCER_PROCESSOR',
  Retail = 'RETAIL',
  SpecialEvent = 'SPECIAL_EVENT',
  Transporter = 'TRANSPORTER',
  Tribal = 'TRIBAL',
  Unknown = 'UNKNOWN'
}

/** An input for mutations affecting `License` */
export type LicenseInput = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType: ProductType;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state: Scalars['String'];
};

/** The fields on `license` to look up the row to connect. */
export type LicenseLicensesPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `license` to look up the row to delete. */
export type LicenseLicensesPkeyDelete = {
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type LicenseNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `license` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LicenseNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `license` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `featureUsageLicense` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
  patch: FeatureUsageLicensePatch;
};

/** The fields on `license` to look up the row to update. */
export type LicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyUsingLicensesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: UpdateLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type LicenseOnLicenseForLicensesOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: OrganizationPatch;
};

/** The fields on `license` to look up the row to update. */
export type LicenseOnLicenseForLicensesOrganizationId2FkeyUsingLicensesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: UpdateLicenseOnLicenseForLicensesOrganizationId2FkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type LicenseOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lockoutGracePeriod` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lockoutGracePeriod` being updated. */
  patch: LockoutGracePeriodPatch;
};

/** The fields on `license` to look up the row to update. */
export type LicenseOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyUsingLicensesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: UpdateLicenseOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type LicenseOnProductUsageStatForProductUsageStatsLicenseIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productUsageStat` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productUsageStat` being updated. */
  patch: ProductUsageStatPatch;
};

/** The fields on `license` to look up the row to update. */
export type LicenseOnProductUsageStatForProductUsageStatsLicenseIdFkeyUsingLicensesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: UpdateLicenseOnProductUsageStatForProductUsageStatsLicenseIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type LicenseOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `reupPurchaseOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `reupPurchaseOrder` being updated. */
  patch: ReupPurchaseOrderPatch;
};

/** The fields on `license` to look up the row to update. */
export type LicenseOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyUsingLicensesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: UpdateLicenseOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyPatch;
};

/** Represents an update to a `License`. Fields that are set will be updated. */
export type LicensePatch = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId?: InputMaybe<Scalars['String']>;
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type LicenseTypeDto = {
  __typename?: 'LicenseTypeDto';
  id: Scalars['Int'];
  licenseGroupType: LicenseGroup;
  name?: Maybe<Scalars['String']>;
};

/** A connection to a list of `License` values. */
export type LicensesConnection = {
  __typename?: 'LicensesConnection';
  /** A list of edges which contains the `License` and cursor to aid in pagination. */
  edges: Array<LicensesEdge>;
  /** A list of `License` objects. */
  nodes: Array<Maybe<License>>;
  /** The count of *all* `License` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `License` edge in the connection. */
export type LicensesEdge = {
  __typename?: 'LicensesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `License` at the end of the edge. */
  node?: Maybe<License>;
};

/** Methods to use when ordering `License`. */
export enum LicensesOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ActivationStatusAsc = 'ACTIVATION_STATUS_ASC',
  ActivationStatusDesc = 'ACTIVATION_STATUS_DESC',
  AddressLine1Asc = 'ADDRESS_LINE1_ASC',
  AddressLine1Desc = 'ADDRESS_LINE1_DESC',
  AddressLine2Asc = 'ADDRESS_LINE2_ASC',
  AddressLine2Desc = 'ADDRESS_LINE2_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseNameAsc = 'LICENSE_NAME_ASC',
  LicenseNameDesc = 'LICENSE_NAME_DESC',
  LicenseNumberAsc = 'LICENSE_NUMBER_ASC',
  LicenseNumberDesc = 'LICENSE_NUMBER_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC'
}

/** Input for the nested mutation of `organization` in the `LicenseInput` mutation. */
export type LicensesOrganizationId2FkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrganizationOrganizationsPkeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrganizationNodeIdConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<LicensesOrganizationId2FkeyOrganizationsCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByKey?: InputMaybe<OrganizationOrganizationsPkeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByEnvironmentId?: InputMaybe<OrganizationOnLicenseForLicensesOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrganizationOnLicenseForLicensesOrganizationId2FkeyUsingOrganizationsPkeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<LicenseOnLicenseForLicensesOrganizationId2FkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `license` in the `OrganizationInput` mutation. */
export type LicensesOrganizationId2FkeyInverseInput = {
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectById?: InputMaybe<Array<LicenseLicensesPkeyConnect>>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<LicenseNodeIdConnect>>;
  /** A `LicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<LicensesOrganizationId2FkeyLicensesCreateInput>>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<LicenseLicensesPkeyDelete>>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<LicenseNodeIdDelete>>;
  /** Flag indicating whether all other `license` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateById?: InputMaybe<Array<LicenseOnLicenseForLicensesOrganizationId2FkeyUsingLicensesPkeyUpdate>>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<OrganizationOnLicenseForLicensesOrganizationId2FkeyNodeIdUpdate>>;
};

/** The `license` to be created by this mutation. */
export type LicensesOrganizationId2FkeyLicensesCreateInput = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType: ProductType;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state: Scalars['String'];
};

/** The `organization` to be created by this mutation. */
export type LicensesOrganizationId2FkeyOrganizationsCreateInput = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  billing: OrganizationBillingInfoInput;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name: Scalars['String'];
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  referralId?: InputMaybe<Scalars['String']>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

export type LineItemDto = {
  __typename?: 'LineItemDto';
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inventory?: Maybe<InventoryDto>;
  isDeleted: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  isVoided: Scalars['Boolean'];
  lastPrinted?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Decimal']>;
  product?: Maybe<ProductDto>;
  purchaseOrder?: Maybe<PurchaseOrderDto>;
  qtyPacked?: Maybe<Scalars['Decimal']>;
  quantityReceived?: Maybe<Scalars['Decimal']>;
  requestedQuantity?: Maybe<Scalars['Decimal']>;
  room?: Maybe<RoomDto>;
  sourceInventory?: Maybe<InventoryDto>;
  strain?: Maybe<StrainDto>;
  toComplianceId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Decimal']>;
  transferDate?: Maybe<Scalars['DateTime']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type LineItemDtoConnection = {
  __typename?: 'LineItemDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LineItemDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LineItemDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LineItemDtoEdge = {
  __typename?: 'LineItemDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LineItemDto;
};

export type LineItemFilterInput = {
  and?: InputMaybe<Array<LineItemFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  inventory?: InputMaybe<InventoryFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isSample?: InputMaybe<BooleanOperationFilterInput>;
  isVoided?: InputMaybe<BooleanOperationFilterInput>;
  lastPrinted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<LineItemFilterInput>>;
  price?: InputMaybe<ComparableDecimalOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  purchaseOrder?: InputMaybe<PurchaseOrderFilterInput>;
  qtyPacked?: InputMaybe<ComparableDecimalOperationFilterInput>;
  quantityReceived?: InputMaybe<ComparableDecimalOperationFilterInput>;
  requestedQuantity?: InputMaybe<ComparableDecimalOperationFilterInput>;
  strain?: InputMaybe<StrainFilterInput>;
  toComplianceId?: InputMaybe<StringOperationFilterInput>;
  total?: InputMaybe<ComparableDecimalOperationFilterInput>;
  transferDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type LineItemFilters = {
  __typename?: 'LineItemFilters';
  avgSales: Scalars['Decimal'];
  inventoryTypes?: Maybe<Array<Maybe<FilterOptionItem>>>;
  priceMax?: Maybe<Scalars['Decimal']>;
  priceMin?: Maybe<Scalars['Decimal']>;
  productCategories?: Maybe<Array<Maybe<FilterOptionItem>>>;
  productSizeMax?: Maybe<Scalars['Decimal']>;
  productSizeMin?: Maybe<Scalars['Decimal']>;
  products?: Maybe<Array<Maybe<FilterOptionItem>>>;
  strains?: Maybe<Array<Maybe<FilterOptionItem>>>;
  totalSales: Scalars['Decimal'];
  vendors?: Maybe<Array<Maybe<FilterOptionItem>>>;
};

export type LineItemSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inventory?: InputMaybe<InventorySortInput>;
  isDeleted?: InputMaybe<SortEnumType>;
  isSample?: InputMaybe<SortEnumType>;
  isVoided?: InputMaybe<SortEnumType>;
  lastPrinted?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  purchaseOrder?: InputMaybe<PurchaseOrderSortInput>;
  qtyPacked?: InputMaybe<SortEnumType>;
  quantityReceived?: InputMaybe<SortEnumType>;
  requestedQuantity?: InputMaybe<SortEnumType>;
  strain?: InputMaybe<StrainSortInput>;
  toComplianceId?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  transferDate?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

/** Input for the nested mutation of `reupPurchaseOrder` in the `ReupLineItemInput` mutation. */
export type LineItemsPurchaseOrderIdFkeyInput = {
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  connectById?: InputMaybe<ReupPurchaseOrderPurchaseOrdersPkeyConnect>;
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ReupPurchaseOrderNodeIdConnect>;
  /** A `ReupPurchaseOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<LineItemsPurchaseOrderIdFkeyPurchaseOrdersCreateInput>;
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  deleteById?: InputMaybe<ReupPurchaseOrderPurchaseOrdersPkeyDelete>;
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ReupPurchaseOrderNodeIdDelete>;
  /** The primary key(s) and patch data for `reupPurchaseOrder` for the far side of the relationship. */
  updateById?: InputMaybe<ReupPurchaseOrderOnReupLineItemForLineItemsPurchaseOrderIdFkeyUsingPurchaseOrdersPkeyUpdate>;
  /** The primary key(s) and patch data for `reupPurchaseOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReupLineItemOnReupLineItemForLineItemsPurchaseOrderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reupLineItem` in the `ReupPurchaseOrderInput` mutation. */
export type LineItemsPurchaseOrderIdFkeyInverseInput = {
  /** The primary key(s) for `reupLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ReupLineItemLineItemsPkeyConnect>>;
  /** The primary key(s) for `reupLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReupLineItemNodeIdConnect>>;
  /** A `ReupLineItemInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<LineItemsPurchaseOrderIdFkeyLineItemsCreateInput>>;
  /** The primary key(s) for `reupLineItem` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ReupLineItemLineItemsPkeyDelete>>;
  /** The primary key(s) for `reupLineItem` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ReupLineItemNodeIdDelete>>;
  /** Flag indicating whether all other `reupLineItem` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `reupLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ReupLineItemOnReupLineItemForLineItemsPurchaseOrderIdFkeyUsingLineItemsPkeyUpdate>>;
  /** The primary key(s) and patch data for `reupLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ReupPurchaseOrderOnReupLineItemForLineItemsPurchaseOrderIdFkeyNodeIdUpdate>>;
};

/** The `reupLineItem` to be created by this mutation. */
export type LineItemsPurchaseOrderIdFkeyLineItemsCreateInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderSessionId?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  receivedQuantity?: InputMaybe<Scalars['Int']>;
  requestedQuantity?: InputMaybe<Scalars['Int']>;
  requiredQuantity?: InputMaybe<Scalars['Int']>;
  reupPurchaseOrderToPurchaseOrderId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInput>;
  suggestedQuantity?: InputMaybe<Scalars['Int']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
  unitPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** The `reupPurchaseOrder` to be created by this mutation. */
export type LineItemsPurchaseOrderIdFkeyPurchaseOrdersCreateInput = {
  buyerId?: InputMaybe<Scalars['Int']>;
  displayPoNumber?: InputMaybe<Scalars['String']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  licenseToBuyerId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInput>;
  matchedTransferId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  note?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  reupLineItemsUsingId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInverseInput>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
};

export type ListComparableInt32OperationFilterInput = {
  all?: InputMaybe<ComparableInt32OperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ComparableInt32OperationFilterInput>;
  some?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ListFilterInputTypeOfAccountFilterInput = {
  all?: InputMaybe<AccountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountFilterInput>;
  some?: InputMaybe<AccountFilterInput>;
};

export type ListFilterInputTypeOfAccountRolesFilterInput = {
  all?: InputMaybe<AccountRolesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountRolesFilterInput>;
  some?: InputMaybe<AccountRolesFilterInput>;
};

export type ListFilterInputTypeOfAccountUserFilterInput = {
  all?: InputMaybe<AccountUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountUserFilterInput>;
  some?: InputMaybe<AccountUserFilterInput>;
};

export type ListFilterInputTypeOfCcrsCsvUploadFilterInput = {
  all?: InputMaybe<CcrsCsvUploadFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CcrsCsvUploadFilterInput>;
  some?: InputMaybe<CcrsCsvUploadFilterInput>;
};

export type ListFilterInputTypeOfRoomTableFilterInput = {
  all?: InputMaybe<RoomTableFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RoomTableFilterInput>;
  some?: InputMaybe<RoomTableFilterInput>;
};

export type ListFilterInputTypeOfStoreFrontPermissionFilterInput = {
  all?: InputMaybe<StoreFrontPermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StoreFrontPermissionFilterInput>;
  some?: InputMaybe<StoreFrontPermissionFilterInput>;
};

export type ListFilterInputTypeOfStoreFrontUserVendorFilterInput = {
  all?: InputMaybe<StoreFrontUserVendorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StoreFrontUserVendorFilterInput>;
  some?: InputMaybe<StoreFrontUserVendorFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type LockoutGracePeriod = Node & {
  __typename?: 'LockoutGracePeriod';
  createdAt: Scalars['Datetime'];
  gracePeriodEndAt?: Maybe<Scalars['Datetime']>;
  gracePeriodStartAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  license: Scalars['Int'];
  /** Reads a single `License` that is related to this `LockoutGracePeriod`. */
  licenseByLicense?: Maybe<License>;
  lockoutBehavior?: Maybe<LockoutGracePeriodBehavior>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  requestor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export enum LockoutGracePeriodBehavior {
  DisableLockout = 'DISABLE_LOCKOUT',
  ForceLockout = 'FORCE_LOCKOUT',
  ForceWarning = 'FORCE_WARNING'
}

/** A filter to be used against `LockoutGracePeriod` object types. All fields are combined with a logical ‘and.’ */
export type LockoutGracePeriodFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockoutGracePeriodFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `gracePeriodEndAt` field. */
  gracePeriodEndAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `gracePeriodStartAt` field. */
  gracePeriodStartAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `license` field. */
  license?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockoutGracePeriodFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockoutGracePeriodFilter>>;
  /** Filter by the object’s `requestor` field. */
  requestor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `LockoutGracePeriod` */
export type LockoutGracePeriodInput = {
  gracePeriodEndAt?: InputMaybe<Scalars['Datetime']>;
  gracePeriodStartAt?: InputMaybe<Scalars['Datetime']>;
  license?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<LockoutGracePeriodLicenseFkeyInput>;
  lockoutBehavior?: InputMaybe<LockoutGracePeriodBehavior>;
  note?: InputMaybe<Scalars['String']>;
  requestor?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `license` in the `LockoutGracePeriodInput` mutation. */
export type LockoutGracePeriodLicenseFkeyInput = {
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectById?: InputMaybe<LicenseLicensesPkeyConnect>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<LicenseNodeIdConnect>;
  /** A `LicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<LockoutGracePeriodLicenseFkeyLicensesCreateInput>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteById?: InputMaybe<LicenseLicensesPkeyDelete>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<LicenseNodeIdDelete>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateById?: InputMaybe<LicenseOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyUsingLicensesPkeyUpdate>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<LockoutGracePeriodOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lockoutGracePeriod` in the `LicenseInput` mutation. */
export type LockoutGracePeriodLicenseFkeyInverseInput = {
  /** The primary key(s) for `lockoutGracePeriod` for the far side of the relationship. */
  connectById?: InputMaybe<Array<LockoutGracePeriodLockoutGracePeriodPkeyConnect>>;
  /** The primary key(s) for `lockoutGracePeriod` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<LockoutGracePeriodNodeIdConnect>>;
  /** A `LockoutGracePeriodInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<LockoutGracePeriodLicenseFkeyLockoutGracePeriodCreateInput>>;
  /** The primary key(s) for `lockoutGracePeriod` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<LockoutGracePeriodLockoutGracePeriodPkeyDelete>>;
  /** The primary key(s) for `lockoutGracePeriod` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<LockoutGracePeriodNodeIdDelete>>;
  /** Flag indicating whether all other `lockoutGracePeriod` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lockoutGracePeriod` for the far side of the relationship. */
  updateById?: InputMaybe<Array<LockoutGracePeriodOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyUsingLockoutGracePeriodPkeyUpdate>>;
  /** The primary key(s) and patch data for `lockoutGracePeriod` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<LicenseOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyNodeIdUpdate>>;
};

/** The `license` to be created by this mutation. */
export type LockoutGracePeriodLicenseFkeyLicensesCreateInput = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType: ProductType;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state: Scalars['String'];
};

/** The `lockoutGracePeriod` to be created by this mutation. */
export type LockoutGracePeriodLicenseFkeyLockoutGracePeriodCreateInput = {
  gracePeriodEndAt?: InputMaybe<Scalars['Datetime']>;
  gracePeriodStartAt?: InputMaybe<Scalars['Datetime']>;
  licenseToLicense?: InputMaybe<LockoutGracePeriodLicenseFkeyInput>;
  lockoutBehavior?: InputMaybe<LockoutGracePeriodBehavior>;
  note?: InputMaybe<Scalars['String']>;
  requestor?: InputMaybe<Scalars['String']>;
};

/** The fields on `lockoutGracePeriod` to look up the row to connect. */
export type LockoutGracePeriodLockoutGracePeriodPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lockoutGracePeriod` to look up the row to delete. */
export type LockoutGracePeriodLockoutGracePeriodPkeyDelete = {
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type LockoutGracePeriodNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lockoutGracePeriod` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LockoutGracePeriodNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lockoutGracePeriod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LockoutGracePeriodOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `license` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: LicensePatch;
};

/** The fields on `lockoutGracePeriod` to look up the row to update. */
export type LockoutGracePeriodOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyUsingLockoutGracePeriodPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `lockoutGracePeriod` being updated. */
  patch: UpdateLockoutGracePeriodOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyPatch;
};

/** Represents an update to a `LockoutGracePeriod`. Fields that are set will be updated. */
export type LockoutGracePeriodPatch = {
  gracePeriodEndAt?: InputMaybe<Scalars['Datetime']>;
  gracePeriodStartAt?: InputMaybe<Scalars['Datetime']>;
  license?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<LockoutGracePeriodLicenseFkeyInput>;
  lockoutBehavior?: InputMaybe<LockoutGracePeriodBehavior>;
  note?: InputMaybe<Scalars['String']>;
  requestor?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `LockoutGracePeriod` values. */
export type LockoutGracePeriodsConnection = {
  __typename?: 'LockoutGracePeriodsConnection';
  /** A list of edges which contains the `LockoutGracePeriod` and cursor to aid in pagination. */
  edges: Array<LockoutGracePeriodsEdge>;
  /** A list of `LockoutGracePeriod` objects. */
  nodes: Array<Maybe<LockoutGracePeriod>>;
  /** The count of *all* `LockoutGracePeriod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LockoutGracePeriod` edge in the connection. */
export type LockoutGracePeriodsEdge = {
  __typename?: 'LockoutGracePeriodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LockoutGracePeriod` at the end of the edge. */
  node?: Maybe<LockoutGracePeriod>;
};

/** Methods to use when ordering `LockoutGracePeriod`. */
export enum LockoutGracePeriodsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GracePeriodEndAtAsc = 'GRACE_PERIOD_END_AT_ASC',
  GracePeriodEndAtDesc = 'GRACE_PERIOD_END_AT_DESC',
  GracePeriodStartAtAsc = 'GRACE_PERIOD_START_AT_ASC',
  GracePeriodStartAtDesc = 'GRACE_PERIOD_START_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseAsc = 'LICENSE_ASC',
  LicenseDesc = 'LICENSE_DESC',
  LockoutBehaviorAsc = 'LOCKOUT_BEHAVIOR_ASC',
  LockoutBehaviorDesc = 'LOCKOUT_BEHAVIOR_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RequestorAsc = 'REQUESTOR_ASC',
  RequestorDesc = 'REQUESTOR_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum LockoutStatus {
  FullLockout = 'FULL_LOCKOUT',
  LockoutTemporarilySuspended = 'LOCKOUT_TEMPORARILY_SUSPENDED',
  NoLockout = 'NO_LOCKOUT',
  ShowWarning = 'SHOW_WARNING'
}

export type LoggedAction = Node & {
  __typename?: 'LoggedAction';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String'];
  /** Wall clock time at which audited event's trigger call occurred */
  actionTstampClk: Scalars['Datetime'];
  /** Statement start timestamp for tx in which audited event occurred */
  actionTstampStm: Scalars['Datetime'];
  /** Transaction start timestamp for tx in which audited event occurred */
  actionTstampTx: Scalars['Datetime'];
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  applicationName?: Maybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changedFields?: Maybe<Scalars['JSON']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  clientAddr?: Maybe<Scalars['InternetAddress']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  clientPort?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  clientQuery?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  eventId: Scalars['BigInt'];
  /** growflow added client name from the JWT of the calling user */
  growflowClientName?: Maybe<Scalars['String']>;
  /** email from the JWT of the calling user */
  growflowUserEmail?: Maybe<Scalars['String']>;
  /** JWT.global_user_id of the calling user */
  growflowUserId?: Maybe<Scalars['String']>;
  hasuraUser?: Maybe<Scalars['JSON']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['BigFloat'];
  rowData?: Maybe<Scalars['JSON']>;
  /** The "id" column of the modified row */
  rowId?: Maybe<Scalars['String']>;
  /** Database schema audited table for this event is in */
  schemaName: Scalars['String'];
  /** Login / session user whose statement caused the audited event */
  sessionUserName?: Maybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statementOnly: Scalars['Boolean'];
  /** Non-schema-qualified table name of table event occured in */
  tableName: Scalars['String'];
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transactionId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LoggedAction` object types. All fields are combined with a logical ‘and.’ */
export type LoggedActionFilter = {
  /** Filter by the object’s `action` field. */
  action?: InputMaybe<StringFilter>;
  /** Filter by the object’s `actionTstampClk` field. */
  actionTstampClk?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `actionTstampStm` field. */
  actionTstampStm?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `actionTstampTx` field. */
  actionTstampTx?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LoggedActionFilter>>;
  /** Filter by the object’s `applicationName` field. */
  applicationName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `changedFields` field. */
  changedFields?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `clientPort` field. */
  clientPort?: InputMaybe<IntFilter>;
  /** Filter by the object’s `clientQuery` field. */
  clientQuery?: InputMaybe<StringFilter>;
  /** Filter by the object’s `growflowClientName` field. */
  growflowClientName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `growflowUserEmail` field. */
  growflowUserEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `growflowUserId` field. */
  growflowUserId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasuraUser` field. */
  hasuraUser?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LoggedActionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LoggedActionFilter>>;
  /** Filter by the object’s `rowData` field. */
  rowData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `schemaName` field. */
  schemaName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sessionUserName` field. */
  sessionUserName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `statementOnly` field. */
  statementOnly?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `tableName` field. */
  tableName?: InputMaybe<StringFilter>;
};

/** A connection to a list of `LoggedAction` values. */
export type LoggedActionsConnection = {
  __typename?: 'LoggedActionsConnection';
  /** A list of edges which contains the `LoggedAction` and cursor to aid in pagination. */
  edges: Array<LoggedActionsEdge>;
  /** A list of `LoggedAction` objects. */
  nodes: Array<Maybe<LoggedAction>>;
  /** The count of *all* `LoggedAction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LoggedAction` edge in the connection. */
export type LoggedActionsEdge = {
  __typename?: 'LoggedActionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LoggedAction` at the end of the edge. */
  node?: Maybe<LoggedAction>;
};

/** Methods to use when ordering `LoggedAction`. */
export enum LoggedActionsOrderBy {
  ActionAsc = 'ACTION_ASC',
  ActionDesc = 'ACTION_DESC',
  ActionTstampClkAsc = 'ACTION_TSTAMP_CLK_ASC',
  ActionTstampClkDesc = 'ACTION_TSTAMP_CLK_DESC',
  ActionTstampStmAsc = 'ACTION_TSTAMP_STM_ASC',
  ActionTstampStmDesc = 'ACTION_TSTAMP_STM_DESC',
  ActionTstampTxAsc = 'ACTION_TSTAMP_TX_ASC',
  ActionTstampTxDesc = 'ACTION_TSTAMP_TX_DESC',
  ApplicationNameAsc = 'APPLICATION_NAME_ASC',
  ApplicationNameDesc = 'APPLICATION_NAME_DESC',
  ChangedFieldsAsc = 'CHANGED_FIELDS_ASC',
  ChangedFieldsDesc = 'CHANGED_FIELDS_DESC',
  ClientAddrAsc = 'CLIENT_ADDR_ASC',
  ClientAddrDesc = 'CLIENT_ADDR_DESC',
  ClientPortAsc = 'CLIENT_PORT_ASC',
  ClientPortDesc = 'CLIENT_PORT_DESC',
  ClientQueryAsc = 'CLIENT_QUERY_ASC',
  ClientQueryDesc = 'CLIENT_QUERY_DESC',
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  GrowflowClientNameAsc = 'GROWFLOW_CLIENT_NAME_ASC',
  GrowflowClientNameDesc = 'GROWFLOW_CLIENT_NAME_DESC',
  GrowflowUserEmailAsc = 'GROWFLOW_USER_EMAIL_ASC',
  GrowflowUserEmailDesc = 'GROWFLOW_USER_EMAIL_DESC',
  GrowflowUserIdAsc = 'GROWFLOW_USER_ID_ASC',
  GrowflowUserIdDesc = 'GROWFLOW_USER_ID_DESC',
  HasuraUserAsc = 'HASURA_USER_ASC',
  HasuraUserDesc = 'HASURA_USER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RelidAsc = 'RELID_ASC',
  RelidDesc = 'RELID_DESC',
  RowDataAsc = 'ROW_DATA_ASC',
  RowDataDesc = 'ROW_DATA_DESC',
  RowIdAsc = 'ROW_ID_ASC',
  RowIdDesc = 'ROW_ID_DESC',
  SchemaNameAsc = 'SCHEMA_NAME_ASC',
  SchemaNameDesc = 'SCHEMA_NAME_DESC',
  SessionUserNameAsc = 'SESSION_USER_NAME_ASC',
  SessionUserNameDesc = 'SESSION_USER_NAME_DESC',
  StatementOnlyAsc = 'STATEMENT_ONLY_ASC',
  StatementOnlyDesc = 'STATEMENT_ONLY_DESC',
  TableNameAsc = 'TABLE_NAME_ASC',
  TableNameDesc = 'TABLE_NAME_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC'
}

export type ManifestJobInput = {
  arrivalTime: Scalars['DateTime'];
  deliveryType?: InputMaybe<Scalars['String']>;
  departureTime: Scalars['DateTime'];
  driverName?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isRetail?: InputMaybe<Scalars['Boolean']>;
  isSandbox?: InputMaybe<Scalars['Boolean']>;
  licenseNumber: Scalars['String'];
  lineItems: Array<ManifestLineItemInput>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']>;
  receiver: ManifestJobInputVendorInput;
  seller: ManifestJobInputVendorInput;
  transactionId: Scalars['String'];
  transportationLicenseNumber?: InputMaybe<Scalars['String']>;
  vehicle: ManifestJobInputVehicleInput;
};

export type ManifestJobInputVehicleInput = {
  color?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  plate?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type ManifestJobInputVendorInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  licenseNumber: Scalars['String'];
  line1?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  ubi: Scalars['String'];
};

export type ManifestLineItemInput = {
  complianceId: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  inventoryDescription?: InputMaybe<Scalars['String']>;
  inventoryName?: InputMaybe<Scalars['String']>;
  isMedical: Scalars['Boolean'];
  qty: Scalars['Decimal'];
  servingsPerUnitOrServingSize?: InputMaybe<Scalars['String']>;
  strain?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<Scalars['String']>;
  unitOfMeasure?: InputMaybe<Scalars['String']>;
  weightPerUnit?: InputMaybe<Scalars['Decimal']>;
};

export type ManufactureNewPackageInput = {
  inputs?: InputMaybe<Array<InputMaybe<ConversionInput>>>;
  inventoryConversionId: Scalars['Int'];
  license?: InputMaybe<CannabisLicenseInput>;
  outputComplianceId?: InputMaybe<Scalars['String']>;
  outputInventoryId: Scalars['Uuid'];
  outputProductName?: InputMaybe<Scalars['String']>;
  outputQuantity: Scalars['Decimal'];
  outputTypeName?: InputMaybe<Scalars['String']>;
  outputWholesaleInventoryId: Scalars['Int'];
};

export type MergeUsersInput = {
  userToCollapseAuth0Ids: Array<Scalars['String']>;
  userToRetainGlobalId: Scalars['UUID'];
};

export type MergeUsersPayload = {
  __typename?: 'MergeUsersPayload';
  deletedGlobalUserIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  user?: Maybe<User>;
};

export enum MjfBatchStatusType {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export enum MjfDisposalReasonType {
  Cull = 'CULL',
  DailyWaste = 'DAILY_WASTE',
  Damaged = 'DAMAGED',
  Died = 'DIED',
  Expired = 'EXPIRED',
  FailedQa = 'FAILED_QA',
  Infestation = 'INFESTATION',
  Mandated = 'MANDATED',
  Other = 'OTHER',
  ProductReturned = 'PRODUCT_RETURNED',
  Pruning = 'PRUNING',
  QualityControl = 'QUALITY_CONTROL',
  Spoilage = 'SPOILAGE',
  Trim = 'TRIM',
  Waste = 'WASTE'
}

export enum MjfDisposalSourceType {
  Batch = 'BATCH',
  DailyPlantWaste = 'DAILY_PLANT_WASTE',
  Inventory = 'INVENTORY',
  InventoryConversion = 'INVENTORY_CONVERSION',
  Plant = 'PLANT',
  PlantBatch = 'PLANT_BATCH'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a new user */
  addUser?: Maybe<UserDto>;
  archiveOrganization?: Maybe<Organization>;
  /** Creates a new stand alone account */
  createAccount?: Maybe<AccountDto>;
  createDejavooPayment: Transaction;
  createEmployee?: Maybe<EmployeeDto>;
  /** Creates a single `FeatureUsageLicense`. */
  createFeatureUsageLicense?: Maybe<CreateFeatureUsageLicensePayload>;
  /** Creates a single `License`. */
  createLicense?: Maybe<CreateLicensePayload>;
  /** Creates a single `LockoutGracePeriod`. */
  createLockoutGracePeriod?: Maybe<CreateLockoutGracePeriodPayload>;
  createMockPayment: Transaction;
  /** Creates a single `Note`. */
  createNote?: Maybe<CreateNotePayload>;
  /** Creates a single `Organization`. */
  createOrganization?: Maybe<CreateOrganizationPayload>;
  createPaytenderPayment: Transaction;
  createPublicBillingPortalLink?: Maybe<BillingPortalLink>;
  /** Creates a single `ReupLineItem`. */
  createReupLineItem?: Maybe<CreateReupLineItemPayload>;
  /** Creates a single `ReupProduct`. */
  createReupProduct?: Maybe<CreateReupProductPayload>;
  /** Creates a single `ReupPurchaseOrder`. */
  createReupPurchaseOrder?: Maybe<CreateReupPurchaseOrderPayload>;
  createRoom?: Maybe<RoomDto>;
  createRoomTable?: Maybe<RoomTableDto>;
  createShortenedLink?: Maybe<CreateShortenedLinkResponse>;
  createStrain?: Maybe<StrainDto>;
  /** Creates a single `TaxRate`. */
  createTaxRate?: Maybe<CreateTaxRatePayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserLinkedIdentity`. */
  createUserLinkedIdentity?: Maybe<CreateUserLinkedIdentityPayload>;
  createVehicle?: Maybe<VehicleDto>;
  deleteEmployee: Scalars['Boolean'];
  /** Deletes a single `FeatureUsageLicense` using a unique key. */
  deleteFeatureUsageLicense?: Maybe<DeleteFeatureUsageLicensePayload>;
  /** Deletes a single `FeatureUsageLicense` using a unique key. */
  deleteFeatureUsageLicenseByFeatureusageAndLicense?: Maybe<DeleteFeatureUsageLicensePayload>;
  /** Deletes a single `FeatureUsageLicense` using its globally unique id. */
  deleteFeatureUsageLicenseByNodeId?: Maybe<DeleteFeatureUsageLicensePayload>;
  /** Deletes a single `License` using a unique key. */
  deleteLicense?: Maybe<DeleteLicensePayload>;
  /** Deletes a single `License` using its globally unique id. */
  deleteLicenseByNodeId?: Maybe<DeleteLicensePayload>;
  /** Deletes a single `LockoutGracePeriod` using a unique key. */
  deleteLockoutGracePeriod?: Maybe<DeleteLockoutGracePeriodPayload>;
  /** Deletes a single `LockoutGracePeriod` using its globally unique id. */
  deleteLockoutGracePeriodByNodeId?: Maybe<DeleteLockoutGracePeriodPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganizationByEnvironmentId?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Organization` using its globally unique id. */
  deleteOrganizationByNodeId?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `ReupLineItem` using a unique key. */
  deleteReupLineItem?: Maybe<DeleteReupLineItemPayload>;
  /** Deletes a single `ReupLineItem` using its globally unique id. */
  deleteReupLineItemByNodeId?: Maybe<DeleteReupLineItemPayload>;
  /** Deletes a single `ReupProduct` using a unique key. */
  deleteReupProduct?: Maybe<DeleteReupProductPayload>;
  /** Deletes a single `ReupProduct` using its globally unique id. */
  deleteReupProductByNodeId?: Maybe<DeleteReupProductPayload>;
  /** Deletes a single `ReupPurchaseOrder` using a unique key. */
  deleteReupPurchaseOrder?: Maybe<DeleteReupPurchaseOrderPayload>;
  /** Deletes a single `ReupPurchaseOrder` using its globally unique id. */
  deleteReupPurchaseOrderByNodeId?: Maybe<DeleteReupPurchaseOrderPayload>;
  deleteRoom: Scalars['Boolean'];
  deleteRoomTable: Scalars['Boolean'];
  deleteRooms: Scalars['Boolean'];
  deleteShortenedLink: DeleteShortenedLinkResponse;
  deleteStrain: Scalars['Boolean'];
  deleteStrains: Scalars['Boolean'];
  /** Deletes a single `TaxRate` using a unique key. */
  deleteTaxRate?: Maybe<DeleteTaxRatePayload>;
  /** Deletes a single `TaxRate` using its globally unique id. */
  deleteTaxRateByNodeId?: Maybe<DeleteTaxRatePayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteUserByGlobalIdV2?: Maybe<DeleteUserByGlobalIdPayloadV2>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByGlobalUserId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserLinkedIdentity` using a unique key. */
  deleteUserLinkedIdentity?: Maybe<DeleteUserLinkedIdentityPayload>;
  /** Deletes a single `UserLinkedIdentity` using a unique key. */
  deleteUserLinkedIdentityByLinkedGlobalUserId?: Maybe<DeleteUserLinkedIdentityPayload>;
  /** Deletes a single `UserLinkedIdentity` using its globally unique id. */
  deleteUserLinkedIdentityByNodeId?: Maybe<DeleteUserLinkedIdentityPayload>;
  deleteVehicle: Scalars['Boolean'];
  generateLabelsPdf?: Maybe<FileModel>;
  inviteEmployee?: Maybe<EmployeeDto>;
  mergeUsers?: Maybe<MergeUsersPayload>;
  processCcrsManifest?: Maybe<CcrsManifestUploadDto>;
  processCcrsReportsAndUpload?: Maybe<Scalars['String']>;
  reconcileErrors?: Maybe<Scalars['String']>;
  resubmitCcrsCsvFiles?: Maybe<Array<Scalars['Boolean']>>;
  resubmitHangingUploads?: Maybe<Scalars['String']>;
  retailadmin_createEnvironment?: Maybe<MutationStatus>;
  retailadmin_deleteEnvironment?: Maybe<MutationStatus>;
  retailadmin_editEnvironment?: Maybe<MutationStatus>;
  runCommandLog: Scalars['Boolean'];
  saveReupDraftOrders?: Maybe<Array<Maybe<ReupPurchaseOrder>>>;
  sendAddNonCannabisCostComponent: Scalars['Uuid'];
  sendAddNonCannabisCostComponents?: Maybe<Array<Scalars['Uuid']>>;
  sendAddPlantsToHarvest: Scalars['Uuid'];
  sendAddPlantsToHarvests?: Maybe<Array<Scalars['Uuid']>>;
  sendAdjustPackage: Scalars['Uuid'];
  sendAdjustPackages?: Maybe<Array<Scalars['Uuid']>>;
  sendAllocateCostToPlantGroup: Scalars['Uuid'];
  sendAllocateCostToPlantGroups?: Maybe<Array<Scalars['Uuid']>>;
  sendCombinePackages: Scalars['Uuid'];
  sendCombinePackagesMany?: Maybe<Array<Scalars['Uuid']>>;
  sendConvertToRetailPackage: Scalars['Uuid'];
  sendCreateHarvest: Scalars['Uuid'];
  sendCreateHarvestPackage: Scalars['Uuid'];
  sendCreateHarvestPackages?: Maybe<Array<Scalars['Uuid']>>;
  sendCreateHarvests?: Maybe<Array<Scalars['Uuid']>>;
  sendCreateNonCannabisPackage: Scalars['Uuid'];
  sendCreateNonCannabisPackages?: Maybe<Array<Scalars['Uuid']>>;
  sendDestroyInventory: Scalars['Uuid'];
  sendDestroyPlants?: Maybe<Array<Scalars['Uuid']>>;
  sendImportPackageFromTraceability: Scalars['Uuid'];
  sendImportPackagesFromTraceability?: Maybe<Array<Scalars['Uuid']>>;
  sendManufactureNewPackage: Scalars['Uuid'];
  sendReceiveInboundPackage: Scalars['Uuid'];
  sendReceiveInboundPackages?: Maybe<Array<Scalars['Uuid']>>;
  sendSetSourcePackageCost: Scalars['Uuid'];
  sendSetSourcePackageCosts?: Maybe<Array<Scalars['Uuid']>>;
  sendSplitPackage: Scalars['Uuid'];
  sendSplitPackageForSale: Scalars['Uuid'];
  sendSplitPackages?: Maybe<Array<Scalars['Uuid']>>;
  sendSplitPackagesForSale?: Maybe<Array<Scalars['Uuid']>>;
  sendUndoDestroyInventory: Scalars['Uuid'];
  sendUndoDestroyPlants?: Maybe<Array<Scalars['Uuid']>>;
  sendUndoHarvestPackage: Scalars['Uuid'];
  sendUndoHarvestPackages?: Maybe<Array<Scalars['Uuid']>>;
  sendUndoManufacturedPackage: Scalars['Uuid'];
  sendUndoSublotPackageForSale: Scalars['Uuid'];
  setPaytenderAccount?: Maybe<SetPaytenderAccountPayload>;
  /** Create an association between user and buying license */
  storeFrontAddBuyingLicense?: Maybe<StoreFrontUserVendorDto>;
  /** Create a store front order */
  storeFrontCreatePurchaseOrder: StoreFrontCreateOrderResultDto;
  /** Remove an association between user and buying license */
  storeFrontRemoveBuyingLicense: Scalars['Boolean'];
  /** Create a store front access request */
  storeFrontRequestAccess?: Maybe<StoreFrontPermission>;
  storeFrontSignup?: Maybe<Scalars['Boolean']>;
  submitCcrsReports?: Maybe<Scalars['String']>;
  submitHarvests?: Maybe<HarvestDto>;
  triggerDataSync?: Maybe<GraphileWorkerJob>;
  updateEmployee?: Maybe<EmployeeDto>;
  /** Updates a single `FeatureUsageLicense` using a unique key and a patch. */
  updateFeatureUsageLicense?: Maybe<UpdateFeatureUsageLicensePayload>;
  /** Updates a single `FeatureUsageLicense` using a unique key and a patch. */
  updateFeatureUsageLicenseByFeatureusageAndLicense?: Maybe<UpdateFeatureUsageLicensePayload>;
  /** Updates a single `FeatureUsageLicense` using its globally unique id and a patch. */
  updateFeatureUsageLicenseByNodeId?: Maybe<UpdateFeatureUsageLicensePayload>;
  /** Updates a single `License` using a unique key and a patch. */
  updateLicense?: Maybe<UpdateLicensePayload>;
  /** Updates a single `License` using its globally unique id and a patch. */
  updateLicenseByNodeId?: Maybe<UpdateLicensePayload>;
  /** Updates a single `LockoutGracePeriod` using a unique key and a patch. */
  updateLockoutGracePeriod?: Maybe<UpdateLockoutGracePeriodPayload>;
  /** Updates a single `LockoutGracePeriod` using its globally unique id and a patch. */
  updateLockoutGracePeriodByNodeId?: Maybe<UpdateLockoutGracePeriodPayload>;
  /** updates an organization record */
  updateOrganization?: Maybe<OrganizationDto>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganizationByEnvironmentId?: Maybe<UpdateTenantOrganizationPayload>;
  /** Updates a single `Organization` using its globally unique id and a patch. */
  updateOrganizationByNodeId?: Maybe<UpdateTenantOrganizationPayload>;
  /** Updates a single `ReupLineItem` using a unique key and a patch. */
  updateReupLineItem?: Maybe<UpdateReupLineItemPayload>;
  /** Updates a single `ReupLineItem` using its globally unique id and a patch. */
  updateReupLineItemByNodeId?: Maybe<UpdateReupLineItemPayload>;
  /** Updates a single `ReupProduct` using a unique key and a patch. */
  updateReupProduct?: Maybe<UpdateReupProductPayload>;
  /** Updates a single `ReupProduct` using its globally unique id and a patch. */
  updateReupProductByNodeId?: Maybe<UpdateReupProductPayload>;
  /** Updates a single `ReupPurchaseOrder` using a unique key and a patch. */
  updateReupPurchaseOrder?: Maybe<UpdateReupPurchaseOrderPayload>;
  /** Updates a single `ReupPurchaseOrder` using its globally unique id and a patch. */
  updateReupPurchaseOrderByNodeId?: Maybe<UpdateReupPurchaseOrderPayload>;
  updateRoom?: Maybe<RoomDto>;
  updateRoomTable?: Maybe<RoomTableDto>;
  updateShortenedLink?: Maybe<CreateShortenedLinkResponse>;
  updateStoreFrontUserProfile: StoreFrontUserProfileData;
  updateStrain?: Maybe<StrainDto>;
  /** Updates a single `TaxRate` using a unique key and a patch. */
  updateTaxRate?: Maybe<UpdateTaxRatePayload>;
  /** Updates a single `TaxRate` using its globally unique id and a patch. */
  updateTaxRateByNodeId?: Maybe<UpdateTaxRatePayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateTenantOrganization?: Maybe<UpdateTenantOrganizationPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByGlobalUserId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserLinkedIdentity` using a unique key and a patch. */
  updateUserLinkedIdentity?: Maybe<UpdateUserLinkedIdentityPayload>;
  /** Updates a single `UserLinkedIdentity` using a unique key and a patch. */
  updateUserLinkedIdentityByLinkedGlobalUserId?: Maybe<UpdateUserLinkedIdentityPayload>;
  /** Updates a single `UserLinkedIdentity` using its globally unique id and a patch. */
  updateUserLinkedIdentityByNodeId?: Maybe<UpdateUserLinkedIdentityPayload>;
  updateVehicle?: Maybe<VehicleDto>;
  verifyUserEmail: VerifyUserEmailPayload;
  /** Creates an organization record */
  wholesale_CreateOrganization?: Maybe<OrganizationDto>;
};


export type MutationAddUserArgs = {
  model?: InputMaybe<AddUserInput>;
};


export type MutationArchiveOrganizationArgs = {
  input: ArchiveOrganizationInput;
};


export type MutationCreateAccountArgs = {
  model?: InputMaybe<CreateAccountInput>;
};


export type MutationCreateDejavooPaymentArgs = {
  input: CreateDejavooPaymentArgs;
};


export type MutationCreateEmployeeArgs = {
  employee: EmployeeCreateDtoInput;
};


export type MutationCreateFeatureUsageLicenseArgs = {
  input: CreateFeatureUsageLicenseInput;
};


export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


export type MutationCreateLockoutGracePeriodArgs = {
  input: CreateLockoutGracePeriodInput;
};


export type MutationCreateMockPaymentArgs = {
  input: CreateMockPaymentArgs;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreatePaytenderPaymentArgs = {
  input: CreatePaytenderPaymentArgs;
};


export type MutationCreatePublicBillingPortalLinkArgs = {
  input: CreatePublicBillingPortalLinkInput;
};


export type MutationCreateReupLineItemArgs = {
  input: CreateReupLineItemInput;
};


export type MutationCreateReupProductArgs = {
  input: CreateReupProductInput;
};


export type MutationCreateReupPurchaseOrderArgs = {
  input: CreateReupPurchaseOrderInput;
};


export type MutationCreateRoomArgs = {
  room: RoomCreateDtoInput;
};


export type MutationCreateRoomTableArgs = {
  roomTable: RoomTableCreateDtoInput;
};


export type MutationCreateShortenedLinkArgs = {
  input?: InputMaybe<CreateShortenedLinkInput>;
};


export type MutationCreateStrainArgs = {
  strain: StrainCreateDtoInput;
};


export type MutationCreateTaxRateArgs = {
  input: CreateTaxRateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserLinkedIdentityArgs = {
  input: CreateUserLinkedIdentityInput;
};


export type MutationCreateVehicleArgs = {
  vehicle: VehicleCreateDtoInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFeatureUsageLicenseArgs = {
  input: DeleteFeatureUsageLicenseInput;
};


export type MutationDeleteFeatureUsageLicenseByFeatureusageAndLicenseArgs = {
  input: DeleteFeatureUsageLicenseByFeatureusageAndLicenseInput;
};


export type MutationDeleteFeatureUsageLicenseByNodeIdArgs = {
  input: DeleteFeatureUsageLicenseByNodeIdInput;
};


export type MutationDeleteLicenseArgs = {
  input: DeleteLicenseInput;
};


export type MutationDeleteLicenseByNodeIdArgs = {
  input: DeleteLicenseByNodeIdInput;
};


export type MutationDeleteLockoutGracePeriodArgs = {
  input: DeleteLockoutGracePeriodInput;
};


export type MutationDeleteLockoutGracePeriodByNodeIdArgs = {
  input: DeleteLockoutGracePeriodByNodeIdInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationDeleteOrganizationByEnvironmentIdArgs = {
  input: DeleteOrganizationByEnvironmentIdInput;
};


export type MutationDeleteOrganizationByNodeIdArgs = {
  input: DeleteOrganizationByNodeIdInput;
};


export type MutationDeleteReupLineItemArgs = {
  input: DeleteReupLineItemInput;
};


export type MutationDeleteReupLineItemByNodeIdArgs = {
  input: DeleteReupLineItemByNodeIdInput;
};


export type MutationDeleteReupProductArgs = {
  input: DeleteReupProductInput;
};


export type MutationDeleteReupProductByNodeIdArgs = {
  input: DeleteReupProductByNodeIdInput;
};


export type MutationDeleteReupPurchaseOrderArgs = {
  input: DeleteReupPurchaseOrderInput;
};


export type MutationDeleteReupPurchaseOrderByNodeIdArgs = {
  input: DeleteReupPurchaseOrderByNodeIdInput;
};


export type MutationDeleteRoomArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRoomTableArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRoomsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteShortenedLinkArgs = {
  id: Scalars['String'];
};


export type MutationDeleteStrainArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStrainsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteTaxRateArgs = {
  input: DeleteTaxRateInput;
};


export type MutationDeleteTaxRateByNodeIdArgs = {
  input: DeleteTaxRateByNodeIdInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDeleteUserByGlobalIdV2Args = {
  input: DeleteUserByGlobalIdInputV2;
};


export type MutationDeleteUserByGlobalUserIdArgs = {
  input: DeleteUserByGlobalUserIdInput;
};


export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


export type MutationDeleteUserLinkedIdentityArgs = {
  input: DeleteUserLinkedIdentityInput;
};


export type MutationDeleteUserLinkedIdentityByLinkedGlobalUserIdArgs = {
  input: DeleteUserLinkedIdentityByLinkedGlobalUserIdInput;
};


export type MutationDeleteUserLinkedIdentityByNodeIdArgs = {
  input: DeleteUserLinkedIdentityByNodeIdInput;
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['Int'];
};


export type MutationGenerateLabelsPdfArgs = {
  labelPrintModel?: InputMaybe<LabelPdfPrintModelInput>;
};


export type MutationInviteEmployeeArgs = {
  employee: EmployeeInviteDtoInput;
};


export type MutationMergeUsersArgs = {
  input: MergeUsersInput;
};


export type MutationProcessCcrsManifestArgs = {
  manifestJobInput?: InputMaybe<ManifestJobInput>;
};


export type MutationProcessCcrsReportsAndUploadArgs = {
  applicationScope?: InputMaybe<CcrsApplicationScope>;
  organizationKey?: InputMaybe<Scalars['String']>;
  reportingCadence?: InputMaybe<CcrsReportingCadence>;
};


export type MutationReconcileErrorsArgs = {
  reconcileEvent?: InputMaybe<ReconcileErrorsEventInput>;
};


export type MutationResubmitCcrsCsvFilesArgs = {
  ccrsUploadIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationResubmitHangingUploadsArgs = {
  atLeastOld?: InputMaybe<Scalars['DateTime']>;
  waitTime?: InputMaybe<Scalars['Int']>;
};


export type MutationRetailadmin_CreateEnvironmentArgs = {
  environment?: InputMaybe<EnvironmentInput>;
};


export type MutationRetailadmin_DeleteEnvironmentArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};


export type MutationRetailadmin_EditEnvironmentArgs = {
  environment?: InputMaybe<EnvironmentInput>;
};


export type MutationRunCommandLogArgs = {
  commandLogJson?: InputMaybe<Scalars['String']>;
};


export type MutationSaveReupDraftOrdersArgs = {
  input: Array<InputMaybe<ReupOrderInput>>;
};


export type MutationSendAddNonCannabisCostComponentArgs = {
  cmd?: InputMaybe<AddNonCannabisCostComponentInput>;
};


export type MutationSendAddNonCannabisCostComponentsArgs = {
  cmds?: InputMaybe<Array<InputMaybe<AddNonCannabisCostComponentInput>>>;
};


export type MutationSendAddPlantsToHarvestArgs = {
  cmd?: InputMaybe<AddPlantsToHarvestInput>;
};


export type MutationSendAddPlantsToHarvestsArgs = {
  cmds?: InputMaybe<Array<InputMaybe<AddPlantsToHarvestInput>>>;
};


export type MutationSendAdjustPackageArgs = {
  cmd?: InputMaybe<AdjustPackageInput>;
};


export type MutationSendAdjustPackagesArgs = {
  cmds?: InputMaybe<Array<InputMaybe<AdjustPackageInput>>>;
};


export type MutationSendAllocateCostToPlantGroupArgs = {
  cmd?: InputMaybe<AllocateCostToPlantGroupInput>;
};


export type MutationSendAllocateCostToPlantGroupsArgs = {
  cmds?: InputMaybe<Array<InputMaybe<AllocateCostToPlantGroupInput>>>;
};


export type MutationSendCombinePackagesArgs = {
  cmd?: InputMaybe<CombinePackagesInput>;
};


export type MutationSendCombinePackagesManyArgs = {
  cmds?: InputMaybe<Array<InputMaybe<CombinePackagesInput>>>;
};


export type MutationSendConvertToRetailPackageArgs = {
  cmd?: InputMaybe<ConvertToRetailPackageInput>;
};


export type MutationSendCreateHarvestArgs = {
  cmd?: InputMaybe<CreateHarvestInput>;
};


export type MutationSendCreateHarvestPackageArgs = {
  cmd?: InputMaybe<CreateHarvestPackageInput>;
};


export type MutationSendCreateHarvestPackagesArgs = {
  cmds?: InputMaybe<Array<InputMaybe<CreateHarvestPackageInput>>>;
};


export type MutationSendCreateHarvestsArgs = {
  cmds?: InputMaybe<Array<InputMaybe<CreateHarvestInput>>>;
};


export type MutationSendCreateNonCannabisPackageArgs = {
  cmd?: InputMaybe<CreateNonCannabisPackageInput>;
};


export type MutationSendCreateNonCannabisPackagesArgs = {
  cmds?: InputMaybe<Array<InputMaybe<CreateNonCannabisPackageInput>>>;
};


export type MutationSendDestroyInventoryArgs = {
  cmd?: InputMaybe<DestroyInventoryInput>;
};


export type MutationSendDestroyPlantsArgs = {
  cmd?: InputMaybe<DestroyPlantsInput>;
};


export type MutationSendImportPackageFromTraceabilityArgs = {
  cmd?: InputMaybe<ImportPackageFromTraceabilityInput>;
};


export type MutationSendImportPackagesFromTraceabilityArgs = {
  cmds?: InputMaybe<Array<InputMaybe<ImportPackageFromTraceabilityInput>>>;
};


export type MutationSendManufactureNewPackageArgs = {
  cmd?: InputMaybe<ManufactureNewPackageInput>;
};


export type MutationSendReceiveInboundPackageArgs = {
  cmd?: InputMaybe<ReceiveInboundPackageInput>;
};


export type MutationSendReceiveInboundPackagesArgs = {
  cmds?: InputMaybe<Array<InputMaybe<ReceiveInboundPackageInput>>>;
};


export type MutationSendSetSourcePackageCostArgs = {
  cmd?: InputMaybe<SetSourcePackageCostInput>;
};


export type MutationSendSetSourcePackageCostsArgs = {
  cmds?: InputMaybe<Array<InputMaybe<SetSourcePackageCostInput>>>;
};


export type MutationSendSplitPackageArgs = {
  cmd?: InputMaybe<SplitPackageInput>;
};


export type MutationSendSplitPackageForSaleArgs = {
  cmd?: InputMaybe<SplitPackageForSaleInput>;
};


export type MutationSendSplitPackagesArgs = {
  cmds?: InputMaybe<Array<InputMaybe<SplitPackageInput>>>;
};


export type MutationSendSplitPackagesForSaleArgs = {
  cmds?: InputMaybe<Array<InputMaybe<SplitPackageForSaleInput>>>;
};


export type MutationSendUndoDestroyInventoryArgs = {
  cmd?: InputMaybe<UndoDestroyInventoryInput>;
};


export type MutationSendUndoDestroyPlantsArgs = {
  cmd?: InputMaybe<UndoDestroyPlantsInput>;
};


export type MutationSendUndoHarvestPackageArgs = {
  cmd?: InputMaybe<UndoHarvestPackageInput>;
};


export type MutationSendUndoHarvestPackagesArgs = {
  cmds?: InputMaybe<Array<InputMaybe<UndoHarvestPackageInput>>>;
};


export type MutationSendUndoManufacturedPackageArgs = {
  cmd?: InputMaybe<UndoManufacturedPackageInput>;
};


export type MutationSendUndoSublotPackageForSaleArgs = {
  cmd?: InputMaybe<UndoSplitPackageForSaleInput>;
};


export type MutationSetPaytenderAccountArgs = {
  input: SetPaytenderAccountInput;
};


export type MutationStoreFrontAddBuyingLicenseArgs = {
  state: Scalars['String'];
  vendorId: Scalars['Int'];
};


export type MutationStoreFrontCreatePurchaseOrderArgs = {
  data: StoreFrontCreateOrder;
  state: Scalars['String'];
};


export type MutationStoreFrontRemoveBuyingLicenseArgs = {
  state: Scalars['String'];
  vendorId: Scalars['Int'];
};


export type MutationStoreFrontRequestAccessArgs = {
  data: StoreFrontAccessRequest;
  state: Scalars['String'];
};


export type MutationStoreFrontSignupArgs = {
  details?: InputMaybe<StoreFrontSignupInput>;
};


export type MutationSubmitCcrsReportsArgs = {
  event?: InputMaybe<SubmitCcrsReportEventInput>;
};


export type MutationSubmitHarvestsArgs = {
  harvestDtos?: InputMaybe<Array<InputMaybe<HarvestDtoInput>>>;
};


export type MutationTriggerDataSyncArgs = {
  maxAttempts?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateEmployeeArgs = {
  employee: EmployeeUpdateDtoInput;
};


export type MutationUpdateFeatureUsageLicenseArgs = {
  input: UpdateFeatureUsageLicenseInput;
};


export type MutationUpdateFeatureUsageLicenseByFeatureusageAndLicenseArgs = {
  input: UpdateFeatureUsageLicenseByFeatureusageAndLicenseInput;
};


export type MutationUpdateFeatureUsageLicenseByNodeIdArgs = {
  input: UpdateFeatureUsageLicenseByNodeIdInput;
};


export type MutationUpdateLicenseArgs = {
  input: UpdateLicenseInput;
};


export type MutationUpdateLicenseByNodeIdArgs = {
  input: UpdateLicenseByNodeIdInput;
};


export type MutationUpdateLockoutGracePeriodArgs = {
  input: UpdateLockoutGracePeriodInput;
};


export type MutationUpdateLockoutGracePeriodByNodeIdArgs = {
  input: UpdateLockoutGracePeriodByNodeIdInput;
};


export type MutationUpdateOrganizationArgs = {
  model?: InputMaybe<UpdateOrganizationInput>;
};


export type MutationUpdateOrganizationByEnvironmentIdArgs = {
  input: UpdateOrganizationByEnvironmentIdInput;
};


export type MutationUpdateOrganizationByNodeIdArgs = {
  input: UpdateOrganizationByNodeIdInput;
};


export type MutationUpdateReupLineItemArgs = {
  input: UpdateReupLineItemInput;
};


export type MutationUpdateReupLineItemByNodeIdArgs = {
  input: UpdateReupLineItemByNodeIdInput;
};


export type MutationUpdateReupProductArgs = {
  input: UpdateReupProductInput;
};


export type MutationUpdateReupProductByNodeIdArgs = {
  input: UpdateReupProductByNodeIdInput;
};


export type MutationUpdateReupPurchaseOrderArgs = {
  input: UpdateReupPurchaseOrderInput;
};


export type MutationUpdateReupPurchaseOrderByNodeIdArgs = {
  input: UpdateReupPurchaseOrderByNodeIdInput;
};


export type MutationUpdateRoomArgs = {
  room: RoomUpdateDtoInput;
};


export type MutationUpdateRoomTableArgs = {
  roomTable: RoomTableDtoInput;
};


export type MutationUpdateShortenedLinkArgs = {
  address: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  expire_in?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  target: Scalars['String'];
};


export type MutationUpdateStoreFrontUserProfileArgs = {
  details?: InputMaybe<UpdateStoreFrontUserInput>;
};


export type MutationUpdateStrainArgs = {
  strain: StrainUpdateDtoInput;
};


export type MutationUpdateTaxRateArgs = {
  input: UpdateTaxRateInput;
};


export type MutationUpdateTaxRateByNodeIdArgs = {
  input: UpdateTaxRateByNodeIdInput;
};


export type MutationUpdateTenantOrganizationArgs = {
  input: UpdateTenantOrganizationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserByGlobalUserIdArgs = {
  input: UpdateUserByGlobalUserIdInput;
};


export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


export type MutationUpdateUserLinkedIdentityArgs = {
  input: UpdateUserLinkedIdentityInput;
};


export type MutationUpdateUserLinkedIdentityByLinkedGlobalUserIdArgs = {
  input: UpdateUserLinkedIdentityByLinkedGlobalUserIdInput;
};


export type MutationUpdateUserLinkedIdentityByNodeIdArgs = {
  input: UpdateUserLinkedIdentityByNodeIdInput;
};


export type MutationUpdateVehicleArgs = {
  vehicle: VehicleUpdateDtoInput;
};


export type MutationVerifyUserEmailArgs = {
  auth0UserId: Scalars['String'];
};


export type MutationWholesale_CreateOrganizationArgs = {
  model?: InputMaybe<CreateWholesaleOrganizationInput>;
};

export type MutationStatus = {
  __typename?: 'MutationStatus';
  Error?: Maybe<Scalars['String']>;
  Ok?: Maybe<Scalars['Boolean']>;
  Response?: Maybe<Scalars['String']>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type NonCannabisSourceInput = {
  inventoryConversionSourceId: Scalars['Int'];
  quantity: Scalars['Decimal'];
  sourcePackage?: InputMaybe<PackageReferenceInput>;
  unitCost: Scalars['Decimal'];
};

export type Note = Node & {
  __typename?: 'Note';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Note`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars['String'];
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Note` object types. All fields are combined with a logical ‘and.’ */
export type NoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NoteFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NoteFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NoteFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Note` */
export type NoteInput = {
  note?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<NotesOrganizationId2FkeyInput>;
  userId?: InputMaybe<Scalars['Int']>;
  userToUserId?: InputMaybe<NotesUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type NoteNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `note` to be connected. */
  nodeId: Scalars['ID'];
};

/** The fields on `note` to look up the row to connect. */
export type NoteNotesPkeyConnect = {
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type NoteOnNoteForNotesOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: OrganizationPatch;
};

/** The fields on `note` to look up the row to update. */
export type NoteOnNoteForNotesOrganizationId2FkeyUsingNotesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `note` being updated. */
  patch: UpdateNoteOnNoteForNotesOrganizationId2FkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type NoteOnNoteForNotesUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `note` to look up the row to update. */
export type NoteOnNoteForNotesUserIdFkeyUsingNotesPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `note` being updated. */
  patch: UpdateNoteOnNoteForNotesUserIdFkeyPatch;
};

/** Represents an update to a `Note`. Fields that are set will be updated. */
export type NotePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  note?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<NotesOrganizationId2FkeyInput>;
  userId?: InputMaybe<Scalars['Int']>;
  userToUserId?: InputMaybe<NotesUserIdFkeyInput>;
};

/** A connection to a list of `Note` values. */
export type NotesConnection = {
  __typename?: 'NotesConnection';
  /** A list of edges which contains the `Note` and cursor to aid in pagination. */
  edges: Array<NotesEdge>;
  /** A list of `Note` objects. */
  nodes: Array<Maybe<Note>>;
  /** The count of *all* `Note` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Note` edge in the connection. */
export type NotesEdge = {
  __typename?: 'NotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Note` at the end of the edge. */
  node?: Maybe<Note>;
};

/** Methods to use when ordering `Note`. */
export enum NotesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Input for the nested mutation of `organization` in the `NoteInput` mutation. */
export type NotesOrganizationId2FkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrganizationOrganizationsPkeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrganizationNodeIdConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<NotesOrganizationId2FkeyOrganizationsCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByKey?: InputMaybe<OrganizationOrganizationsPkeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByEnvironmentId?: InputMaybe<OrganizationOnNoteForNotesOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrganizationOnNoteForNotesOrganizationId2FkeyUsingOrganizationsPkeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<NoteOnNoteForNotesOrganizationId2FkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `note` in the `OrganizationInput` mutation. */
export type NotesOrganizationId2FkeyInverseInput = {
  /** The primary key(s) for `note` for the far side of the relationship. */
  connectById?: InputMaybe<Array<NoteNotesPkeyConnect>>;
  /** The primary key(s) for `note` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<NoteNodeIdConnect>>;
  /** A `NoteInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<NotesOrganizationId2FkeyNotesCreateInput>>;
  /** The primary key(s) and patch data for `note` for the far side of the relationship. */
  updateById?: InputMaybe<Array<NoteOnNoteForNotesOrganizationId2FkeyUsingNotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `note` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<OrganizationOnNoteForNotesOrganizationId2FkeyNodeIdUpdate>>;
};

/** The `note` to be created by this mutation. */
export type NotesOrganizationId2FkeyNotesCreateInput = {
  note?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<NotesOrganizationId2FkeyInput>;
  userId?: InputMaybe<Scalars['Int']>;
  userToUserId?: InputMaybe<NotesUserIdFkeyInput>;
};

/** The `organization` to be created by this mutation. */
export type NotesOrganizationId2FkeyOrganizationsCreateInput = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  billing: OrganizationBillingInfoInput;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name: Scalars['String'];
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  referralId?: InputMaybe<Scalars['String']>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

/** Input for the nested mutation of `user` in the `NoteInput` mutation. */
export type NotesUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByGlobalUserId?: InputMaybe<UserUsersGlobalUserIdKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUsersPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByGlobalUserId?: InputMaybe<UserUsersGlobalUserIdKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserUsersPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByGlobalUserId?: InputMaybe<UserOnNoteForNotesUserIdFkeyUsingUsersGlobalUserIdKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnNoteForNotesUserIdFkeyUsingUsersPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<NoteOnNoteForNotesUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `note` in the `UserInput` mutation. */
export type NotesUserIdFkeyInverseInput = {
  /** The primary key(s) for `note` for the far side of the relationship. */
  connectById?: InputMaybe<Array<NoteNotesPkeyConnect>>;
  /** The primary key(s) for `note` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<NoteNodeIdConnect>>;
  /** The primary key(s) and patch data for `note` for the far side of the relationship. */
  updateById?: InputMaybe<Array<NoteOnNoteForNotesUserIdFkeyUsingNotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `note` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnNoteForNotesUserIdFkeyNodeIdUpdate>>;
};

export type NullableOfCcrsCsvUploadErrorTypeOperationFilterInput = {
  eq?: InputMaybe<CcrsCsvUploadErrorType>;
  in?: InputMaybe<Array<InputMaybe<CcrsCsvUploadErrorType>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<CcrsCsvUploadErrorType>;
  nin?: InputMaybe<Array<InputMaybe<CcrsCsvUploadErrorType>>>;
};

export type NullableOfMjfDisposalReasonTypeOperationFilterInput = {
  eq?: InputMaybe<MjfDisposalReasonType>;
  in?: InputMaybe<Array<InputMaybe<MjfDisposalReasonType>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<MjfDisposalReasonType>;
  nin?: InputMaybe<Array<InputMaybe<MjfDisposalReasonType>>>;
};

export type NullableOfMjfDisposalSourceTypeOperationFilterInput = {
  eq?: InputMaybe<MjfDisposalSourceType>;
  in?: InputMaybe<Array<InputMaybe<MjfDisposalSourceType>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<MjfDisposalSourceType>;
  nin?: InputMaybe<Array<InputMaybe<MjfDisposalSourceType>>>;
};

export type NullableOfPlantStageOperationFilterInput = {
  eq?: InputMaybe<PlantStage>;
  in?: InputMaybe<Array<InputMaybe<PlantStage>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<PlantStage>;
  nin?: InputMaybe<Array<InputMaybe<PlantStage>>>;
};

export type NullableOfUnitOfMeasureOperationFilterInput = {
  eq?: InputMaybe<UnitOfMeasure>;
  in?: InputMaybe<Array<InputMaybe<UnitOfMeasure>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<UnitOfMeasure>;
  nin?: InputMaybe<Array<InputMaybe<UnitOfMeasure>>>;
};

export type Organization = Node & {
  __typename?: 'Organization';
  _initialMigrationNeedsManualDataResolution?: Maybe<Scalars['Boolean']>;
  archivedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: Maybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: Maybe<Scalars['String']>;
  hasRetailLicense?: Maybe<Scalars['Boolean']>;
  hasWholesaleLicense?: Maybe<Scalars['Boolean']>;
  historicalStripeCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isArchived: Scalars['Boolean'];
  key: Scalars['String'];
  /** Reads and enables pagination through a set of `License`. */
  licenses: LicensesConnection;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Note`. */
  notes: NotesConnection;
  /** Reads and enables pagination through a set of `ProductUsageStat`. */
  productUsageStats: ProductUsageStatsConnection;
  referralId?: Maybe<Scalars['String']>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `StripeSubscription`. */
  stripeSubscriptions: StripeSubscriptionsConnection;
};


export type OrganizationLicensesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LicenseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LicensesOrderBy>>;
};


export type OrganizationNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


export type OrganizationProductUsageStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProductUsageStatFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductUsageStatsOrderBy>>;
};


export type OrganizationStripeSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<StripeSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StripeSubscriptionsOrderBy>>;
};

export type OrganizationBillingInfoInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type OrganizationDto = {
  __typename?: 'OrganizationDto';
  accountsCount: Scalars['Int'];
  createTimestamp?: Maybe<Scalars['DateTime']>;
  firstPaidDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  isDeleted: Scalars['Boolean'];
  isDelinquent: Scalars['Boolean'];
  isGrowFlow: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isLost: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isPaidWithCheck: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lostDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
  organizationStatus: OrganizationStatus;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePlanKey?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  trialEndDate?: Maybe<Scalars['DateTime']>;
  trialStartDate?: Maybe<Scalars['DateTime']>;
  verifiedDate?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type OrganizationDtoConnection = {
  __typename?: 'OrganizationDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrganizationDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrganizationDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganizationDtoEdge = {
  __typename?: 'OrganizationDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrganizationDto;
};

/** A filter to be used against `Organization` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationFilter = {
  /** Filter by the object’s `_initialMigrationNeedsManualDataResolution` field. */
  _initialMigrationNeedsManualDataResolution?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalCustomerId` field. */
  externalCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasRetailLicense` field. */
  hasRetailLicense?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasWholesaleLicense` field. */
  hasWholesaleLicense?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `historicalStripeCustomerIds` field. */
  historicalStripeCustomerIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `referralId` field. */
  referralId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeCustomerId` field. */
  stripeCustomerId?: InputMaybe<StringFilter>;
};

export type OrganizationFilterInput = {
  and?: InputMaybe<Array<OrganizationFilterInput>>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  firstPaidDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isDelinquent?: InputMaybe<BooleanOperationFilterInput>;
  isGrowFlow?: InputMaybe<BooleanOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  isLost?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<BooleanOperationFilterInput>;
  isPaidWithCheck?: InputMaybe<BooleanOperationFilterInput>;
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  lastLoginDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lostDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationFilterInput>>;
  organizationKey?: InputMaybe<StringOperationFilterInput>;
  organizationStatus?: InputMaybe<OrganizationStatusOperationFilterInput>;
  primaryEmail?: InputMaybe<StringOperationFilterInput>;
  primaryPhone?: InputMaybe<StringOperationFilterInput>;
  stripeCustomerId?: InputMaybe<StringOperationFilterInput>;
  stripePlanKey?: InputMaybe<StringOperationFilterInput>;
  stripeSubscriptionId?: InputMaybe<StringOperationFilterInput>;
  trialEndDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  trialStartDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  verifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  websiteUrl?: InputMaybe<StringOperationFilterInput>;
};

/** An input for mutations affecting `Organization` */
export type OrganizationInput = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  billing: OrganizationBillingInfoInput;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name: Scalars['String'];
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  referralId?: InputMaybe<Scalars['String']>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type OrganizationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `organization` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnLicenseForLicensesOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `license` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: LicensePatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnLicenseForLicensesOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate = {
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnLicenseForLicensesOrganizationId2FkeyPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnLicenseForLicensesOrganizationId2FkeyUsingOrganizationsPkeyUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnLicenseForLicensesOrganizationId2FkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnNoteForNotesOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `note` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `note` being updated. */
  patch: NotePatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnNoteForNotesOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate = {
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnNoteForNotesOrganizationId2FkeyPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnNoteForNotesOrganizationId2FkeyUsingOrganizationsPkeyUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnNoteForNotesOrganizationId2FkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productUsageStat` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productUsageStat` being updated. */
  patch: ProductUsageStatPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate = {
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingOrganizationsPkeyUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `stripeSubscription` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: StripeSubscriptionPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate = {
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingOrganizationsPkeyUpdate = {
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: UpdateOrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch;
};

/** The fields on `organization` to look up the row to connect. */
export type OrganizationOrganizationsEnvironmentIdKeyConnect = {
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
};

/** The fields on `organization` to look up the row to delete. */
export type OrganizationOrganizationsEnvironmentIdKeyDelete = {
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
};

/** The fields on `organization` to look up the row to connect. */
export type OrganizationOrganizationsPkeyConnect = {
  key: Scalars['String'];
};

/** The fields on `organization` to look up the row to delete. */
export type OrganizationOrganizationsPkeyDelete = {
  key: Scalars['String'];
};

/** Represents an update to a `Organization`. Fields that are set will be updated. */
export type OrganizationPatch = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

export type OrganizationSortInput = {
  createTimestamp?: InputMaybe<SortEnumType>;
  firstPaidDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isDelinquent?: InputMaybe<SortEnumType>;
  isGrowFlow?: InputMaybe<SortEnumType>;
  isInactive?: InputMaybe<SortEnumType>;
  isLost?: InputMaybe<SortEnumType>;
  isPaid?: InputMaybe<SortEnumType>;
  isPaidWithCheck?: InputMaybe<SortEnumType>;
  isVerified?: InputMaybe<SortEnumType>;
  lastLoginDate?: InputMaybe<SortEnumType>;
  lostDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  organizationKey?: InputMaybe<SortEnumType>;
  organizationStatus?: InputMaybe<SortEnumType>;
  primaryEmail?: InputMaybe<SortEnumType>;
  primaryPhone?: InputMaybe<SortEnumType>;
  stripeCustomerId?: InputMaybe<SortEnumType>;
  stripePlanKey?: InputMaybe<SortEnumType>;
  stripeSubscriptionId?: InputMaybe<SortEnumType>;
  trialEndDate?: InputMaybe<SortEnumType>;
  trialStartDate?: InputMaybe<SortEnumType>;
  verifiedDate?: InputMaybe<SortEnumType>;
  websiteUrl?: InputMaybe<SortEnumType>;
};

export enum OrganizationStatus {
  Lost = 'LOST',
  Paid = 'PAID',
  PaidThenLost = 'PAID_THEN_LOST',
  Trial = 'TRIAL',
  TrialExpired = 'TRIAL_EXPIRED'
}

export type OrganizationStatusOperationFilterInput = {
  eq?: InputMaybe<OrganizationStatus>;
  in?: InputMaybe<Array<OrganizationStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<OrganizationStatus>;
  nin?: InputMaybe<Array<OrganizationStatus>>;
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  /** A list of edges which contains the `Organization` and cursor to aid in pagination. */
  edges: Array<OrganizationsEdge>;
  /** A list of `Organization` objects. */
  nodes: Array<Maybe<Organization>>;
  /** The count of *all* `Organization` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Organization` edge in the connection. */
export type OrganizationsEdge = {
  __typename?: 'OrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Organization` at the end of the edge. */
  node?: Maybe<Organization>;
};

/** Methods to use when ordering `Organization`. */
export enum OrganizationsOrderBy {
  ArchivedAtAsc = 'ARCHIVED_AT_ASC',
  ArchivedAtDesc = 'ARCHIVED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ExternalCustomerIdAsc = 'EXTERNAL_CUSTOMER_ID_ASC',
  ExternalCustomerIdDesc = 'EXTERNAL_CUSTOMER_ID_DESC',
  HistoricalStripeCustomerIdsAsc = 'HISTORICAL_STRIPE_CUSTOMER_IDS_ASC',
  HistoricalStripeCustomerIdsDesc = 'HISTORICAL_STRIPE_CUSTOMER_IDS_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferralIdAsc = 'REFERRAL_ID_ASC',
  ReferralIdDesc = 'REFERRAL_ID_DESC',
  StripeCustomerIdAsc = 'STRIPE_CUSTOMER_ID_ASC',
  StripeCustomerIdDesc = 'STRIPE_CUSTOMER_ID_DESC',
  InitialMigrationNeedsManualDataResolutionAsc = '_INITIAL_MIGRATION_NEEDS_MANUAL_DATA_RESOLUTION_ASC',
  InitialMigrationNeedsManualDataResolutionDesc = '_INITIAL_MIGRATION_NEEDS_MANUAL_DATA_RESOLUTION_DESC'
}

export type PackageConversionInput = {
  inputPackage?: InputMaybe<PackageReferenceInput>;
  inventoryConversionSourceId: Scalars['Int'];
  quantityUsed: Scalars['Decimal'];
};

export type PackageReferenceInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  inventoryId: Scalars['Uuid'];
  wholesaleInventoryId: Scalars['Int'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PageOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

export enum PageSizes {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A4Landscape = 'A4_LANDSCAPE',
  A5 = 'A5',
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Legal = 'LEGAL',
  LegalLandscape = 'LEGAL_LANDSCAPE',
  Letter = 'LETTER',
  LetterLandscape = 'LETTER_LANDSCAPE',
  SmallPaperback = 'SMALL_PAPERBACK'
}

export type PageSizesOperationFilterInput = {
  eq?: InputMaybe<PageSizes>;
  in?: InputMaybe<Array<PageSizes>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<PageSizes>;
  nin?: InputMaybe<Array<PageSizes>>;
};

export type Pagination = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type PaytenderAccount = Node & {
  __typename?: 'PaytenderAccount';
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDemo: Scalars['Boolean'];
  licenseNumber: Scalars['String'];
  merchantId: Scalars['String'];
  merchantSalt: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationKey: Scalars['String'];
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
};


export type PaytenderAccountTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/**
 * A condition to be used against `PaytenderAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PaytenderAccountCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isDemo` field. */
  isDemo?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `licenseNumber` field. */
  licenseNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `merchantId` field. */
  merchantId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `merchantSalt` field. */
  merchantSalt?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationKey` field. */
  organizationKey?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `PaytenderAccount` values. */
export type PaytenderAccountsConnection = {
  __typename?: 'PaytenderAccountsConnection';
  /** A list of edges which contains the `PaytenderAccount` and cursor to aid in pagination. */
  edges: Array<PaytenderAccountsEdge>;
  /** A list of `PaytenderAccount` objects. */
  nodes: Array<PaytenderAccount>;
  /** The count of *all* `PaytenderAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PaytenderAccount` edge in the connection. */
export type PaytenderAccountsEdge = {
  __typename?: 'PaytenderAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PaytenderAccount` at the end of the edge. */
  node: PaytenderAccount;
};

/** Methods to use when ordering `PaytenderAccount`. */
export enum PaytenderAccountsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDemoAsc = 'IS_DEMO_ASC',
  IsDemoDesc = 'IS_DEMO_DESC',
  LicenseNumberAsc = 'LICENSE_NUMBER_ASC',
  LicenseNumberDesc = 'LICENSE_NUMBER_DESC',
  MerchantIdAsc = 'MERCHANT_ID_ASC',
  MerchantIdDesc = 'MERCHANT_ID_DESC',
  MerchantSaltAsc = 'MERCHANT_SALT_ASC',
  MerchantSaltDesc = 'MERCHANT_SALT_DESC',
  Natural = 'NATURAL',
  OrganizationKeyAsc = 'ORGANIZATION_KEY_ASC',
  OrganizationKeyDesc = 'ORGANIZATION_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type PaytenderCustomer = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type PaytenderMerchant = {
  licenseNumber: Scalars['String'];
  organizationKey: Scalars['String'];
};

export type PaytenderOrder = {
  itemSubtotal?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Array<PaytenderOrderItem>>;
  referenceNumber: Scalars['String'];
  salesTax: Scalars['Int'];
};

export type PaytenderOrderItem = {
  amount: Scalars['Int'];
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PaytenderUser = {
  __typename?: 'PaytenderUser';
  phoneNumber: Scalars['String'];
};

export enum PermissionScopeType {
  BillingAccount = 'billingAccount',
  License = 'license',
  LicenseGroup = 'licenseGroup',
  User = 'user',
  UserGroup = 'userGroup'
}

export enum PermissionVerb {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update'
}

export type PerpetualInventoryDetail = {
  __typename?: 'PerpetualInventoryDetail';
  category?: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  endingBalance: Scalars['Decimal'];
  itemId?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  netAdjustments: Scalars['Decimal'];
  netConversions: Scalars['Decimal'];
  netImported: Scalars['Decimal'];
  netPurchases: Scalars['Decimal'];
  netSales: Scalars['Decimal'];
  netWaste: Scalars['Decimal'];
};

export type PerpetualInventorySummary = {
  __typename?: 'PerpetualInventorySummary';
  category?: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  endingBalance: Scalars['Decimal'];
  measure?: Maybe<Scalars['String']>;
  netAdjustments: Scalars['Decimal'];
  netConversions: Scalars['Decimal'];
  netImported: Scalars['Decimal'];
  netPurchases: Scalars['Decimal'];
  netSales: Scalars['Decimal'];
  netWaste: Scalars['Decimal'];
};

export enum PlanType {
  Additional = 'ADDITIONAL',
  Addon = 'ADDON',
  Base = 'BASE'
}

export type PlantCostAllocationEvent = {
  __typename?: 'PlantCostAllocationEvent';
  costAllocatedToPlantFromGroup: CostAllocatedToPlantFromGroup;
  isDestroyed: Scalars['Boolean'];
};

export type PlantCostAllocationLog = {
  __typename?: 'PlantCostAllocationLog';
  costAllocationId: Scalars['String'];
  data: CostAllocatedToPlantFromGroup;
  dataId: Scalars['String'];
  description: Scalars['String'];
  eventId: Scalars['String'];
  eventTimestamp: Scalars['DateTime'];
  eventType: Scalars['String'];
  isDestroyed: Scalars['Boolean'];
  licenses: Array<CannabisLicense>;
  plantComplianceId: Scalars['String'];
  tenantId: Scalars['String'];
};

export type PlantDto = {
  __typename?: 'PlantDto';
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  flowerWeight: Scalars['Decimal'];
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  lastPrinted?: Maybe<Scalars['DateTime']>;
  stage: PlantStage;
  strain?: Maybe<StrainDto>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  wasteWeight: Scalars['Decimal'];
};

/** A connection to a list of items. */
export type PlantDtoConnection = {
  __typename?: 'PlantDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PlantDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PlantDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PlantDtoEdge = {
  __typename?: 'PlantDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PlantDto;
};

export type PlantFilterInput = {
  and?: InputMaybe<Array<PlantFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lastPrinted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlantFilterInput>>;
  stage?: InputMaybe<NullableOfPlantStageOperationFilterInput>;
  strain?: InputMaybe<StrainFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  wasteWeight?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type PlantGroupReferenceInput = {
  plantGroupName?: InputMaybe<Scalars['String']>;
  plants?: InputMaybe<Array<InputMaybe<PlantReferenceInput>>>;
  type: PlantGroupType;
  wholesaleGroupReferenceId: Scalars['Int'];
};

export enum PlantGroupType {
  Block = 'BLOCK',
  Custom = 'CUSTOM',
  Harvest = 'HARVEST',
  Room = 'ROOM'
}

export type PlantReference = {
  __typename?: 'PlantReference';
  complianceId?: Maybe<Scalars['String']>;
  license?: Maybe<CannabisLicense>;
  plantId: Scalars['Uuid'];
  wholesalePlantId: Scalars['Int'];
};

export type PlantReferenceInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<CannabisLicenseInput>;
  wholesalePlantId: Scalars['Int'];
};

export type PlantSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastPrinted?: InputMaybe<SortEnumType>;
  stage?: InputMaybe<SortEnumType>;
  strain?: InputMaybe<StrainSortInput>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  wasteWeight?: InputMaybe<SortEnumType>;
};

export enum PlantStage {
  Clone = 'CLONE',
  Converted = 'CONVERTED',
  Cured = 'CURED',
  Destroyed = 'DESTROYED',
  Flowering = 'FLOWERING',
  Harvested = 'HARVESTED',
  Inactive = 'INACTIVE',
  Packaged = 'PACKAGED',
  ReadyToHarvest = 'READY_TO_HARVEST',
  ScheduledForDisposal = 'SCHEDULED_FOR_DISPOSAL',
  Seedling = 'SEEDLING',
  Transferred = 'TRANSFERRED',
  Unknown = 'UNKNOWN',
  Vegging = 'VEGGING'
}

export type PlantView = {
  __typename?: 'PlantView';
  changeLogs?: Maybe<Array<Maybe<PlantViewChangeLog>>>;
  complianceId?: Maybe<Scalars['String']>;
  costComponents?: Maybe<Array<Maybe<CostAllocation>>>;
  harvests?: Maybe<Array<Maybe<Harvest>>>;
  id: Scalars['Uuid'];
  isDestroyed: Scalars['Boolean'];
  phase: GrowthPhase;
  plantGroupIds?: Maybe<Array<Scalars['Uuid']>>;
  totalCost: Scalars['Decimal'];
  totalHarvestedWeight: Scalars['Decimal'];
  wholesalePlantId: Scalars['Int'];
};

export type PlantViewChangeLog = {
  __typename?: 'PlantViewChangeLog';
  allocatedBy?: Maybe<Scalars['String']>;
  allocatedCost: Scalars['Decimal'];
  event?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  phase: GrowthPhase;
  timestamp: Scalars['DateTime'];
  totalCost: Scalars['Decimal'];
};

export type PlantViewChangeLogInput = {
  allocatedBy?: InputMaybe<Scalars['String']>;
  allocatedCost: Scalars['Decimal'];
  event?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  phase: GrowthPhase;
  timestamp: Scalars['DateTime'];
  totalCost: Scalars['Decimal'];
};

export type PlantViewInput = {
  changeLogs?: InputMaybe<Array<InputMaybe<PlantViewChangeLogInput>>>;
  complianceId?: InputMaybe<Scalars['String']>;
  costComponents?: InputMaybe<Array<InputMaybe<CostAllocationInput>>>;
  harvests?: InputMaybe<Array<InputMaybe<HarvestInput>>>;
  id: Scalars['Uuid'];
  isDestroyed: Scalars['Boolean'];
  phase: GrowthPhase;
  plantGroupIds?: InputMaybe<Array<Scalars['Uuid']>>;
  wholesalePlantId: Scalars['Int'];
};

export type Price = Node & {
  __typename?: 'Price';
  active: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Feature` that is related to this `Price`. */
  feature?: Maybe<Feature>;
  featureId: Scalars['String'];
  /** Reads and enables pagination through a set of `FeatureUsage`. */
  featureUsages: FeatureUsagesConnection;
  id: Scalars['String'];
  livemode: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  recurringInterval?: Maybe<Scalars['String']>;
  recurringIntervalAmount?: Maybe<Scalars['Int']>;
  unitAmount: Scalars['Int'];
};


export type PriceFeatureUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureUsageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeatureUsagesOrderBy>>;
};

/** A filter to be used against `Price` object types. All fields are combined with a logical ‘and.’ */
export type PriceFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PriceFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `featureId` field. */
  featureId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `livemode` field. */
  livemode?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PriceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PriceFilter>>;
  /** Filter by the object’s `recurringInterval` field. */
  recurringInterval?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recurringIntervalAmount` field. */
  recurringIntervalAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `unitAmount` field. */
  unitAmount?: InputMaybe<IntFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PriceNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `price` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type PriceOnFeatureUsageForFeatureUsagesPriceIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `featureUsage` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: FeatureUsagePatch;
};

/** The fields on `price` to look up the row to update. */
export type PriceOnFeatureUsageForFeatureUsagesPriceIdFkeyUsingPricesPkeyUpdate = {
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `price` being updated. */
  patch: UpdatePriceOnFeatureUsageForFeatureUsagesPriceIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type PriceOnPriceForPricesFeatureIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `feature` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `feature` being updated. */
  patch: FeaturePatch;
};

/** The fields on `price` to look up the row to update. */
export type PriceOnPriceForPricesFeatureIdFkeyUsingPricesPkeyUpdate = {
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `price` being updated. */
  patch: UpdatePriceOnPriceForPricesFeatureIdFkeyPatch;
};

/** Represents an update to a `Price`. Fields that are set will be updated. */
export type PricePatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureId?: InputMaybe<Scalars['String']>;
  featureToFeatureId?: InputMaybe<PricesFeatureIdFkeyInput>;
  featureUsagesUsingId?: InputMaybe<FeatureUsagesPriceIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['String']>;
  livemode?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  recurringIntervalAmount?: InputMaybe<Scalars['Int']>;
  unitAmount?: InputMaybe<Scalars['Int']>;
};

/** The fields on `price` to look up the row to connect. */
export type PricePricesPkeyConnect = {
  id: Scalars['String'];
};

/** A connection to a list of `Price` values. */
export type PricesConnection = {
  __typename?: 'PricesConnection';
  /** A list of edges which contains the `Price` and cursor to aid in pagination. */
  edges: Array<PricesEdge>;
  /** A list of `Price` objects. */
  nodes: Array<Maybe<Price>>;
  /** The count of *all* `Price` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Price` edge in the connection. */
export type PricesEdge = {
  __typename?: 'PricesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Price` at the end of the edge. */
  node?: Maybe<Price>;
};

/** Input for the nested mutation of `feature` in the `PriceInput` mutation. */
export type PricesFeatureIdFkeyInput = {
  /** The primary key(s) for `feature` for the far side of the relationship. */
  connectById?: InputMaybe<FeatureFeaturesPkeyConnect>;
  /** The primary key(s) for `feature` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<FeatureNodeIdConnect>;
  /** The primary key(s) and patch data for `feature` for the far side of the relationship. */
  updateById?: InputMaybe<FeatureOnPriceForPricesFeatureIdFkeyUsingFeaturesPkeyUpdate>;
  /** The primary key(s) and patch data for `feature` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<PriceOnPriceForPricesFeatureIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `price` in the `FeatureInput` mutation. */
export type PricesFeatureIdFkeyInverseInput = {
  /** The primary key(s) for `price` for the far side of the relationship. */
  connectById?: InputMaybe<Array<PricePricesPkeyConnect>>;
  /** The primary key(s) for `price` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<PriceNodeIdConnect>>;
  /** The primary key(s) and patch data for `price` for the far side of the relationship. */
  updateById?: InputMaybe<Array<PriceOnPriceForPricesFeatureIdFkeyUsingPricesPkeyUpdate>>;
  /** The primary key(s) and patch data for `price` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<FeatureOnPriceForPricesFeatureIdFkeyNodeIdUpdate>>;
};

/** Methods to use when ordering `Price`. */
export enum PricesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FeatureIdAsc = 'FEATURE_ID_ASC',
  FeatureIdDesc = 'FEATURE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LivemodeAsc = 'LIVEMODE_ASC',
  LivemodeDesc = 'LIVEMODE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecurringIntervalAmountAsc = 'RECURRING_INTERVAL_AMOUNT_ASC',
  RecurringIntervalAmountDesc = 'RECURRING_INTERVAL_AMOUNT_DESC',
  RecurringIntervalAsc = 'RECURRING_INTERVAL_ASC',
  RecurringIntervalDesc = 'RECURRING_INTERVAL_DESC',
  UnitAmountAsc = 'UNIT_AMOUNT_ASC',
  UnitAmountDesc = 'UNIT_AMOUNT_DESC'
}

export type PrintNodeComputer = {
  __typename?: 'PrintNodeComputer';
  createTimeStamp: Scalars['DateTime'];
  hostName?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  inet?: Maybe<Scalars['String']>;
  inet6?: Maybe<Scalars['String']>;
  jre?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PrintNodeComputerFilterInput = {
  and?: InputMaybe<Array<PrintNodeComputerFilterInput>>;
  createTimeStamp?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  hostName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt64OperationFilterInput>;
  inet?: InputMaybe<StringOperationFilterInput>;
  inet6?: InputMaybe<StringOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  jre?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PrintNodeComputerFilterInput>>;
  state?: InputMaybe<StringOperationFilterInput>;
};

export type PrintNodePrinter = {
  __typename?: 'PrintNodePrinter';
  capabilities?: Maybe<PrintNodePrinterCapabilities>;
  computer?: Maybe<PrintNodeComputer>;
  createTimeStamp: Scalars['DateTime'];
  default: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PrintNodePrinterCapabilities = {
  __typename?: 'PrintNodePrinterCapabilities';
  bins?: Maybe<Array<Maybe<Scalars['String']>>>;
  collate: Scalars['Boolean'];
  color: Scalars['Boolean'];
  copies: Scalars['Int'];
  dpis?: Maybe<Array<Maybe<Scalars['String']>>>;
  medias?: Maybe<Array<Maybe<Scalars['String']>>>;
  nup?: Maybe<Array<Scalars['Int']>>;
  supportsCustomPaperSize: Scalars['Boolean'];
};

export type PrintNodePrinterCapabilitiesFilterInput = {
  and?: InputMaybe<Array<PrintNodePrinterCapabilitiesFilterInput>>;
  bins?: InputMaybe<ListStringOperationFilterInput>;
  collate?: InputMaybe<BooleanOperationFilterInput>;
  color?: InputMaybe<BooleanOperationFilterInput>;
  copies?: InputMaybe<ComparableInt32OperationFilterInput>;
  dpis?: InputMaybe<ListStringOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  medias?: InputMaybe<ListStringOperationFilterInput>;
  nup?: InputMaybe<ListComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<PrintNodePrinterCapabilitiesFilterInput>>;
  supportsCustomPaperSize?: InputMaybe<BooleanOperationFilterInput>;
};

export type PrintNodePrinterFilterInput = {
  and?: InputMaybe<Array<PrintNodePrinterFilterInput>>;
  capabilities?: InputMaybe<PrintNodePrinterCapabilitiesFilterInput>;
  computer?: InputMaybe<PrintNodeComputerFilterInput>;
  createTimeStamp?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt64OperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PrintNodePrinterFilterInput>>;
  state?: InputMaybe<StringOperationFilterInput>;
};

export type ProductAttributeDto = {
  __typename?: 'ProductAttributeDto';
  attribute?: Maybe<ProductAttributeDto>;
  attributeTerms?: Maybe<Array<Maybe<ProductAttributeTermDto>>>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  productAttributeId: Scalars['Int'];
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type ProductAttributeDtoConnection = {
  __typename?: 'ProductAttributeDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductAttributeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductAttributeDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductAttributeDtoEdge = {
  __typename?: 'ProductAttributeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductAttributeDto;
};

export type ProductAttributeFilterInput = {
  and?: InputMaybe<Array<ProductAttributeFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductAttributeFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type ProductAttributeSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type ProductAttributeTermDto = {
  __typename?: 'ProductAttributeTermDto';
  attribute?: Maybe<ProductAttributeDto>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type ProductAttributeTermDtoConnection = {
  __typename?: 'ProductAttributeTermDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductAttributeTermDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductAttributeTermDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductAttributeTermDtoEdge = {
  __typename?: 'ProductAttributeTermDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductAttributeTermDto;
};

export type ProductAttributeTermFilterInput = {
  and?: InputMaybe<Array<ProductAttributeTermFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductAttributeTermFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type ProductAttributeTermSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type ProductBrandDto = {
  __typename?: 'ProductBrandDto';
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type ProductBrandDtoConnection = {
  __typename?: 'ProductBrandDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductBrandDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductBrandDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductBrandDtoEdge = {
  __typename?: 'ProductBrandDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductBrandDto;
};

export type ProductCategoryDto = {
  __typename?: 'ProductCategoryDto';
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type ProductCategoryDtoConnection = {
  __typename?: 'ProductCategoryDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductCategoryDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductCategoryDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductCategoryDtoEdge = {
  __typename?: 'ProductCategoryDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductCategoryDto;
};

export type ProductDto = {
  __typename?: 'ProductDto';
  attributeTerms?: Maybe<Array<Maybe<ProductAttributeTermDto>>>;
  brand?: Maybe<ProductBrandDto>;
  category?: Maybe<ProductCategoryDto>;
  complianceId?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  inventoryType?: Maybe<InventoryTypeDto>;
  isDeleted: Scalars['Boolean'];
  label?: Maybe<LabelDto>;
  labelId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Decimal']>;
  strain?: Maybe<StrainDto>;
  traceabilityCommandIds?: Maybe<Array<Scalars['Uuid']>>;
  traceabilityError?: Maybe<Scalars['String']>;
  traceabilityStatus?: Maybe<TraceabilitySyncStatus>;
  traceabilityTag?: Maybe<Scalars['String']>;
  traceableName?: Maybe<Scalars['String']>;
  unit: UnitOfMeasure;
  unitName?: Maybe<Scalars['String']>;
  unitShortName?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type ProductDtoConnection = {
  __typename?: 'ProductDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductDtoEdge = {
  __typename?: 'ProductDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductDto;
};

export type ProductFilterInput = {
  and?: InputMaybe<Array<ProductFilterInput>>;
  brand?: InputMaybe<BrandFilterInput>;
  category?: InputMaybe<CategoryFilterInput>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  inventoryType?: InputMaybe<InventoryTypeFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<LabelFilterInput>;
  labelId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  size?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  strain?: InputMaybe<StrainFilterInput>;
  traceableName?: InputMaybe<StringOperationFilterInput>;
  unit?: InputMaybe<UnitOfMeasureOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type ProductReferenceInput = {
  inventoryType?: InputMaybe<InventoryTypeReferenceInput>;
  name?: InputMaybe<Scalars['String']>;
  productId: Scalars['Uuid'];
  wholesaleProductId: Scalars['Int'];
};

export type ProductSortInput = {
  brand?: InputMaybe<BrandSortInput>;
  category?: InputMaybe<CategorySortInput>;
  complianceId?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inventoryType?: InputMaybe<InventoryTypeSortInput>;
  isDeleted?: InputMaybe<SortEnumType>;
  label?: InputMaybe<LabelSortInput>;
  labelId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  size?: InputMaybe<SortEnumType>;
  strain?: InputMaybe<StrainSortInput>;
  traceableName?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export enum ProductType {
  Retail = 'RETAIL',
  Wholesale = 'WHOLESALE'
}

export type ProductUsageStat = Node & {
  __typename?: 'ProductUsageStat';
  activeUsers?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  date: Scalars['Date'];
  id: Scalars['Int'];
  /** Reads a single `License` that is related to this `ProductUsageStat`. */
  license?: Maybe<License>;
  licenseId?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `ProductUsageStat`. */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  salesCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProductUsageStat` object types. All fields are combined with a logical ‘and.’ */
export type ProductUsageStatFilter = {
  /** Filter by the object’s `activeUsers` field. */
  activeUsers?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProductUsageStatFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DateFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `licenseId` field. */
  licenseId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProductUsageStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProductUsageStatFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `salesCents` field. */
  salesCents?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductUsageStatNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `productUsageStat` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `license` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: LicensePatch;
};

/** The fields on `productUsageStat` to look up the row to update. */
export type ProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyUsingProductUsageStatsLicenseIdDateKeyUpdate = {
  date: Scalars['Date'];
  licenseId: Scalars['Int'];
  /** An object where the defined keys will be set on the `productUsageStat` being updated. */
  patch: UpdateProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyPatch;
};

/** The fields on `productUsageStat` to look up the row to update. */
export type ProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyUsingProductUsageStatsPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `productUsageStat` being updated. */
  patch: UpdateProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: OrganizationPatch;
};

/** The fields on `productUsageStat` to look up the row to update. */
export type ProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingProductUsageStatsLicenseIdDateKeyUpdate = {
  date: Scalars['Date'];
  licenseId: Scalars['Int'];
  /** An object where the defined keys will be set on the `productUsageStat` being updated. */
  patch: UpdateProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyPatch;
};

/** The fields on `productUsageStat` to look up the row to update. */
export type ProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingProductUsageStatsPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `productUsageStat` being updated. */
  patch: UpdateProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyPatch;
};

/** Represents an update to a `ProductUsageStat`. Fields that are set will be updated. */
export type ProductUsageStatPatch = {
  activeUsers?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  licenseId?: InputMaybe<Scalars['Int']>;
  licenseToLicenseId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInput>;
  salesCents?: InputMaybe<Scalars['Int']>;
};

/** The fields on `productUsageStat` to look up the row to connect. */
export type ProductUsageStatProductUsageStatsLicenseIdDateKeyConnect = {
  date: Scalars['Date'];
  licenseId: Scalars['Int'];
};

/** The fields on `productUsageStat` to look up the row to connect. */
export type ProductUsageStatProductUsageStatsPkeyConnect = {
  id: Scalars['Int'];
};

/** A connection to a list of `ProductUsageStat` values. */
export type ProductUsageStatsConnection = {
  __typename?: 'ProductUsageStatsConnection';
  /** A list of edges which contains the `ProductUsageStat` and cursor to aid in pagination. */
  edges: Array<ProductUsageStatsEdge>;
  /** A list of `ProductUsageStat` objects. */
  nodes: Array<Maybe<ProductUsageStat>>;
  /** The count of *all* `ProductUsageStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProductUsageStat` edge in the connection. */
export type ProductUsageStatsEdge = {
  __typename?: 'ProductUsageStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProductUsageStat` at the end of the edge. */
  node?: Maybe<ProductUsageStat>;
};

/** Input for the nested mutation of `license` in the `ProductUsageStatInput` mutation. */
export type ProductUsageStatsLicenseIdFkeyInput = {
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectById?: InputMaybe<LicenseLicensesPkeyConnect>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<LicenseNodeIdConnect>;
  /** A `LicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProductUsageStatsLicenseIdFkeyLicensesCreateInput>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteById?: InputMaybe<LicenseLicensesPkeyDelete>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<LicenseNodeIdDelete>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateById?: InputMaybe<LicenseOnProductUsageStatForProductUsageStatsLicenseIdFkeyUsingLicensesPkeyUpdate>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `productUsageStat` in the `LicenseInput` mutation. */
export type ProductUsageStatsLicenseIdFkeyInverseInput = {
  /** The primary key(s) for `productUsageStat` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProductUsageStatProductUsageStatsPkeyConnect>>;
  /** The primary key(s) for `productUsageStat` for the far side of the relationship. */
  connectByLicenseIdAndDate?: InputMaybe<Array<ProductUsageStatProductUsageStatsLicenseIdDateKeyConnect>>;
  /** The primary key(s) for `productUsageStat` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProductUsageStatNodeIdConnect>>;
  /** The primary key(s) and patch data for `productUsageStat` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyUsingProductUsageStatsPkeyUpdate>>;
  /** The primary key(s) and patch data for `productUsageStat` for the far side of the relationship. */
  updateByLicenseIdAndDate?: InputMaybe<Array<ProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyUsingProductUsageStatsLicenseIdDateKeyUpdate>>;
  /** The primary key(s) and patch data for `productUsageStat` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<LicenseOnProductUsageStatForProductUsageStatsLicenseIdFkeyNodeIdUpdate>>;
};

/** The `license` to be created by this mutation. */
export type ProductUsageStatsLicenseIdFkeyLicensesCreateInput = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType: ProductType;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state: Scalars['String'];
};

/** Methods to use when ordering `ProductUsageStat`. */
export enum ProductUsageStatsOrderBy {
  ActiveUsersAsc = 'ACTIVE_USERS_ASC',
  ActiveUsersDesc = 'ACTIVE_USERS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LicenseIdAsc = 'LICENSE_ID_ASC',
  LicenseIdDesc = 'LICENSE_ID_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SalesCentsAsc = 'SALES_CENTS_ASC',
  SalesCentsDesc = 'SALES_CENTS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Input for the nested mutation of `organization` in the `ProductUsageStatInput` mutation. */
export type ProductUsageStatsOrganizationId2FkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrganizationOrganizationsPkeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrganizationNodeIdConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProductUsageStatsOrganizationId2FkeyOrganizationsCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByKey?: InputMaybe<OrganizationOrganizationsPkeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByEnvironmentId?: InputMaybe<OrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingOrganizationsPkeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `productUsageStat` in the `OrganizationInput` mutation. */
export type ProductUsageStatsOrganizationId2FkeyInverseInput = {
  /** The primary key(s) for `productUsageStat` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProductUsageStatProductUsageStatsPkeyConnect>>;
  /** The primary key(s) for `productUsageStat` for the far side of the relationship. */
  connectByLicenseIdAndDate?: InputMaybe<Array<ProductUsageStatProductUsageStatsLicenseIdDateKeyConnect>>;
  /** The primary key(s) for `productUsageStat` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProductUsageStatNodeIdConnect>>;
  /** The primary key(s) and patch data for `productUsageStat` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingProductUsageStatsPkeyUpdate>>;
  /** The primary key(s) and patch data for `productUsageStat` for the far side of the relationship. */
  updateByLicenseIdAndDate?: InputMaybe<Array<ProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyUsingProductUsageStatsLicenseIdDateKeyUpdate>>;
  /** The primary key(s) and patch data for `productUsageStat` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<OrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyNodeIdUpdate>>;
};

/** The `organization` to be created by this mutation. */
export type ProductUsageStatsOrganizationId2FkeyOrganizationsCreateInput = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  billing: OrganizationBillingInfoInput;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name: Scalars['String'];
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  referralId?: InputMaybe<Scalars['String']>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

export type PurchaseOrderDto = {
  __typename?: 'PurchaseOrderDto';
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isVoided: Scalars['Boolean'];
  manifestComplianceId?: Maybe<Scalars['String']>;
  poNumber: Scalars['Int'];
  stage: PurchaseOrderStage;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorDto>;
};

/** A connection to a list of items. */
export type PurchaseOrderDtoConnection = {
  __typename?: 'PurchaseOrderDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PurchaseOrderDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PurchaseOrderDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PurchaseOrderDtoEdge = {
  __typename?: 'PurchaseOrderDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PurchaseOrderDto;
};

export type PurchaseOrderFilterInput = {
  and?: InputMaybe<Array<PurchaseOrderFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  manifestComplianceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PurchaseOrderFilterInput>>;
  poNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  stage?: InputMaybe<PurchaseOrderStageOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  vendor?: InputMaybe<VendorFilterInput>;
};

export type PurchaseOrderSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  manifestComplianceId?: InputMaybe<SortEnumType>;
  poNumber?: InputMaybe<SortEnumType>;
  stage?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  vendor?: InputMaybe<VendorSortInput>;
};

export enum PurchaseOrderStage {
  BackOrder = 'BACK_ORDER',
  Closed = 'CLOSED',
  ExternalRequest = 'EXTERNAL_REQUEST',
  InTransfer = 'IN_TRANSFER',
  NeedToManifest = 'NEED_TO_MANIFEST',
  NeedToTransfer = 'NEED_TO_TRANSFER',
  New = 'NEW',
  Paid = 'PAID',
  ReadyToLabel = 'READY_TO_LABEL',
  ReadyToPack = 'READY_TO_PACK',
  Rejected = 'REJECTED',
  Returned = 'RETURNED',
  Unknown = 'UNKNOWN',
  Voided = 'VOIDED'
}

export type PurchaseOrderStageOperationFilterInput = {
  eq?: InputMaybe<PurchaseOrderStage>;
  in?: InputMaybe<Array<PurchaseOrderStage>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<PurchaseOrderStage>;
  nin?: InputMaybe<Array<PurchaseOrderStage>>;
};

export enum PurchaseOrderStatus {
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Partiallyreceived = 'PARTIALLYRECEIVED',
  Placed = 'PLACED',
  Received = 'RECEIVED'
}

/** Input for the nested mutation of `license` in the `ReupPurchaseOrderInput` mutation. */
export type PurchaseOrdersBuyerIdFkeyInput = {
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectById?: InputMaybe<LicenseLicensesPkeyConnect>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<LicenseNodeIdConnect>;
  /** A `LicenseInput` object that will be created and connected to this object. */
  create?: InputMaybe<PurchaseOrdersBuyerIdFkeyLicensesCreateInput>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteById?: InputMaybe<LicenseLicensesPkeyDelete>;
  /** The primary key(s) for `license` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<LicenseNodeIdDelete>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateById?: InputMaybe<LicenseOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyUsingLicensesPkeyUpdate>;
  /** The primary key(s) and patch data for `license` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReupPurchaseOrderOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reupPurchaseOrder` in the `LicenseInput` mutation. */
export type PurchaseOrdersBuyerIdFkeyInverseInput = {
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ReupPurchaseOrderPurchaseOrdersPkeyConnect>>;
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReupPurchaseOrderNodeIdConnect>>;
  /** A `ReupPurchaseOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<PurchaseOrdersBuyerIdFkeyPurchaseOrdersCreateInput>>;
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ReupPurchaseOrderPurchaseOrdersPkeyDelete>>;
  /** The primary key(s) for `reupPurchaseOrder` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ReupPurchaseOrderNodeIdDelete>>;
  /** Flag indicating whether all other `reupPurchaseOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `reupPurchaseOrder` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ReupPurchaseOrderOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyUsingPurchaseOrdersPkeyUpdate>>;
  /** The primary key(s) and patch data for `reupPurchaseOrder` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<LicenseOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyNodeIdUpdate>>;
};

/** The `license` to be created by this mutation. */
export type PurchaseOrdersBuyerIdFkeyLicensesCreateInput = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId: Scalars['String'];
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName: Scalars['String'];
  licenseNumber: Scalars['String'];
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType: ProductType;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state: Scalars['String'];
};

/** The `reupPurchaseOrder` to be created by this mutation. */
export type PurchaseOrdersBuyerIdFkeyPurchaseOrdersCreateInput = {
  displayPoNumber?: InputMaybe<Scalars['String']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  licenseToBuyerId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInput>;
  matchedTransferId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  note?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  reupLineItemsUsingId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInverseInput>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
};

export enum QuantityType {
  CountBased = 'COUNT_BASED',
  VolumeBased = 'VOLUME_BASED',
  WeightBased = 'WEIGHT_BASED'
}

export type QuantityTypeOperationFilterInput = {
  eq?: InputMaybe<QuantityType>;
  in?: InputMaybe<Array<QuantityType>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<QuantityType>;
  nin?: InputMaybe<Array<QuantityType>>;
};

export type Query = {
  __typename?: 'Query';
  accountLabels: Array<Maybe<LabelDto>>;
  accountUser?: Maybe<AccountUserDto>;
  allOrganizations?: Maybe<AllOrganizationDtoConnection>;
  allUsers: Array<Maybe<AllUserDto>>;
  apiRequest?: Maybe<ApiRequest>;
  /** Reads a single `ApiRequest` using its globally unique `ID`. */
  apiRequestByNodeId?: Maybe<ApiRequest>;
  /** Reads and enables pagination through a set of `ApiRequest`. */
  apiRequests?: Maybe<ApiRequestsConnection>;
  ccrsCsvUpload?: Maybe<CcrsCsvUploadDtoConnection>;
  ccrsManifestUpload?: Maybe<CcrsManifestUploadDtoConnection>;
  conversion?: Maybe<ConversionsView>;
  conversions?: Maybe<Array<Maybe<ConversionsView>>>;
  costAllocatedToPlantFromGroupEvents?: Maybe<Array<Maybe<PlantCostAllocationLog>>>;
  costAllocatedToPlantGroupEvents?: Maybe<Array<Maybe<CostAllocationLog>>>;
  currentUserId?: Maybe<Scalars['Int']>;
  dataSyncJobStatus?: Maybe<GraphileWorkerJobStatus>;
  disposals?: Maybe<DisposalDtoConnection>;
  employees?: Maybe<EmployeeDtoConnection>;
  feature?: Maybe<Feature>;
  /** Reads a single `Feature` using its globally unique `ID`. */
  featureByNodeId?: Maybe<Feature>;
  featureUsage?: Maybe<FeatureUsage>;
  /** Reads a single `FeatureUsage` using its globally unique `ID`. */
  featureUsageByNodeId?: Maybe<FeatureUsage>;
  featureUsageByStripeId?: Maybe<FeatureUsage>;
  featureUsageByStripeSubscriptionIdAndPriceId?: Maybe<FeatureUsage>;
  featureUsageLicense?: Maybe<FeatureUsageLicense>;
  featureUsageLicenseByFeatureusageAndLicense?: Maybe<FeatureUsageLicense>;
  /** Reads a single `FeatureUsageLicense` using its globally unique `ID`. */
  featureUsageLicenseByNodeId?: Maybe<FeatureUsageLicense>;
  /** Reads and enables pagination through a set of `FeatureUsageLicense`. */
  featureUsageLicenses?: Maybe<FeatureUsageLicensesConnection>;
  /** Reads and enables pagination through a set of `FeatureUsage`. */
  featureUsages?: Maybe<FeatureUsagesConnection>;
  /** Reads and enables pagination through a set of `Feature`. */
  features?: Maybe<FeaturesConnection>;
  /** Reads and enables pagination through a set of `Feature`. */
  featuresByProductType?: Maybe<FeaturesConnection>;
  /** Query that returns active jurisdictions */
  getArcCoreJurisdictions: Array<ArcCore_Jurisdiction>;
  /** Reads and enables pagination through a set of `License`. */
  getAuthorizedLicensesForOrganizationAndModel?: Maybe<LicensesConnection>;
  getDejavooTerminalStatus: TerminalStatus;
  /** Generate a firebase token with an additional claim to the chosen license number IF available inside the provided Growflow JWT token */
  getFirebaseToken?: Maybe<FirebaseTokenResponse>;
  getInsightsAuthorizedDashboardsLayout: Array<InsightsLayout>;
  getJurisdictionsForUserEmail: Array<Scalars['String']>;
  getOrganizationsForUserEmail: Array<WholesaleOrganization>;
  /** Query that returns active Products w/ Velocity and on-hand inventory counts */
  getReupProducts?: Maybe<Array<Reup_Product>>;
  getReupSuppliers?: Maybe<Array<Reup_Supplier>>;
  getSignedLookerURL: Scalars['String'];
  getStoreFrontUserProfile: StoreFrontUserProfileData;
  /** Query that returns a list of vendor's the user has selected to buy as */
  getStoreFrontUserVendors: Array<StoreFrontUserVendorDto>;
  /** Query that returns a list of storeFronts given a state and vendor who is viewing store fronts */
  getStoreFronts: Array<StoreFront>;
  /** Query that returns a listing for a storefront. */
  getStoreListing: StoreFrontListing;
  /** Query that returns a list of storeFronts products for a given store front, vendorId is which vendor is viewing the store */
  getStoreProducts: Array<StoreFrontProduct>;
  /** GetLogged in User from Auth0 Access Token */
  getUser: Scalars['String'];
  /** Query that returns vendors */
  getVendors: Array<Vendor>;
  /** Query that returns vendors matching a filter */
  getVendorsByFilter: Array<Vendor>;
  /** Query that returns vendors for licenses */
  getVendorsForLicenses: Array<Vendor>;
  /** Query that returns vendors */
  getVendorsV2: Array<Vendor>;
  harvestById?: Maybe<HarvestView>;
  harvests?: Maybe<HarvestDtoConnection>;
  harvestsByIds?: Maybe<Array<Maybe<HarvestView>>>;
  images?: Maybe<ImageDtoConnection>;
  inventories?: Maybe<InventoryDtoConnection>;
  inventorySamples?: Maybe<InventorySampleDtoConnection>;
  inventoryTypes?: Maybe<InventoryTypeDtoConnection>;
  jurisdictions?: Maybe<JurisdictionDtoConnection>;
  labelPages: Array<Maybe<LabelPageDto>>;
  labels: Array<Maybe<LabelDto>>;
  license?: Maybe<License>;
  /** Reads a single `License` using its globally unique `ID`. */
  licenseByNodeId?: Maybe<License>;
  /** Reads and enables pagination through a set of `License`. */
  licenses?: Maybe<LicensesConnection>;
  lineItemFilters?: Maybe<LineItemFilters>;
  lineItems?: Maybe<LineItemDtoConnection>;
  lockoutGracePeriod?: Maybe<LockoutGracePeriod>;
  /** Reads a single `LockoutGracePeriod` using its globally unique `ID`. */
  lockoutGracePeriodByNodeId?: Maybe<LockoutGracePeriod>;
  /** Reads and enables pagination through a set of `LockoutGracePeriod`. */
  lockoutGracePeriods?: Maybe<LockoutGracePeriodsConnection>;
  loggedAction?: Maybe<LoggedAction>;
  /** Reads a single `LoggedAction` using its globally unique `ID`. */
  loggedActionByNodeId?: Maybe<LoggedAction>;
  /** Reads and enables pagination through a set of `LoggedAction`. */
  loggedActions?: Maybe<LoggedActionsConnection>;
  lookupAuth0UserByEmail?: Maybe<Array<Maybe<Auth0User>>>;
  me?: Maybe<UserDto>;
  myAccount?: Maybe<AccountDto>;
  myAccounts?: Maybe<AccountDtoConnection>;
  myJurisdiction?: Maybe<JurisdictionDto>;
  note?: Maybe<Note>;
  /** Reads a single `Note` using its globally unique `ID`. */
  noteByNodeId?: Maybe<Note>;
  /** Reads and enables pagination through a set of `Note`. */
  notes?: Maybe<NotesConnection>;
  organization?: Maybe<Organization>;
  organizationByEnvironmentId?: Maybe<Organization>;
  /** Reads a single `Organization` using its globally unique `ID`. */
  organizationByNodeId?: Maybe<Organization>;
  /** Reads and enables pagination through a set of `Organization`. */
  organizations?: Maybe<OrganizationsConnection>;
  package?: Maybe<InventoryView>;
  packages?: Maybe<Array<Maybe<InventoryView>>>;
  paytenderAccount?: Maybe<PaytenderAccount>;
  /** Reads a single `PaytenderAccount` using its globally unique `ID`. */
  paytenderAccountByNodeId?: Maybe<PaytenderAccount>;
  paytenderAccountByOrganizationKeyAndLicenseNumber?: Maybe<PaytenderAccount>;
  /** Reads and enables pagination through a set of `PaytenderAccount`. */
  paytenderAccounts?: Maybe<PaytenderAccountsConnection>;
  paytenderUser?: Maybe<PaytenderUser>;
  perpetualInventoryDetails?: Maybe<Array<Maybe<PerpetualInventoryDetail>>>;
  perpetualInventorySummary?: Maybe<Array<Maybe<PerpetualInventorySummary>>>;
  plantById?: Maybe<PlantView>;
  plants?: Maybe<PlantDtoConnection>;
  plantsByIds?: Maybe<Array<Maybe<PlantView>>>;
  price?: Maybe<Price>;
  /** Reads a single `Price` using its globally unique `ID`. */
  priceByNodeId?: Maybe<Price>;
  /** Reads and enables pagination through a set of `Price`. */
  prices?: Maybe<PricesConnection>;
  printers: Array<Maybe<PrintNodePrinter>>;
  productAttributeTerms?: Maybe<ProductAttributeTermDtoConnection>;
  productAttributes?: Maybe<ProductAttributeDtoConnection>;
  productBrands?: Maybe<ProductBrandDtoConnection>;
  productCategories?: Maybe<ProductCategoryDtoConnection>;
  productUsageStat?: Maybe<ProductUsageStat>;
  productUsageStatByLicenseIdAndDate?: Maybe<ProductUsageStat>;
  /** Reads a single `ProductUsageStat` using its globally unique `ID`. */
  productUsageStatByNodeId?: Maybe<ProductUsageStat>;
  /** Reads and enables pagination through a set of `ProductUsageStat`. */
  productUsageStats?: Maybe<ProductUsageStatsConnection>;
  products?: Maybe<ProductDtoConnection>;
  purchaseOrders?: Maybe<PurchaseOrderDtoConnection>;
  readinessCheck?: Maybe<Scalars['Boolean']>;
  retailadmin_getEnvironmentActivity?: Maybe<Array<Maybe<Activity>>>;
  retailadmin_getEnvironments?: Maybe<Array<Maybe<Environment>>>;
  retailadmin_listActiveEnvironments?: Maybe<Array<Maybe<Scalars['String']>>>;
  reupLineItem?: Maybe<ReupLineItem>;
  /** Reads a single `ReupLineItem` using its globally unique `ID`. */
  reupLineItemByNodeId?: Maybe<ReupLineItem>;
  /** Reads and enables pagination through a set of `ReupLineItem`. */
  reupLineItems?: Maybe<ReupLineItemsConnection>;
  reupProduct?: Maybe<ReupProduct>;
  /** Reads a single `ReupProduct` using its globally unique `ID`. */
  reupProductByNodeId?: Maybe<ReupProduct>;
  /** Reads and enables pagination through a set of `ReupProduct`. */
  reupProducts?: Maybe<ReupProductsConnection>;
  reupPurchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** Reads a single `ReupPurchaseOrder` using its globally unique `ID`. */
  reupPurchaseOrderByNodeId?: Maybe<ReupPurchaseOrder>;
  /** Reads and enables pagination through a set of `ReupPurchaseOrder`. */
  reupPurchaseOrders?: Maybe<ReupPurchaseOrdersConnection>;
  rooms?: Maybe<RoomDtoConnection>;
  roomtables?: Maybe<RoomTableDtoConnection>;
  serviceStatus?: Maybe<ServiceStatusDto>;
  shortenedLinkStats?: Maybe<ShortenedLinkStatsResponse>;
  /** StoreFront Test endpoint for generating a test error to view results */
  storeFrontError: Scalars['String'];
  storeFronts?: Maybe<StoreFrontDtoConnection>;
  storeMenuPdf: FileResponse;
  strains?: Maybe<StrainDtoConnection>;
  stripeSubscription?: Maybe<StripeSubscription>;
  /** Reads a single `StripeSubscription` using its globally unique `ID`. */
  stripeSubscriptionByNodeId?: Maybe<StripeSubscription>;
  stripeSubscriptionByScheduleId?: Maybe<StripeSubscription>;
  stripeSubscriptionByStripeId?: Maybe<StripeSubscription>;
  /** Reads and enables pagination through a set of `StripeSubscription`. */
  stripeSubscriptions?: Maybe<StripeSubscriptionsConnection>;
  taxRate?: Maybe<TaxRate>;
  /** Reads a single `TaxRate` using its globally unique `ID`. */
  taxRateByNodeId?: Maybe<TaxRate>;
  /** Reads and enables pagination through a set of `TaxRate`. */
  taxRates?: Maybe<TaxRatesConnection>;
  tenant_checkPermission?: Maybe<Scalars['Boolean']>;
  tenant_getJurisdictionsForUserEmail: Array<Scalars['String']>;
  tenant_getLicensesForCurrentUser?: Maybe<Array<Maybe<UserLicense>>>;
  tenant_getLicensesInLicenseGroups: Array<Scalars['String']>;
  traceabilityRoomType?: Maybe<TraceabilityRoomTypeDtoConnection>;
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` using its globally unique `ID`. */
  transactionByNodeId?: Maybe<Transaction>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions?: Maybe<TransactionsConnection>;
  typeOfReports?: Maybe<ReportConfigurationDtoConnection>;
  typeOfReportsByJurisdiction?: Maybe<ReportConfigurationDtoConnection>;
  user?: Maybe<User>;
  userByGlobalUserId?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads and enables pagination through a set of `UserLinkedIdentity`. */
  userLinkedIdentities?: Maybe<UserLinkedIdentitiesConnection>;
  userLinkedIdentity?: Maybe<UserLinkedIdentity>;
  userLinkedIdentityByLinkedGlobalUserId?: Maybe<UserLinkedIdentity>;
  /** Reads a single `UserLinkedIdentity` using its globally unique `ID`. */
  userLinkedIdentityByNodeId?: Maybe<UserLinkedIdentity>;
  userOrganizations?: Maybe<OrganizationDtoConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `User`. */
  usersByEmail?: Maybe<UsersConnection>;
  vehicles?: Maybe<VehicleDtoConnection>;
  vendorById?: Maybe<VendorDto>;
  vendors?: Maybe<VendorDtoConnection>;
  warehouseQuery: QueryResponse;
};


export type QueryAccountLabelsArgs = {
  order?: InputMaybe<Array<LabelSortInput>>;
  where?: InputMaybe<LabelFilterInput>;
};


export type QueryAccountUserArgs = {
  order?: InputMaybe<Array<AccountUserSortInput>>;
  where?: InputMaybe<AccountUserFilterInput>;
};


export type QueryAllOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AllOrganizationSortInput>>;
  where?: InputMaybe<AllOrganizationFilterInput>;
};


export type QueryAllUsersArgs = {
  organizationId: Scalars['String'];
};


export type QueryApiRequestArgs = {
  id: Scalars['Int'];
};


export type QueryApiRequestByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryApiRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ApiRequestCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApiRequestsOrderBy>>;
};


export type QueryCcrsCsvUploadArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CcrsCsvUploadSortInput>>;
  where?: InputMaybe<CcrsCsvUploadFilterInput>;
};


export type QueryCcrsManifestUploadArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CcrsManifestUploadSortInput>>;
  where?: InputMaybe<CcrsManifestUploadFilterInput>;
};


export type QueryConversionArgs = {
  query?: InputMaybe<GetConversionInput>;
};


export type QueryConversionsArgs = {
  query?: InputMaybe<GetConversionsInput>;
};


export type QueryCostAllocatedToPlantFromGroupEventsArgs = {
  query?: InputMaybe<GetCostAllocatedToPlantFromGroupEventsInput>;
};


export type QueryCostAllocatedToPlantGroupEventsArgs = {
  query?: InputMaybe<GetCostAllocatedToPlantGroupEventsInput>;
};


export type QueryDataSyncJobStatusArgs = {
  jobId: Scalars['Int'];
};


export type QueryDisposalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<DisposalSortInput>>;
  where?: InputMaybe<DisposalFilterInput>;
};


export type QueryEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<EmployeeSortInput>>;
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryFeatureArgs = {
  id: Scalars['String'];
};


export type QueryFeatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryFeatureUsageArgs = {
  id: Scalars['Int'];
};


export type QueryFeatureUsageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryFeatureUsageByStripeIdArgs = {
  stripeId: Scalars['String'];
};


export type QueryFeatureUsageByStripeSubscriptionIdAndPriceIdArgs = {
  priceId: Scalars['String'];
  stripeSubscriptionId: Scalars['Int'];
};


export type QueryFeatureUsageLicenseArgs = {
  id: Scalars['Int'];
};


export type QueryFeatureUsageLicenseByFeatureusageAndLicenseArgs = {
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
};


export type QueryFeatureUsageLicenseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryFeatureUsageLicensesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureUsageLicenseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeatureUsageLicensesOrderBy>>;
};


export type QueryFeatureUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureUsageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeatureUsagesOrderBy>>;
};


export type QueryFeaturesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeaturesOrderBy>>;
};


export type QueryFeaturesByProductTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  productType?: InputMaybe<ProductType>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryGetAuthorizedLicensesForOrganizationAndModelArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LicenseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  modelName?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationKey?: InputMaybe<Scalars['String']>;
};


export type QueryGetDejavooTerminalStatusArgs = {
  terminal: Terminal;
};


export type QueryGetFirebaseTokenArgs = {
  gcpProjectId: Scalars['String'];
  licenseNumber: Scalars['String'];
  regionAbbreviation: Scalars['String'];
};


export type QueryGetInsightsAuthorizedDashboardsLayoutArgs = {
  organizationKey: Scalars['String'];
};


export type QueryGetJurisdictionsForUserEmailArgs = {
  userEmail: Scalars['String'];
};


export type QueryGetOrganizationsForUserEmailArgs = {
  pagination?: InputMaybe<Pagination>;
  state: Scalars['String'];
  userEmail: Scalars['String'];
};


export type QueryGetReupProductsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orgkey: Scalars['String'];
  storekey: Scalars['String'];
};


export type QueryGetReupSuppliersArgs = {
  orgkey: Scalars['String'];
};


export type QueryGetSignedLookerUrlArgs = {
  dashboardId: Scalars['String'];
  embedDomain: Scalars['String'];
  licenseNumbers: Array<Scalars['String']>;
  organizationKey: Scalars['String'];
};


export type QueryGetStoreFrontUserVendorsArgs = {
  state: Scalars['String'];
};


export type QueryGetStoreFrontsArgs = {
  isTraining?: InputMaybe<Scalars['Boolean']>;
  state: Scalars['String'];
  vendorId: Scalars['Int'];
};


export type QueryGetStoreListingArgs = {
  state: Scalars['String'];
  storeFrontId: Scalars['Int'];
  vendorId: Scalars['Int'];
};


export type QueryGetStoreProductsArgs = {
  state: Scalars['String'];
  storeFrontId: Scalars['Int'];
  vendorId: Scalars['Int'];
};


export type QueryGetVendorsArgs = {
  isTraining?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<Pagination>;
  state: Scalars['String'];
};


export type QueryGetVendorsByFilterArgs = {
  filter?: InputMaybe<Scalars['String']>;
  isTraining?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<Pagination>;
  state: Scalars['String'];
};


export type QueryGetVendorsForLicensesArgs = {
  isTraining?: InputMaybe<Scalars['Boolean']>;
  licenseNumbers: Array<InputMaybe<Scalars['String']>>;
  pagination?: InputMaybe<Pagination>;
  state: Scalars['String'];
};


export type QueryGetVendorsV2Args = {
  pagination?: InputMaybe<Pagination>;
  state: Scalars['String'];
};


export type QueryHarvestByIdArgs = {
  query?: InputMaybe<GetHarvestByIdInput>;
};


export type QueryHarvestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<BatchSortInput>>;
  where?: InputMaybe<BatchFilterInput>;
};


export type QueryHarvestsByIdsArgs = {
  query?: InputMaybe<GetHarvestsByIdsInput>;
};


export type QueryImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ImageSortInput>>;
  where?: InputMaybe<ImageFilterInput>;
};


export type QueryInventoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InventorySortInput>>;
  where?: InputMaybe<InventoryFilterInput>;
};


export type QueryInventorySamplesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InventorySampleSortInput>>;
  where?: InputMaybe<InventorySampleFilterInput>;
};


export type QueryInventoryTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InventoryTypeSortInput>>;
  where?: InputMaybe<InventoryTypeFilterInput>;
};


export type QueryJurisdictionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TypeOfJurisdictionSortInput>>;
  where?: InputMaybe<TypeOfJurisdictionFilterInput>;
};


export type QueryLabelPagesArgs = {
  includeDefault?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LabelPageFilterInput>;
};


export type QueryLabelsArgs = {
  order?: InputMaybe<Array<LabelSortInput>>;
  where?: InputMaybe<LabelFilterInput>;
};


export type QueryLicenseArgs = {
  id: Scalars['Int'];
};


export type QueryLicenseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryLicensesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LicenseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LicensesOrderBy>>;
};


export type QueryLineItemFiltersArgs = {
  where?: InputMaybe<LineItemFilterInput>;
};


export type QueryLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<LineItemSortInput>>;
  where?: InputMaybe<LineItemFilterInput>;
};


export type QueryLockoutGracePeriodArgs = {
  id: Scalars['Int'];
};


export type QueryLockoutGracePeriodByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryLockoutGracePeriodsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LockoutGracePeriodFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LockoutGracePeriodsOrderBy>>;
};


export type QueryLoggedActionArgs = {
  eventId: Scalars['BigInt'];
};


export type QueryLoggedActionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryLoggedActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LoggedActionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LoggedActionsOrderBy>>;
};


export type QueryLookupAuth0UserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryMyAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AccountSortInput>>;
  where?: InputMaybe<AccountFilterInput>;
};


export type QueryNoteArgs = {
  id: Scalars['Int'];
};


export type QueryNoteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


export type QueryOrganizationArgs = {
  key: Scalars['String'];
};


export type QueryOrganizationByEnvironmentIdArgs = {
  environmentId: Scalars['String'];
};


export type QueryOrganizationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};


export type QueryPackageArgs = {
  query?: InputMaybe<GetPackageInput>;
};


export type QueryPackagesArgs = {
  query?: InputMaybe<GetPackagesInput>;
};


export type QueryPaytenderAccountArgs = {
  id: Scalars['Int'];
};


export type QueryPaytenderAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryPaytenderAccountByOrganizationKeyAndLicenseNumberArgs = {
  licenseNumber: Scalars['String'];
  organizationKey: Scalars['String'];
};


export type QueryPaytenderAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaytenderAccountCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaytenderAccountsOrderBy>>;
};


export type QueryPaytenderUserArgs = {
  merchant: PaytenderMerchant;
  phoneNumber: Scalars['String'];
};


export type QueryPerpetualInventoryDetailsArgs = {
  categoryFilter?: InputMaybe<Scalars['String']>;
  measureFilter?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};


export type QueryPerpetualInventorySummaryArgs = {
  startDate: Scalars['DateTime'];
};


export type QueryPlantByIdArgs = {
  query?: InputMaybe<GetPlantByIdInput>;
};


export type QueryPlantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<PlantSortInput>>;
  where?: InputMaybe<PlantFilterInput>;
};


export type QueryPlantsByIdsArgs = {
  query?: InputMaybe<GetPlantsByIdsInput>;
};


export type QueryPriceArgs = {
  id: Scalars['String'];
};


export type QueryPriceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryPricesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PriceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricesOrderBy>>;
};


export type QueryPrintersArgs = {
  where?: InputMaybe<PrintNodePrinterFilterInput>;
};


export type QueryProductAttributeTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductAttributeTermSortInput>>;
  where?: InputMaybe<ProductAttributeTermFilterInput>;
};


export type QueryProductAttributesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductAttributeSortInput>>;
  where?: InputMaybe<ProductAttributeFilterInput>;
};


export type QueryProductBrandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<BrandSortInput>>;
  where?: InputMaybe<BrandFilterInput>;
};


export type QueryProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CategorySortInput>>;
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryProductUsageStatArgs = {
  id: Scalars['Int'];
};


export type QueryProductUsageStatByLicenseIdAndDateArgs = {
  date: Scalars['Date'];
  licenseId: Scalars['Int'];
};


export type QueryProductUsageStatByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryProductUsageStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProductUsageStatFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductUsageStatsOrderBy>>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  where?: InputMaybe<ProductFilterInput>;
};


export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<PurchaseOrderSortInput>>;
  where?: InputMaybe<PurchaseOrderFilterInput>;
};


export type QueryRetailadmin_GetEnvironmentActivityArgs = {
  filters?: InputMaybe<Array<InputMaybe<Where>>>;
};


export type QueryRetailadmin_GetEnvironmentsArgs = {
  filters?: InputMaybe<Array<InputMaybe<Where>>>;
};


export type QueryReupLineItemArgs = {
  id: Scalars['Int'];
};


export type QueryReupLineItemByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryReupLineItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ReupLineItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReupLineItemsOrderBy>>;
};


export type QueryReupProductArgs = {
  id: Scalars['Int'];
};


export type QueryReupProductByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryReupProductsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ReupProductFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReupProductsOrderBy>>;
};


export type QueryReupPurchaseOrderArgs = {
  id: Scalars['Int'];
};


export type QueryReupPurchaseOrderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryReupPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ReupPurchaseOrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReupPurchaseOrdersOrderBy>>;
};


export type QueryRoomsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<RoomSortInput>>;
  where?: InputMaybe<RoomFilterInput>;
};


export type QueryRoomtablesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<RoomTableSortInput>>;
  where?: InputMaybe<RoomTableFilterInput>;
};


export type QueryShortenedLinkStatsArgs = {
  id: Scalars['String'];
};


export type QueryStoreFrontErrorArgs = {
  state: Scalars['String'];
};


export type QueryStoreFrontsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<StoreFrontSortInput>>;
  where?: InputMaybe<StoreFrontFilterInput>;
};


export type QueryStoreMenuPdfArgs = {
  storeMenuDto: StoreMenuDtoInput;
};


export type QueryStrainsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<StrainSortInput>>;
  where?: InputMaybe<StrainFilterInput>;
};


export type QueryStripeSubscriptionArgs = {
  id: Scalars['Int'];
};


export type QueryStripeSubscriptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryStripeSubscriptionByScheduleIdArgs = {
  scheduleId: Scalars['String'];
};


export type QueryStripeSubscriptionByStripeIdArgs = {
  stripeId: Scalars['String'];
};


export type QueryStripeSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<StripeSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StripeSubscriptionsOrderBy>>;
};


export type QueryTaxRateArgs = {
  zipcode: Scalars['Int'];
};


export type QueryTaxRateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTaxRatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TaxRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};


export type QueryTenant_CheckPermissionArgs = {
  model: Scalars['String'];
  permissions: Array<Scalars['String']>;
  scope: Scalars['String'];
  scopeType: PermissionScopeType;
  verb: PermissionVerb;
};


export type QueryTenant_GetJurisdictionsForUserEmailArgs = {
  userEmail: Scalars['String'];
};


export type QueryTenant_GetLicensesForCurrentUserArgs = {
  organization: Scalars['String'];
};


export type QueryTenant_GetLicensesInLicenseGroupsArgs = {
  licenseGroups: Array<Scalars['String']>;
};


export type QueryTraceabilityRoomTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TraceabilityRoomTypeSortInput>>;
  where?: InputMaybe<TraceabilityRoomTypeFilterInput>;
};


export type QueryTransactionArgs = {
  id: Scalars['String'];
};


export type QueryTransactionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};


export type QueryTypeOfReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TypeOfReportSortInput>>;
  where?: InputMaybe<TypeOfReportFilterInput>;
};


export type QueryTypeOfReportsByJurisdictionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<TypeOfReportSortInput>>;
  where?: InputMaybe<TypeOfReportFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserByGlobalUserIdArgs = {
  globalUserId: Scalars['UUID'];
};


export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryUserLinkedIdentitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserLinkedIdentityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserLinkedIdentitiesOrderBy>>;
};


export type QueryUserLinkedIdentityArgs = {
  id: Scalars['Int'];
};


export type QueryUserLinkedIdentityByLinkedGlobalUserIdArgs = {
  linkedGlobalUserId: Scalars['UUID'];
};


export type QueryUserLinkedIdentityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryUserOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrganizationSortInput>>;
  where?: InputMaybe<OrganizationFilterInput>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type QueryUsersByEmailArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<VehicleSortInput>>;
  where?: InputMaybe<VehicleFilterInput>;
};


export type QueryVendorByIdArgs = {
  id: Scalars['Int'];
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<VendorSortInput>>;
  where?: InputMaybe<VendorFilterInput>;
};


export type QueryWarehouseQueryArgs = {
  sqlQuery: Scalars['String'];
};

export type QueryResponse = {
  __typename?: 'QueryResponse';
  LatencyMS?: Maybe<Scalars['Int']>;
  ResponseColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResponseValues?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

export type ReceiveInboundPackageInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  initialCost: Scalars['Decimal'];
  inventoryId: Scalars['Uuid'];
  inventoryTypeName?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<CannabisLicenseInput>;
  productName?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Decimal'];
  strain?: InputMaybe<Scalars['String']>;
  wholesaleInventoryId: Scalars['Int'];
};

export type RecipeReferenceInput = {
  name?: InputMaybe<Scalars['String']>;
  recipeId: Scalars['Uuid'];
  wholesaleRecipeId: Scalars['Int'];
};

export type ReconcileErrorsEventInput = {
  applicationScope?: InputMaybe<CcrsApplicationScope>;
  asOfDateTime?: InputMaybe<Scalars['DateTime']>;
  ccrsFileNames?: InputMaybe<Array<CcrsFileName>>;
  ignoreAsOfDate?: InputMaybe<Scalars['Boolean']>;
  licenseNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transactionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReportConfigurationDto = {
  __typename?: 'ReportConfigurationDto';
  group?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ReportConfigurationDtoConnection = {
  __typename?: 'ReportConfigurationDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReportConfigurationDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ReportConfigurationDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReportConfigurationDtoEdge = {
  __typename?: 'ReportConfigurationDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReportConfigurationDto;
};

export type ReupLineItem = Node & {
  __typename?: 'ReupLineItem';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  orderSessionId?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  /** Reads a single `ReupPurchaseOrder` that is related to this `ReupLineItem`. */
  purchaseOrder?: Maybe<ReupPurchaseOrder>;
  purchaseOrderId: Scalars['Int'];
  receivedQuantity?: Maybe<Scalars['Int']>;
  requestedQuantity?: Maybe<Scalars['Int']>;
  requiredQuantity?: Maybe<Scalars['Int']>;
  suggestedQuantity?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['BigFloat']>;
  unitPrice?: Maybe<Scalars['BigFloat']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `ReupLineItem` object types. All fields are combined with a logical ‘and.’ */
export type ReupLineItemFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReupLineItemFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReupLineItemFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReupLineItemFilter>>;
  /** Filter by the object’s `orderSessionId` field. */
  orderSessionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `purchaseOrderId` field. */
  purchaseOrderId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `receivedQuantity` field. */
  receivedQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `requestedQuantity` field. */
  requestedQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `requiredQuantity` field. */
  requiredQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `suggestedQuantity` field. */
  suggestedQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ReupLineItem` */
export type ReupLineItemInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderSessionId?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  purchaseOrderId?: InputMaybe<Scalars['Int']>;
  receivedQuantity?: InputMaybe<Scalars['Int']>;
  requestedQuantity?: InputMaybe<Scalars['Int']>;
  requiredQuantity?: InputMaybe<Scalars['Int']>;
  reupPurchaseOrderToPurchaseOrderId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInput>;
  suggestedQuantity?: InputMaybe<Scalars['Int']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
  unitPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** The fields on `reupLineItem` to look up the row to connect. */
export type ReupLineItemLineItemsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `reupLineItem` to look up the row to delete. */
export type ReupLineItemLineItemsPkeyDelete = {
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to connect. */
export type ReupLineItemNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reupLineItem` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ReupLineItemNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `reupLineItem` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReupLineItemOnReupLineItemForLineItemsPurchaseOrderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `reupPurchaseOrder` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `reupPurchaseOrder` being updated. */
  patch: ReupPurchaseOrderPatch;
};

/** The fields on `reupLineItem` to look up the row to update. */
export type ReupLineItemOnReupLineItemForLineItemsPurchaseOrderIdFkeyUsingLineItemsPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `reupLineItem` being updated. */
  patch: UpdateReupLineItemOnReupLineItemForLineItemsPurchaseOrderIdFkeyPatch;
};

/** Represents an update to a `ReupLineItem`. Fields that are set will be updated. */
export type ReupLineItemPatch = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderSessionId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  purchaseOrderId?: InputMaybe<Scalars['Int']>;
  receivedQuantity?: InputMaybe<Scalars['Int']>;
  requestedQuantity?: InputMaybe<Scalars['Int']>;
  requiredQuantity?: InputMaybe<Scalars['Int']>;
  reupPurchaseOrderToPurchaseOrderId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInput>;
  suggestedQuantity?: InputMaybe<Scalars['Int']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
  unitPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ReupLineItem` values. */
export type ReupLineItemsConnection = {
  __typename?: 'ReupLineItemsConnection';
  /** A list of edges which contains the `ReupLineItem` and cursor to aid in pagination. */
  edges: Array<ReupLineItemsEdge>;
  /** A list of `ReupLineItem` objects. */
  nodes: Array<Maybe<ReupLineItem>>;
  /** The count of *all* `ReupLineItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReupLineItem` edge in the connection. */
export type ReupLineItemsEdge = {
  __typename?: 'ReupLineItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReupLineItem` at the end of the edge. */
  node?: Maybe<ReupLineItem>;
};

/** Methods to use when ordering `ReupLineItem`. */
export enum ReupLineItemsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  OrderSessionIdAsc = 'ORDER_SESSION_ID_ASC',
  OrderSessionIdDesc = 'ORDER_SESSION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  PurchaseOrderIdAsc = 'PURCHASE_ORDER_ID_ASC',
  PurchaseOrderIdDesc = 'PURCHASE_ORDER_ID_DESC',
  ReceivedQuantityAsc = 'RECEIVED_QUANTITY_ASC',
  ReceivedQuantityDesc = 'RECEIVED_QUANTITY_DESC',
  RequestedQuantityAsc = 'REQUESTED_QUANTITY_ASC',
  RequestedQuantityDesc = 'REQUESTED_QUANTITY_DESC',
  RequiredQuantityAsc = 'REQUIRED_QUANTITY_ASC',
  RequiredQuantityDesc = 'REQUIRED_QUANTITY_DESC',
  SuggestedQuantityAsc = 'SUGGESTED_QUANTITY_ASC',
  SuggestedQuantityDesc = 'SUGGESTED_QUANTITY_DESC',
  TotalPriceAsc = 'TOTAL_PRICE_ASC',
  TotalPriceDesc = 'TOTAL_PRICE_DESC',
  UnitPriceAsc = 'UNIT_PRICE_ASC',
  UnitPriceDesc = 'UNIT_PRICE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ReupOrderInput = {
  buyerId?: InputMaybe<Scalars['Int']>;
  cost: Scalars['Float'];
  orgKey?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Float'];
  required: Scalars['Float'];
  suggested: Scalars['Float'];
  supplierId: Scalars['String'];
  supplierName: Scalars['String'];
  unitPrice: Scalars['Float'];
};

export type ReupProduct = Node & {
  __typename?: 'ReupProduct';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A filter to be used against `ReupProduct` object types. All fields are combined with a logical ‘and.’ */
export type ReupProductFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReupProductFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReupProductFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReupProductFilter>>;
};

/** An input for mutations affecting `ReupProduct` */
export type ReupProductInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ReupProduct`. Fields that are set will be updated. */
export type ReupProductPatch = {
  name?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `ReupProduct` values. */
export type ReupProductsConnection = {
  __typename?: 'ReupProductsConnection';
  /** A list of edges which contains the `ReupProduct` and cursor to aid in pagination. */
  edges: Array<ReupProductsEdge>;
  /** A list of `ReupProduct` objects. */
  nodes: Array<Maybe<ReupProduct>>;
  /** The count of *all* `ReupProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReupProduct` edge in the connection. */
export type ReupProductsEdge = {
  __typename?: 'ReupProductsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReupProduct` at the end of the edge. */
  node?: Maybe<ReupProduct>;
};

/** Methods to use when ordering `ReupProduct`. */
export enum ReupProductsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ReupPurchaseOrder = Node & {
  __typename?: 'ReupPurchaseOrder';
  /** Reads a single `License` that is related to this `ReupPurchaseOrder`. */
  buyer?: Maybe<License>;
  buyerId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  displayPoNumber?: Maybe<Scalars['String']>;
  externalReferenceNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  matchedTransferId?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  organizationKey?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ReupLineItem`. */
  reupLineItems: ReupLineItemsConnection;
  status?: Maybe<PurchaseOrderStatus>;
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['BigFloat']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type ReupPurchaseOrderReupLineItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ReupLineItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReupLineItemsOrderBy>>;
};

/** A filter to be used against `ReupPurchaseOrder` object types. All fields are combined with a logical ‘and.’ */
export type ReupPurchaseOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReupPurchaseOrderFilter>>;
  /** Filter by the object’s `buyerId` field. */
  buyerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `displayPoNumber` field. */
  displayPoNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalReferenceNumber` field. */
  externalReferenceNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `matchedTransferId` field. */
  matchedTransferId?: InputMaybe<StringListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReupPurchaseOrderFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReupPurchaseOrderFilter>>;
  /** Filter by the object’s `organizationKey` field. */
  organizationKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `poNumber` field. */
  poNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `supplierId` field. */
  supplierId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `supplierName` field. */
  supplierName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ReupPurchaseOrder` */
export type ReupPurchaseOrderInput = {
  buyerId?: InputMaybe<Scalars['Int']>;
  displayPoNumber?: InputMaybe<Scalars['String']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  licenseToBuyerId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInput>;
  matchedTransferId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  note?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  reupLineItemsUsingId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInverseInput>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReupPurchaseOrderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reupPurchaseOrder` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ReupPurchaseOrderNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `reupPurchaseOrder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReupPurchaseOrderOnReupLineItemForLineItemsPurchaseOrderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `reupLineItem` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `reupLineItem` being updated. */
  patch: ReupLineItemPatch;
};

/** The fields on `reupPurchaseOrder` to look up the row to update. */
export type ReupPurchaseOrderOnReupLineItemForLineItemsPurchaseOrderIdFkeyUsingPurchaseOrdersPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `reupPurchaseOrder` being updated. */
  patch: UpdateReupPurchaseOrderOnReupLineItemForLineItemsPurchaseOrderIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ReupPurchaseOrderOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `license` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `license` being updated. */
  patch: LicensePatch;
};

/** The fields on `reupPurchaseOrder` to look up the row to update. */
export type ReupPurchaseOrderOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyUsingPurchaseOrdersPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `reupPurchaseOrder` being updated. */
  patch: UpdateReupPurchaseOrderOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyPatch;
};

/** Represents an update to a `ReupPurchaseOrder`. Fields that are set will be updated. */
export type ReupPurchaseOrderPatch = {
  buyerId?: InputMaybe<Scalars['Int']>;
  displayPoNumber?: InputMaybe<Scalars['String']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  licenseToBuyerId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInput>;
  matchedTransferId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  note?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  reupLineItemsUsingId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInverseInput>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** The fields on `reupPurchaseOrder` to look up the row to connect. */
export type ReupPurchaseOrderPurchaseOrdersPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `reupPurchaseOrder` to look up the row to delete. */
export type ReupPurchaseOrderPurchaseOrdersPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `ReupPurchaseOrder` values. */
export type ReupPurchaseOrdersConnection = {
  __typename?: 'ReupPurchaseOrdersConnection';
  /** A list of edges which contains the `ReupPurchaseOrder` and cursor to aid in pagination. */
  edges: Array<ReupPurchaseOrdersEdge>;
  /** A list of `ReupPurchaseOrder` objects. */
  nodes: Array<Maybe<ReupPurchaseOrder>>;
  /** The count of *all* `ReupPurchaseOrder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReupPurchaseOrder` edge in the connection. */
export type ReupPurchaseOrdersEdge = {
  __typename?: 'ReupPurchaseOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReupPurchaseOrder` at the end of the edge. */
  node?: Maybe<ReupPurchaseOrder>;
};

/** Methods to use when ordering `ReupPurchaseOrder`. */
export enum ReupPurchaseOrdersOrderBy {
  BuyerIdAsc = 'BUYER_ID_ASC',
  BuyerIdDesc = 'BUYER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DisplayPoNumberAsc = 'DISPLAY_PO_NUMBER_ASC',
  DisplayPoNumberDesc = 'DISPLAY_PO_NUMBER_DESC',
  ExternalReferenceNumberAsc = 'EXTERNAL_REFERENCE_NUMBER_ASC',
  ExternalReferenceNumberDesc = 'EXTERNAL_REFERENCE_NUMBER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  MatchedTransferIdAsc = 'MATCHED_TRANSFER_ID_ASC',
  MatchedTransferIdDesc = 'MATCHED_TRANSFER_ID_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  OrganizationKeyAsc = 'ORGANIZATION_KEY_ASC',
  OrganizationKeyDesc = 'ORGANIZATION_KEY_DESC',
  PoNumberAsc = 'PO_NUMBER_ASC',
  PoNumberDesc = 'PO_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SupplierIdAsc = 'SUPPLIER_ID_ASC',
  SupplierIdDesc = 'SUPPLIER_ID_DESC',
  SupplierNameAsc = 'SUPPLIER_NAME_ASC',
  SupplierNameDesc = 'SUPPLIER_NAME_DESC',
  TotalPriceAsc = 'TOTAL_PRICE_ASC',
  TotalPriceDesc = 'TOTAL_PRICE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Reup_Product = {
  __typename?: 'Reup_Product';
  CategoryType: Scalars['String'];
  CustomCategory?: Maybe<Scalars['String']>;
  DefaultEstimatedStockOut?: Maybe<Scalars['String']>;
  EstimatedDailyLoss?: Maybe<Scalars['Float']>;
  EstimatedStockOut_1Week?: Maybe<Scalars['String']>;
  EstimatedStockOut_2Weeks?: Maybe<Scalars['String']>;
  EstimatedStockOut_12Weeks?: Maybe<Scalars['String']>;
  LicenseNum?: Maybe<Scalars['String']>;
  MedianPrice?: Maybe<Scalars['Float']>;
  OrganizationKey: Scalars['String'];
  ProductName: Scalars['String'];
  QuantityOnHand?: Maybe<Scalars['Float']>;
  StoreId: Scalars['String'];
  StoreKey: Scalars['String'];
  StoreName: Scalars['String'];
  StrainType?: Maybe<Scalars['String']>;
  Suppliers?: Maybe<Array<Reup_ProductSupplier>>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Velocity_1Week?: Maybe<Scalars['Float']>;
  Velocity_2Weeks?: Maybe<Scalars['Float']>;
  Velocity_7Days_Actual?: Maybe<Scalars['Int']>;
  Velocity_12Weeks?: Maybe<Scalars['Float']>;
  key: Scalars['String'];
};

export type Reup_ProductSupplier = {
  __typename?: 'Reup_ProductSupplier';
  SupplierId: Scalars['String'];
  SupplierLicenseNumber?: Maybe<Scalars['String']>;
  SupplierName: Scalars['String'];
  UnitCost?: Maybe<Scalars['Float']>;
};

export type Reup_Supplier = {
  __typename?: 'Reup_Supplier';
  SupplierId: Scalars['String'];
  SupplierLicenseNumber?: Maybe<Scalars['String']>;
  SupplierName: Scalars['String'];
};

export type RoomCreateDtoInput = {
  isDefault: Scalars['Boolean'];
  isPlant: Scalars['Boolean'];
  name: Scalars['String'];
  purpose?: InputMaybe<SiteType>;
  traceabilityRoomTypeId?: InputMaybe<Scalars['Int']>;
};

export type RoomDto = {
  __typename?: 'RoomDto';
  complianceId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isForHarvests: Scalars['Boolean'];
  isForPlants: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  isPlant: Scalars['Boolean'];
  name: Scalars['String'];
  purpose?: Maybe<SiteType>;
  roomTables?: Maybe<Array<Maybe<RoomTableDto>>>;
  traceabilityRoomTypeId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type RoomDtoConnection = {
  __typename?: 'RoomDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RoomDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RoomDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RoomDtoEdge = {
  __typename?: 'RoomDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RoomDto;
};

export type RoomFilterInput = {
  and?: InputMaybe<Array<RoomFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isPlant?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RoomFilterInput>>;
  purpose?: InputMaybe<SiteTypeOperationFilterInput>;
  roomTables?: InputMaybe<ListFilterInputTypeOfRoomTableFilterInput>;
};

export type RoomSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isInactive?: InputMaybe<SortEnumType>;
  isPlant?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  purpose?: InputMaybe<SortEnumType>;
};

export type RoomTableCreateDtoInput = {
  cols?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  roomId: Scalars['Int'];
  rows?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<RoomTableType>;
};

export type RoomTableDto = {
  __typename?: 'RoomTableDto';
  cols?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  roomId: Scalars['Int'];
  rows?: Maybe<Scalars['Int']>;
  type?: Maybe<RoomTableType>;
};

/** A connection to a list of items. */
export type RoomTableDtoConnection = {
  __typename?: 'RoomTableDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RoomTableDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RoomTableDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RoomTableDtoEdge = {
  __typename?: 'RoomTableDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RoomTableDto;
};

export type RoomTableDtoInput = {
  cols?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  roomId: Scalars['Int'];
  rows?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<RoomTableType>;
};

export type RoomTableFilterInput = {
  and?: InputMaybe<Array<RoomTableFilterInput>>;
  cols?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RoomTableFilterInput>>;
  roomId?: InputMaybe<ComparableInt32OperationFilterInput>;
  rows?: InputMaybe<ComparableInt32OperationFilterInput>;
  type?: InputMaybe<RoomTableTypeOperationFilterInput>;
};

export type RoomTableSortInput = {
  cols?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  roomId?: InputMaybe<SortEnumType>;
  rows?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum RoomTableType {
  Default = 'DEFAULT'
}

export type RoomTableTypeOperationFilterInput = {
  eq?: InputMaybe<RoomTableType>;
  in?: InputMaybe<Array<RoomTableType>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<RoomTableType>;
  nin?: InputMaybe<Array<RoomTableType>>;
};

export type RoomUpdateDtoInput = {
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isPlant: Scalars['Boolean'];
  name: Scalars['String'];
  purpose?: InputMaybe<SiteType>;
  traceabilityRoomTypeId?: InputMaybe<Scalars['Int']>;
};

export type ServiceStatusDto = {
  __typename?: 'ServiceStatusDto';
  asOf: Scalars['DateTime'];
  assemblies?: Maybe<Array<Maybe<DotNetAssemblyDto>>>;
};

/** All input for the `setPaytenderAccount` mutation. */
export type SetPaytenderAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  isDemo?: InputMaybe<Scalars['Boolean']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  merchantSalt?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
};

/** The output of our `setPaytenderAccount` mutation. */
export type SetPaytenderAccountPayload = {
  __typename?: 'SetPaytenderAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  paytenderAccount?: Maybe<PaytenderAccount>;
  /** An edge for our `PaytenderAccount`. May be used by Relay 1. */
  paytenderAccountEdge?: Maybe<PaytenderAccountsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `setPaytenderAccount` mutation. */
export type SetPaytenderAccountPayloadPaytenderAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<PaytenderAccountsOrderBy>>;
};

export type SetSourcePackageCostInput = {
  baseCost: Scalars['Decimal'];
  complianceId?: InputMaybe<Scalars['String']>;
  inventoryId: Scalars['Uuid'];
  license?: InputMaybe<CannabisLicenseInput>;
  wholesaleInventoryId: Scalars['Int'];
};

export type ShortenedLinkStat = {
  __typename?: 'ShortenedLinkStat';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ShortenedLinkStatGroup = {
  __typename?: 'ShortenedLinkStatGroup';
  browser?: Maybe<Array<Maybe<ShortenedLinkStat>>>;
  country?: Maybe<Array<Maybe<ShortenedLinkStat>>>;
  os?: Maybe<Array<Maybe<ShortenedLinkStat>>>;
  referrer?: Maybe<Array<Maybe<ShortenedLinkStat>>>;
};

export type ShortenedLinkStatsResponse = {
  __typename?: 'ShortenedLinkStatsResponse';
  address?: Maybe<Scalars['String']>;
  allTime?: Maybe<ShortenedLinkViews>;
  banned?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastDay?: Maybe<ShortenedLinkViews>;
  lastMonth?: Maybe<ShortenedLinkViews>;
  lastWeek?: Maybe<ShortenedLinkViews>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Boolean']>;
  target?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  visit_count?: Maybe<Scalars['Int']>;
};

export type ShortenedLinkViews = {
  __typename?: 'ShortenedLinkViews';
  stats?: Maybe<ShortenedLinkStatGroup>;
  views?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum SiteType {
  Clone = 'CLONE',
  DefaultInventory = 'DEFAULT_INVENTORY',
  DefaultPlant = 'DEFAULT_PLANT',
  Drying = 'DRYING',
  Flower = 'FLOWER',
  FlowerInv = 'FLOWER_INV',
  FlowerLots = 'FLOWER_LOTS',
  HideActivePackages = 'HIDE_ACTIVE_PACKAGES',
  Quarantine = 'QUARANTINE',
  QuarantineForOrders = 'QUARANTINE_FOR_ORDERS',
  Unknown = 'UNKNOWN',
  UnknownInventory = 'UNKNOWN_INVENTORY',
  UnknownPlant = 'UNKNOWN_PLANT',
  Veg = 'VEG'
}

export type SiteTypeOperationFilterInput = {
  eq?: InputMaybe<SiteType>;
  in?: InputMaybe<Array<SiteType>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<SiteType>;
  nin?: InputMaybe<Array<SiteType>>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SplitPackageForSaleInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  inventoryConversionSourceId: Scalars['Int'];
  inventoryId: Scalars['Uuid'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<CannabisLicenseInput>;
  lineItemId: Scalars['Int'];
  newComplianceId?: InputMaybe<Scalars['String']>;
  newPackageId: Scalars['Uuid'];
  newPackageWholesaleId: Scalars['Int'];
  productName?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Decimal'];
  wholesaleInventoryId: Scalars['Int'];
};

export type SplitPackageInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  inventoryId: Scalars['Uuid'];
  inventoryTypeName?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<CannabisLicenseInput>;
  newPackageComplianceId?: InputMaybe<Scalars['String']>;
  newPackageId: Scalars['Uuid'];
  newPackageWholesaleId: Scalars['Int'];
  productName?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Decimal'];
  wholesaleInventoryId: Scalars['Int'];
};

export type StoreDisplayOptions = {
  __typename?: 'StoreDisplayOptions';
  Brand?: Maybe<Scalars['Boolean']>;
  CBD?: Maybe<Scalars['Boolean']>;
  THC?: Maybe<Scalars['Boolean']>;
  TotalOptional?: Maybe<Scalars['Boolean']>;
  TotalTerpenes?: Maybe<Scalars['Boolean']>;
};

export type StoreFront = {
  __typename?: 'StoreFront';
  AccessStatus?: Maybe<StoreFrontAccessStatus>;
  AccountId: Scalars['Int'];
  City?: Maybe<Scalars['String']>;
  ColumnOptionsDTO?: Maybe<StoreDisplayOptions>;
  ContactInfo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  FacebookUrl?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  InstagramUrl?: Maybe<Scalars['String']>;
  IsFeatured?: Maybe<Scalars['Boolean']>;
  IsPrivateVisibility?: Maybe<Scalars['Boolean']>;
  IsPublicVisibility?: Maybe<Scalars['Boolean']>;
  LicenseNumber?: Maybe<Scalars['String']>;
  LogoUrl?: Maybe<Scalars['String']>;
  MinimumOrderValue?: Maybe<Scalars['Int']>;
  MinimumOrderValueMode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Order?: Maybe<Scalars['Int']>;
  Phone?: Maybe<Scalars['String']>;
  PreOrders?: Maybe<Scalars['Boolean']>;
  Region?: Maybe<Scalars['String']>;
  ShowProductStatusFilter?: Maybe<Scalars['Boolean']>;
  TwitterUrl?: Maybe<Scalars['String']>;
  UBI?: Maybe<Scalars['String']>;
  VendorId: Scalars['Int'];
  Website?: Maybe<Scalars['String']>;
};

export type StoreFrontAccessRequest = {
  FirstName?: InputMaybe<Scalars['String']>;
  LastName?: InputMaybe<Scalars['String']>;
  PhoneNumber?: InputMaybe<Scalars['String']>;
  StoreFrontId: Scalars['Int'];
  VendorId: Scalars['Int'];
};

export enum StoreFrontAccessStatus {
  Locked = 'Locked',
  Verified = 'Verified',
  WaitingForVerification = 'WaitingForVerification'
}

export type StoreFrontCreateOrder = {
  InternalNote?: InputMaybe<Scalars['String']>;
  LineItems: Array<StoreFrontCreateOrderLineItem>;
  StoreFrontId: Scalars['Int'];
  VendorId: Scalars['Int'];
};

export type StoreFrontCreateOrderLineItem = {
  Discount: Scalars['Float'];
  Note?: InputMaybe<Scalars['String']>;
  Price: Scalars['Float'];
  ProductId: Scalars['Int'];
  Quantity: Scalars['Float'];
};

export type StoreFrontCreateOrderResultDto = {
  __typename?: 'StoreFrontCreateOrderResultDTO';
  Id: Scalars['Int'];
  OrderNum: Scalars['Int'];
};

export type StoreFrontDto = {
  __typename?: 'StoreFrontDto';
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type StoreFrontDtoConnection = {
  __typename?: 'StoreFrontDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<StoreFrontDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StoreFrontDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StoreFrontDtoEdge = {
  __typename?: 'StoreFrontDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StoreFrontDto;
};

export type StoreFrontFilterInput = {
  and?: InputMaybe<Array<StoreFrontFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StoreFrontFilterInput>>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type StoreFrontListing = {
  __typename?: 'StoreFrontListing';
  listings: Array<StoreFrontProductListing>;
  products: Array<StoreFrontProduct>;
};

export type StoreFrontPermission = {
  __typename?: 'StoreFrontPermission';
  Email?: Maybe<Scalars['String']>;
  IsVerified: Scalars['Boolean'];
  StoreFrontId: Scalars['Int'];
  StoreFrontName?: Maybe<Scalars['String']>;
  VendorId: Scalars['Int'];
  VendorName?: Maybe<Scalars['String']>;
};

export type StoreFrontPermissionFilterInput = {
  and?: InputMaybe<Array<StoreFrontPermissionFilterInput>>;
  buyerFirstName?: InputMaybe<StringOperationFilterInput>;
  buyerLastName?: InputMaybe<StringOperationFilterInput>;
  buyerPhone?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  isUserFavorite?: InputMaybe<BooleanOperationFilterInput>;
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<StoreFrontPermissionFilterInput>>;
  storeFront?: InputMaybe<StoreFrontFilterInput>;
  storeFrontId?: InputMaybe<ComparableInt32OperationFilterInput>;
  storeFrontUserVendor?: InputMaybe<StoreFrontUserVendorFilterInput>;
  storeFrontUserVendorId?: InputMaybe<ComparableInt32OperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  verifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type StoreFrontProduct = {
  __typename?: 'StoreFrontProduct';
  Available?: Maybe<Scalars['String']>;
  BulkGramsAvailable?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Discount?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  Image2OriginalUrl?: Maybe<Scalars['String']>;
  Image2Url?: Maybe<Scalars['String']>;
  Image3OriginalUrl?: Maybe<Scalars['String']>;
  Image3Url?: Maybe<Scalars['String']>;
  ImageOriginalUrl?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  IsEducationalSample?: Maybe<Scalars['Boolean']>;
  IsSample?: Maybe<Scalars['Boolean']>;
  MSRP?: Maybe<Scalars['String']>;
  MaxCBDMax?: Maybe<Scalars['String']>;
  MaxTHCMax?: Maybe<Scalars['String']>;
  MaxTotalOptional?: Maybe<Scalars['String']>;
  MaxTotalTerpenes?: Maybe<Scalars['String']>;
  MinCBDMax?: Maybe<Scalars['String']>;
  MinTHCMax?: Maybe<Scalars['String']>;
  MinTotalOptional?: Maybe<Scalars['String']>;
  MinTotalTerpenes?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['String']>;
  ProductAttributes?: Maybe<Scalars['String']>;
  ProductBrandId?: Maybe<Scalars['String']>;
  ProductBrandName?: Maybe<Scalars['String']>;
  ProductCategoryId?: Maybe<Scalars['String']>;
  ProductCategoryName?: Maybe<Scalars['String']>;
  ProductCategoryParentId?: Maybe<Scalars['String']>;
  ProductCategoryParentName?: Maybe<Scalars['String']>;
  ProductStatus?: Maybe<Scalars['String']>;
  ProductStatusName?: Maybe<Scalars['String']>;
  QuantityType?: Maybe<Scalars['String']>;
  Size?: Maybe<Scalars['String']>;
  SourcedProductId?: Maybe<Scalars['String']>;
  StrainName?: Maybe<Scalars['String']>;
  StrainNotes?: Maybe<Scalars['String']>;
  StrainTypeName?: Maybe<Scalars['String']>;
  UnitName?: Maybe<Scalars['String']>;
  UnitShortName?: Maybe<Scalars['String']>;
  VideoEmbedLink?: Maybe<Scalars['String']>;
};

export type StoreFrontProductListing = {
  __typename?: 'StoreFrontProductListing';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  products: Array<StoreFrontProduct>;
};

export type StoreFrontSignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type StoreFrontSortInput = {
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type StoreFrontUserProfileData = {
  __typename?: 'StoreFrontUserProfileData';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
};

/** Buying license vendor has access to */
export type StoreFrontUserVendorDto = {
  __typename?: 'StoreFrontUserVendorDTO';
  BuyerVendorId: Scalars['Int'];
  BuyerVendorLicenseNumber?: Maybe<Scalars['String']>;
  BuyerVendorName?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  UserId?: Maybe<Scalars['String']>;
};

export type StoreFrontUserVendorFilterInput = {
  and?: InputMaybe<Array<StoreFrontUserVendorFilterInput>>;
  buyerVendor?: InputMaybe<VendorFilterInput>;
  buyerVendorId?: InputMaybe<ComparableInt32OperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<StoreFrontUserVendorFilterInput>>;
  storeFrontPermissions?: InputMaybe<ListFilterInputTypeOfStoreFrontPermissionFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
};

export type StoreMenuDtoInput = {
  email?: InputMaybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  licenseNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pageOrientation: PageOrientation;
  phone?: InputMaybe<Scalars['String']>;
  products: Array<StoreMenuProductDtoInput>;
  storeIconImageUrl?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['String']>;
};

export type StoreMenuProductDtoInput = {
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  iconImageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCategory?: InputMaybe<Scalars['String']>;
  price: Scalars['Decimal'];
  strainType?: InputMaybe<Scalars['String']>;
  thcContentDescription?: InputMaybe<Scalars['String']>;
};

export type StrainCreateDtoInput = {
  description?: InputMaybe<Scalars['String']>;
  indicaPercentage?: InputMaybe<Scalars['Decimal']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  sativaPercentage?: InputMaybe<Scalars['Decimal']>;
  strainType?: InputMaybe<StrainType>;
};

export type StrainDto = {
  __typename?: 'StrainDto';
  complianceId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  indicaPercentage?: Maybe<Scalars['Decimal']>;
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  sativaPercentage?: Maybe<Scalars['Decimal']>;
  strainType?: Maybe<StrainType>;
};

/** A connection to a list of items. */
export type StrainDtoConnection = {
  __typename?: 'StrainDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<StrainDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StrainDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StrainDtoEdge = {
  __typename?: 'StrainDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StrainDto;
};

export type StrainFilterInput = {
  and?: InputMaybe<Array<StrainFilterInput>>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  indicaPercentage?: InputMaybe<ComparableDecimalOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isInactive?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StrainFilterInput>>;
  sativaPercentage?: InputMaybe<ComparableDecimalOperationFilterInput>;
  strainType?: InputMaybe<StrainTypeOperationFilterInput>;
};

export type StrainSortInput = {
  complianceId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  indicaPercentage?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isInactive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  sativaPercentage?: InputMaybe<SortEnumType>;
  strainType?: InputMaybe<SortEnumType>;
};

export enum StrainType {
  Cbd = 'CBD',
  CbdIndica = 'CBD_INDICA',
  CbdSativa = 'CBD_SATIVA',
  Hybrid = 'HYBRID',
  Indica = 'INDICA',
  IndicaHybrid = 'INDICA_HYBRID',
  Sativa = 'SATIVA',
  SativaHybrid = 'SATIVA_HYBRID'
}

export type StrainTypeOperationFilterInput = {
  eq?: InputMaybe<StrainType>;
  in?: InputMaybe<Array<StrainType>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<StrainType>;
  nin?: InputMaybe<Array<StrainType>>;
};

export type StrainUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  indicaPercentage?: InputMaybe<Scalars['Decimal']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  sativaPercentage?: InputMaybe<Scalars['Decimal']>;
  strainType?: InputMaybe<StrainType>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['String']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StripeSubscription = Node & {
  __typename?: 'StripeSubscription';
  createdAt: Scalars['Datetime'];
  /** ID for the subscription in external billing system (Recurly) */
  externalId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `FeatureUsage`. */
  featureUsages: FeatureUsagesConnection;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `StripeSubscription`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars['String'];
  pauseBehavior?: Maybe<Scalars['String']>;
  pauseReason?: Maybe<Scalars['String']>;
  pauseResumesAt?: Maybe<Scalars['Datetime']>;
  pausedAt?: Maybe<Scalars['Datetime']>;
  scheduleId?: Maybe<Scalars['String']>;
  status: SubscriptionStatus;
  stripeId?: Maybe<Scalars['String']>;
  trialEnd?: Maybe<Scalars['Datetime']>;
  trialStart?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type StripeSubscriptionFeatureUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FeatureUsageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeatureUsagesOrderBy>>;
};

/** A filter to be used against `StripeSubscription` object types. All fields are combined with a logical ‘and.’ */
export type StripeSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StripeSubscriptionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `externalId` field. */
  externalId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StripeSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StripeSubscriptionFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `pauseBehavior` field. */
  pauseBehavior?: InputMaybe<StringFilter>;
  /** Filter by the object’s `pauseReason` field. */
  pauseReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `pauseResumesAt` field. */
  pauseResumesAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `pausedAt` field. */
  pausedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `scheduleId` field. */
  scheduleId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeId` field. */
  stripeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trialEnd` field. */
  trialEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trialStart` field. */
  trialStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type StripeSubscriptionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `stripeSubscription` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `featureUsage` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `featureUsage` being updated. */
  patch: FeatureUsagePatch;
};

/** The fields on `stripeSubscription` to look up the row to update. */
export type StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingStripeSubscriptionsPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: UpdateStripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch;
};

/** The fields on `stripeSubscription` to look up the row to update. */
export type StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingStripeSubscriptionsScheduleIdKeyUpdate = {
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: UpdateStripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch;
  scheduleId: Scalars['String'];
};

/** The fields on `stripeSubscription` to look up the row to update. */
export type StripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyUsingStripeSubscriptionsStripeIdKeyUpdate = {
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: UpdateStripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch;
  stripeId: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: OrganizationPatch;
};

/** The fields on `stripeSubscription` to look up the row to update. */
export type StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingStripeSubscriptionsPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: UpdateStripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch;
};

/** The fields on `stripeSubscription` to look up the row to update. */
export type StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingStripeSubscriptionsScheduleIdKeyUpdate = {
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: UpdateStripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch;
  scheduleId: Scalars['String'];
};

/** The fields on `stripeSubscription` to look up the row to update. */
export type StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingStripeSubscriptionsStripeIdKeyUpdate = {
  /** An object where the defined keys will be set on the `stripeSubscription` being updated. */
  patch: UpdateStripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch;
  stripeId: Scalars['String'];
};

/** Represents an update to a `StripeSubscription`. Fields that are set will be updated. */
export type StripeSubscriptionPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** ID for the subscription in external billing system (Recurly) */
  externalId?: InputMaybe<Scalars['String']>;
  featureUsagesUsingId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInput>;
  pauseBehavior?: InputMaybe<Scalars['String']>;
  pauseReason?: InputMaybe<Scalars['String']>;
  pauseResumesAt?: InputMaybe<Scalars['Datetime']>;
  pausedAt?: InputMaybe<Scalars['Datetime']>;
  scheduleId?: InputMaybe<Scalars['String']>;
  stripeId?: InputMaybe<Scalars['String']>;
  trialEnd?: InputMaybe<Scalars['Datetime']>;
  trialStart?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `stripeSubscription` to look up the row to connect. */
export type StripeSubscriptionStripeSubscriptionsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `stripeSubscription` to look up the row to connect. */
export type StripeSubscriptionStripeSubscriptionsScheduleIdKeyConnect = {
  scheduleId: Scalars['String'];
};

/** The fields on `stripeSubscription` to look up the row to connect. */
export type StripeSubscriptionStripeSubscriptionsStripeIdKeyConnect = {
  stripeId: Scalars['String'];
};

/** A connection to a list of `StripeSubscription` values. */
export type StripeSubscriptionsConnection = {
  __typename?: 'StripeSubscriptionsConnection';
  /** A list of edges which contains the `StripeSubscription` and cursor to aid in pagination. */
  edges: Array<StripeSubscriptionsEdge>;
  /** A list of `StripeSubscription` objects. */
  nodes: Array<Maybe<StripeSubscription>>;
  /** The count of *all* `StripeSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `StripeSubscription` edge in the connection. */
export type StripeSubscriptionsEdge = {
  __typename?: 'StripeSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `StripeSubscription` at the end of the edge. */
  node?: Maybe<StripeSubscription>;
};

/** Methods to use when ordering `StripeSubscription`. */
export enum StripeSubscriptionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ExternalIdAsc = 'EXTERNAL_ID_ASC',
  ExternalIdDesc = 'EXTERNAL_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PausedAtAsc = 'PAUSED_AT_ASC',
  PausedAtDesc = 'PAUSED_AT_DESC',
  PauseBehaviorAsc = 'PAUSE_BEHAVIOR_ASC',
  PauseBehaviorDesc = 'PAUSE_BEHAVIOR_DESC',
  PauseReasonAsc = 'PAUSE_REASON_ASC',
  PauseReasonDesc = 'PAUSE_REASON_DESC',
  PauseResumesAtAsc = 'PAUSE_RESUMES_AT_ASC',
  PauseResumesAtDesc = 'PAUSE_RESUMES_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScheduleIdAsc = 'SCHEDULE_ID_ASC',
  ScheduleIdDesc = 'SCHEDULE_ID_DESC',
  StripeIdAsc = 'STRIPE_ID_ASC',
  StripeIdDesc = 'STRIPE_ID_DESC',
  TrialEndAsc = 'TRIAL_END_ASC',
  TrialEndDesc = 'TRIAL_END_DESC',
  TrialStartAsc = 'TRIAL_START_ASC',
  TrialStartDesc = 'TRIAL_START_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Input for the nested mutation of `organization` in the `StripeSubscriptionInput` mutation. */
export type StripeSubscriptionsOrganizationId2FkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByKey?: InputMaybe<OrganizationOrganizationsPkeyConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrganizationNodeIdConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyOrganizationsCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByEnvironmentId?: InputMaybe<OrganizationOrganizationsEnvironmentIdKeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByKey?: InputMaybe<OrganizationOrganizationsPkeyDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByEnvironmentId?: InputMaybe<OrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingOrganizationsEnvironmentIdKeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByKey?: InputMaybe<OrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingOrganizationsPkeyUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `stripeSubscription` in the `OrganizationInput` mutation. */
export type StripeSubscriptionsOrganizationId2FkeyInverseInput = {
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectById?: InputMaybe<Array<StripeSubscriptionStripeSubscriptionsPkeyConnect>>;
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<StripeSubscriptionNodeIdConnect>>;
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectByScheduleId?: InputMaybe<Array<StripeSubscriptionStripeSubscriptionsScheduleIdKeyConnect>>;
  /** The primary key(s) for `stripeSubscription` for the far side of the relationship. */
  connectByStripeId?: InputMaybe<Array<StripeSubscriptionStripeSubscriptionsStripeIdKeyConnect>>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateById?: InputMaybe<Array<StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingStripeSubscriptionsPkeyUpdate>>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<OrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateByScheduleId?: InputMaybe<Array<StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingStripeSubscriptionsScheduleIdKeyUpdate>>;
  /** The primary key(s) and patch data for `stripeSubscription` for the far side of the relationship. */
  updateByStripeId?: InputMaybe<Array<StripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyUsingStripeSubscriptionsStripeIdKeyUpdate>>;
};

/** The `organization` to be created by this mutation. */
export type StripeSubscriptionsOrganizationId2FkeyOrganizationsCreateInput = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  billing: OrganizationBillingInfoInput;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name: Scalars['String'];
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  referralId?: InputMaybe<Scalars['String']>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

export type SubmitCcrsReportEventInput = {
  applicationScope: CcrsApplicationScope;
  asOfDateTime?: InputMaybe<Scalars['DateTime']>;
  ccrsFileNames?: InputMaybe<Array<CcrsFileName>>;
  isSandbox?: InputMaybe<Scalars['Boolean']>;
  licenseNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum SubscriptionBillingStatus {
  Cancelled = 'CANCELLED',
  InTrial = 'IN_TRIAL',
  NotSetup = 'NOT_SETUP',
  PaidUp = 'PAID_UP',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED'
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  NotSetup = 'NOT_SETUP',
  NotStarted = 'NOT_STARTED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export enum SyncStatus {
  AuthenticationError = 'AUTHENTICATION_ERROR',
  CcrsFailedProcesingFile = 'CCRS_FAILED_PROCESING_FILE',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  ErrorsReconciled = 'ERRORS_RECONCILED',
  ErrorProcessingFailed = 'ERROR_PROCESSING_FAILED',
  Pending = 'PENDING',
  ProcessingErrors = 'PROCESSING_ERRORS',
  Reprocessing = 'REPROCESSING',
  Uploaded = 'UPLOADED'
}

export type SyncStatusOperationFilterInput = {
  eq?: InputMaybe<SyncStatus>;
  in?: InputMaybe<Array<SyncStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<SyncStatus>;
  nin?: InputMaybe<Array<SyncStatus>>;
};

export type TaxRate = Node & {
  __typename?: 'TaxRate';
  city?: Maybe<Scalars['String']>;
  cityRate?: Maybe<Scalars['Int']>;
  combinedDistrictRate?: Maybe<Scalars['Int']>;
  combinedRate?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  countryRate?: Maybe<Scalars['Int']>;
  county?: Maybe<Scalars['String']>;
  countyRate?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  freightTaxable?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  stateRate?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  zipcode: Scalars['Int'];
};

/** A filter to be used against `TaxRate` object types. All fields are combined with a logical ‘and.’ */
export type TaxRateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaxRateFilter>>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cityRate` field. */
  cityRate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `combinedDistrictRate` field. */
  combinedDistrictRate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `combinedRate` field. */
  combinedRate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<StringFilter>;
  /** Filter by the object’s `countryRate` field. */
  countryRate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `county` field. */
  county?: InputMaybe<StringFilter>;
  /** Filter by the object’s `countyRate` field. */
  countyRate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `freightTaxable` field. */
  freightTaxable?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaxRateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaxRateFilter>>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stateRate` field. */
  stateRate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `stripeId` field. */
  stripeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `zipcode` field. */
  zipcode?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TaxRate` */
export type TaxRateInput = {
  city?: InputMaybe<Scalars['String']>;
  cityRate?: InputMaybe<Scalars['Int']>;
  combinedDistrictRate?: InputMaybe<Scalars['Int']>;
  combinedRate?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  countryRate?: InputMaybe<Scalars['Int']>;
  county?: InputMaybe<Scalars['String']>;
  countyRate?: InputMaybe<Scalars['Int']>;
  freightTaxable?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  stateRate?: InputMaybe<Scalars['Int']>;
  stripeId?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['Int'];
};

/** Represents an update to a `TaxRate`. Fields that are set will be updated. */
export type TaxRatePatch = {
  city?: InputMaybe<Scalars['String']>;
  cityRate?: InputMaybe<Scalars['Int']>;
  combinedDistrictRate?: InputMaybe<Scalars['Int']>;
  combinedRate?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  countryRate?: InputMaybe<Scalars['Int']>;
  county?: InputMaybe<Scalars['String']>;
  countyRate?: InputMaybe<Scalars['Int']>;
  freightTaxable?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  stateRate?: InputMaybe<Scalars['Int']>;
  stripeId?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `TaxRate` values. */
export type TaxRatesConnection = {
  __typename?: 'TaxRatesConnection';
  /** A list of edges which contains the `TaxRate` and cursor to aid in pagination. */
  edges: Array<TaxRatesEdge>;
  /** A list of `TaxRate` objects. */
  nodes: Array<Maybe<TaxRate>>;
  /** The count of *all* `TaxRate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxRate` edge in the connection. */
export type TaxRatesEdge = {
  __typename?: 'TaxRatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxRate` at the end of the edge. */
  node?: Maybe<TaxRate>;
};

/** Methods to use when ordering `TaxRate`. */
export enum TaxRatesOrderBy {
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CityRateAsc = 'CITY_RATE_ASC',
  CityRateDesc = 'CITY_RATE_DESC',
  CombinedDistrictRateAsc = 'COMBINED_DISTRICT_RATE_ASC',
  CombinedDistrictRateDesc = 'COMBINED_DISTRICT_RATE_DESC',
  CombinedRateAsc = 'COMBINED_RATE_ASC',
  CombinedRateDesc = 'COMBINED_RATE_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CountryRateAsc = 'COUNTRY_RATE_ASC',
  CountryRateDesc = 'COUNTRY_RATE_DESC',
  CountyAsc = 'COUNTY_ASC',
  CountyDesc = 'COUNTY_DESC',
  CountyRateAsc = 'COUNTY_RATE_ASC',
  CountyRateDesc = 'COUNTY_RATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FreightTaxableAsc = 'FREIGHT_TAXABLE_ASC',
  FreightTaxableDesc = 'FREIGHT_TAXABLE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  StateRateAsc = 'STATE_RATE_ASC',
  StateRateDesc = 'STATE_RATE_DESC',
  StripeIdAsc = 'STRIPE_ID_ASC',
  StripeIdDesc = 'STRIPE_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ZipcodeAsc = 'ZIPCODE_ASC',
  ZipcodeDesc = 'ZIPCODE_DESC'
}

export type Terminal = {
  authKey: Scalars['String'];
  id: Scalars['String'];
  type?: InputMaybe<TerminalType>;
};

export type TerminalStatus = {
  __typename?: 'TerminalStatus';
  isOnline: Scalars['Boolean'];
};

export enum TerminalType {
  TransactFirst = 'TRANSACT_FIRST',
  Usag = 'USAG'
}

export enum TraceabilityProvider {
  BioTrack = 'BIO_TRACK',
  GrowFlow = 'GROW_FLOW',
  LeafData = 'LEAF_DATA',
  Metrc = 'METRC'
}

export type TraceabilityRoomTypeDto = {
  __typename?: 'TraceabilityRoomTypeDto';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isForHarvests: Scalars['Boolean'];
  isForPackages: Scalars['Boolean'];
  isForPlantBatches: Scalars['Boolean'];
  isForPlants: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type TraceabilityRoomTypeDtoConnection = {
  __typename?: 'TraceabilityRoomTypeDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TraceabilityRoomTypeDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TraceabilityRoomTypeDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TraceabilityRoomTypeDtoEdge = {
  __typename?: 'TraceabilityRoomTypeDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TraceabilityRoomTypeDto;
};

export type TraceabilityRoomTypeFilterInput = {
  and?: InputMaybe<Array<TraceabilityRoomTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isForHarvests?: InputMaybe<BooleanOperationFilterInput>;
  isForPackages?: InputMaybe<BooleanOperationFilterInput>;
  isForPlantBatches?: InputMaybe<BooleanOperationFilterInput>;
  isForPlants?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TraceabilityRoomTypeFilterInput>>;
};

export type TraceabilityRoomTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  isForHarvests?: InputMaybe<SortEnumType>;
  isForPackages?: InputMaybe<SortEnumType>;
  isForPlantBatches?: InputMaybe<SortEnumType>;
  isForPlants?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum TraceabilitySyncStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Synced = 'SYNCED',
  Unknown = 'UNKNOWN'
}

export type Transaction = Node & {
  __typename?: 'Transaction';
  /** Reads a single `PaytenderAccount` that is related to this `Transaction`. */
  account?: Maybe<PaytenderAccount>;
  accountId?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `ApiRequest`. */
  apiRequests: ApiRequestsConnection;
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  gateway: Gateway;
  id: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  orderReferenceNumber?: Maybe<Scalars['String']>;
  salesTax: Scalars['Int'];
  status: TransactionStatus;
  subtotal: Scalars['Int'];
  tip: Scalars['Int'];
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type TransactionApiRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ApiRequestCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ApiRequestsOrderBy>>;
};

/**
 * A condition to be used against `Transaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TransactionCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `errorMessage` field. */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gateway` field. */
  gateway?: InputMaybe<Gateway>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orderReferenceNumber` field. */
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `salesTax` field. */
  salesTax?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<TransactionStatus>;
  /** Checks for equality with the object’s `subtotal` field. */
  subtotal?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tip` field. */
  tip?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING'
}

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  __typename?: 'TransactionsConnection';
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  edges: Array<TransactionsEdge>;
  /** A list of `Transaction` objects. */
  nodes: Array<Transaction>;
  /** The count of *all* `Transaction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  __typename?: 'TransactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Transaction` at the end of the edge. */
  node: Transaction;
};

/** Methods to use when ordering `Transaction`. */
export enum TransactionsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  ErrorMessageAsc = 'ERROR_MESSAGE_ASC',
  ErrorMessageDesc = 'ERROR_MESSAGE_DESC',
  GatewayAsc = 'GATEWAY_ASC',
  GatewayDesc = 'GATEWAY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrderReferenceNumberAsc = 'ORDER_REFERENCE_NUMBER_ASC',
  OrderReferenceNumberDesc = 'ORDER_REFERENCE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SalesTaxAsc = 'SALES_TAX_ASC',
  SalesTaxDesc = 'SALES_TAX_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubtotalAsc = 'SUBTOTAL_ASC',
  SubtotalDesc = 'SUBTOTAL_DESC',
  TipAsc = 'TIP_ASC',
  TipDesc = 'TIP_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type TypeOfJurisdictionFilterInput = {
  and?: InputMaybe<Array<TypeOfJurisdictionFilterInput>>;
  complianceProvider?: InputMaybe<ComplianceProviderFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<JurisdictionOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeOfJurisdictionFilterInput>>;
  regionAbbreviation?: InputMaybe<StringOperationFilterInput>;
  regionName?: InputMaybe<StringOperationFilterInput>;
  traceabilityUrl?: InputMaybe<StringOperationFilterInput>;
};

export type TypeOfJurisdictionSortInput = {
  complianceProvider?: InputMaybe<ComplianceProviderSortInput>;
  createTimestamp?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  regionAbbreviation?: InputMaybe<SortEnumType>;
  regionName?: InputMaybe<SortEnumType>;
  traceabilityUrl?: InputMaybe<SortEnumType>;
};

export type TypeOfReportFilterInput = {
  and?: InputMaybe<Array<TypeOfReportFilterInput>>;
  group?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeOfReportFilterInput>>;
  shortName?: InputMaybe<StringOperationFilterInput>;
};

export type TypeOfReportSortInput = {
  group?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  shortName?: InputMaybe<SortEnumType>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

export type UndoDestroyInventoryInput = {
  complianceId?: InputMaybe<Scalars['String']>;
  inventoryId: Scalars['Uuid'];
  license?: InputMaybe<CannabisLicenseInput>;
  quantityToRestore: Scalars['Decimal'];
  wholesaleInventoryId: Scalars['Int'];
};

export type UndoDestroyPlantsInput = {
  license: CannabisLicenseInput;
  plants?: InputMaybe<Array<InputMaybe<PlantReferenceInput>>>;
};

export type UndoHarvestPackageInput = {
  harvestBatchId: Scalars['Int'];
  harvestName?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<CannabisLicenseInput>;
  packageReference?: InputMaybe<PackageReferenceInput>;
};

export type UndoManufacturedPackageInput = {
  license?: InputMaybe<CannabisLicenseInput>;
  package?: InputMaybe<PackageReferenceInput>;
  product?: InputMaybe<ProductReferenceInput>;
};

export type UndoSplitPackageForSaleInput = {
  inventoryId: Scalars['Uuid'];
  license?: InputMaybe<CannabisLicenseInput>;
  wholesaleInventoryId: Scalars['Int'];
};

export enum UnitOfMeasure {
  Ea = 'EA',
  FluidOunces = 'FLUID_OUNCES',
  Gallon = 'GALLON',
  Gm = 'GM',
  Kg = 'KG',
  Lb = 'LB',
  Liter = 'LITER',
  MetricTons = 'METRIC_TONS',
  Mg = 'MG',
  Milliliter = 'MILLILITER',
  Oz = 'OZ',
  Pints = 'PINTS',
  Quarts = 'QUARTS',
  Unknown = 'UNKNOWN'
}

export type UnitOfMeasureOperationFilterInput = {
  eq?: InputMaybe<UnitOfMeasure>;
  in?: InputMaybe<Array<UnitOfMeasure>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<UnitOfMeasure>;
  nin?: InputMaybe<Array<UnitOfMeasure>>;
};

/** All input for the `updateFeatureUsageLicenseByFeatureusageAndLicense` mutation. */
export type UpdateFeatureUsageLicenseByFeatureusageAndLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  featureusage: Scalars['Int'];
  license: Scalars['Int'];
  /** An object where the defined keys will be set on the `FeatureUsageLicense` being updated. */
  patch: FeatureUsageLicensePatch;
};

/** All input for the `updateFeatureUsageLicenseByNodeId` mutation. */
export type UpdateFeatureUsageLicenseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FeatureUsageLicense` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FeatureUsageLicense` being updated. */
  patch: FeatureUsageLicensePatch;
};

/** All input for the `updateFeatureUsageLicense` mutation. */
export type UpdateFeatureUsageLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `FeatureUsageLicense` being updated. */
  patch: FeatureUsageLicensePatch;
};

/** The output of our update `FeatureUsageLicense` mutation. */
export type UpdateFeatureUsageLicensePayload = {
  __typename?: 'UpdateFeatureUsageLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `FeatureUsage` that is related to this `FeatureUsageLicense`. */
  featureUsageByFeatureusage?: Maybe<FeatureUsage>;
  /** The `FeatureUsageLicense` that was updated by this mutation. */
  featureUsageLicense?: Maybe<FeatureUsageLicense>;
  /** An edge for our `FeatureUsageLicense`. May be used by Relay 1. */
  featureUsageLicenseEdge?: Maybe<FeatureUsageLicensesEdge>;
  /** Reads a single `License` that is related to this `FeatureUsageLicense`. */
  licenseByLicense?: Maybe<License>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `FeatureUsageLicense` mutation. */
export type UpdateFeatureUsageLicensePayloadFeatureUsageLicenseEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureUsageLicensesOrderBy>>;
};

/** All input for the `updateLicenseByNodeId` mutation. */
export type UpdateLicenseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `License` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `License` being updated. */
  patch: LicensePatch;
};

/** All input for the `updateLicense` mutation. */
export type UpdateLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `License` being updated. */
  patch: LicensePatch;
};

/** The output of our update `License` mutation. */
export type UpdateLicensePayload = {
  __typename?: 'UpdateLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `License` that was updated by this mutation. */
  license?: Maybe<License>;
  /** An edge for our `License`. May be used by Relay 1. */
  licenseEdge?: Maybe<LicensesEdge>;
  /** Reads a single `Organization` that is related to this `License`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `License` mutation. */
export type UpdateLicensePayloadLicenseEdgeArgs = {
  orderBy?: InputMaybe<Array<LicensesOrderBy>>;
};

/** All input for the `updateLockoutGracePeriodByNodeId` mutation. */
export type UpdateLockoutGracePeriodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LockoutGracePeriod` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LockoutGracePeriod` being updated. */
  patch: LockoutGracePeriodPatch;
};

/** All input for the `updateLockoutGracePeriod` mutation. */
export type UpdateLockoutGracePeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `LockoutGracePeriod` being updated. */
  patch: LockoutGracePeriodPatch;
};

/** The output of our update `LockoutGracePeriod` mutation. */
export type UpdateLockoutGracePeriodPayload = {
  __typename?: 'UpdateLockoutGracePeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `License` that is related to this `LockoutGracePeriod`. */
  licenseByLicense?: Maybe<License>;
  /** The `LockoutGracePeriod` that was updated by this mutation. */
  lockoutGracePeriod?: Maybe<LockoutGracePeriod>;
  /** An edge for our `LockoutGracePeriod`. May be used by Relay 1. */
  lockoutGracePeriodEdge?: Maybe<LockoutGracePeriodsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LockoutGracePeriod` mutation. */
export type UpdateLockoutGracePeriodPayloadLockoutGracePeriodEdgeArgs = {
  orderBy?: InputMaybe<Array<LockoutGracePeriodsOrderBy>>;
};

/** All input for the `updateOrganizationByEnvironmentId` mutation. */
export type UpdateOrganizationByEnvironmentIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId: Scalars['String'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** All input for the `updateOrganizationByNodeId` mutation. */
export type UpdateOrganizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Organization` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

export type UpdateOrganizationInput = {
  id: Scalars['Uuid'];
  organizationKey?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
};

/** All input for the `updateReupLineItemByNodeId` mutation. */
export type UpdateReupLineItemByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReupLineItem` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ReupLineItem` being updated. */
  patch: ReupLineItemPatch;
};

/** All input for the `updateReupLineItem` mutation. */
export type UpdateReupLineItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ReupLineItem` being updated. */
  patch: ReupLineItemPatch;
};

/** The output of our update `ReupLineItem` mutation. */
export type UpdateReupLineItemPayload = {
  __typename?: 'UpdateReupLineItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ReupPurchaseOrder` that is related to this `ReupLineItem`. */
  purchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupLineItem` that was updated by this mutation. */
  reupLineItem?: Maybe<ReupLineItem>;
  /** An edge for our `ReupLineItem`. May be used by Relay 1. */
  reupLineItemEdge?: Maybe<ReupLineItemsEdge>;
};


/** The output of our update `ReupLineItem` mutation. */
export type UpdateReupLineItemPayloadReupLineItemEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupLineItemsOrderBy>>;
};

/** All input for the `updateReupProductByNodeId` mutation. */
export type UpdateReupProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReupProduct` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ReupProduct` being updated. */
  patch: ReupProductPatch;
};

/** All input for the `updateReupProduct` mutation. */
export type UpdateReupProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ReupProduct` being updated. */
  patch: ReupProductPatch;
};

/** The output of our update `ReupProduct` mutation. */
export type UpdateReupProductPayload = {
  __typename?: 'UpdateReupProductPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupProduct` that was updated by this mutation. */
  reupProduct?: Maybe<ReupProduct>;
  /** An edge for our `ReupProduct`. May be used by Relay 1. */
  reupProductEdge?: Maybe<ReupProductsEdge>;
};


/** The output of our update `ReupProduct` mutation. */
export type UpdateReupProductPayloadReupProductEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupProductsOrderBy>>;
};

/** All input for the `updateReupPurchaseOrderByNodeId` mutation. */
export type UpdateReupPurchaseOrderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReupPurchaseOrder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ReupPurchaseOrder` being updated. */
  patch: ReupPurchaseOrderPatch;
};

/** All input for the `updateReupPurchaseOrder` mutation. */
export type UpdateReupPurchaseOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ReupPurchaseOrder` being updated. */
  patch: ReupPurchaseOrderPatch;
};

/** The output of our update `ReupPurchaseOrder` mutation. */
export type UpdateReupPurchaseOrderPayload = {
  __typename?: 'UpdateReupPurchaseOrderPayload';
  /** Reads a single `License` that is related to this `ReupPurchaseOrder`. */
  buyer?: Maybe<License>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReupPurchaseOrder` that was updated by this mutation. */
  reupPurchaseOrder?: Maybe<ReupPurchaseOrder>;
  /** An edge for our `ReupPurchaseOrder`. May be used by Relay 1. */
  reupPurchaseOrderEdge?: Maybe<ReupPurchaseOrdersEdge>;
};


/** The output of our update `ReupPurchaseOrder` mutation. */
export type UpdateReupPurchaseOrderPayloadReupPurchaseOrderEdgeArgs = {
  orderBy?: InputMaybe<Array<ReupPurchaseOrdersOrderBy>>;
};

export type UpdateStoreFrontUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
};

/** All input for the `updateTaxRateByNodeId` mutation. */
export type UpdateTaxRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TaxRate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TaxRate` being updated. */
  patch: TaxRatePatch;
};

/** All input for the `updateTaxRate` mutation. */
export type UpdateTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TaxRate` being updated. */
  patch: TaxRatePatch;
  zipcode: Scalars['Int'];
};

/** The output of our update `TaxRate` mutation. */
export type UpdateTaxRatePayload = {
  __typename?: 'UpdateTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was updated by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our update `TaxRate` mutation. */
export type UpdateTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/** All input for the `updateTenantOrganization` mutation. */
export type UpdateTenantOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** The output of our update `Organization` mutation. */
export type UpdateTenantOrganizationPayload = {
  __typename?: 'UpdateTenantOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was updated by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Organization` mutation. */
export type UpdateTenantOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `updateUserByGlobalUserId` mutation. */
export type UpdateUserByGlobalUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserLinkedIdentityByLinkedGlobalUserId` mutation. */
export type UpdateUserLinkedIdentityByLinkedGlobalUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A global_user_id that has been merged into the corresponding primary_global_user_id. A linked_global_user_id should only ever show up once and it should only be allowed to map to one primary_global_user_id. */
  linkedGlobalUserId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UserLinkedIdentity` being updated. */
  patch: UserLinkedIdentityPatch;
};

/** All input for the `updateUserLinkedIdentityByNodeId` mutation. */
export type UpdateUserLinkedIdentityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserLinkedIdentity` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserLinkedIdentity` being updated. */
  patch: UserLinkedIdentityPatch;
};

/** All input for the `updateUserLinkedIdentity` mutation. */
export type UpdateUserLinkedIdentityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UserLinkedIdentity` being updated. */
  patch: UserLinkedIdentityPatch;
};

/** The output of our update `UserLinkedIdentity` mutation. */
export type UpdateUserLinkedIdentityPayload = {
  __typename?: 'UpdateUserLinkedIdentityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserLinkedIdentity` that was updated by this mutation. */
  userLinkedIdentity?: Maybe<UserLinkedIdentity>;
  /** An edge for our `UserLinkedIdentity`. May be used by Relay 1. */
  userLinkedIdentityEdge?: Maybe<UserLinkedIdentitiesEdge>;
};


/** The output of our update `UserLinkedIdentity` mutation. */
export type UpdateUserLinkedIdentityPayloadUserLinkedIdentityEdgeArgs = {
  orderBy?: InputMaybe<Array<UserLinkedIdentitiesOrderBy>>;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type User = Node & {
  __typename?: 'User';
  /** All of this user's auth0 ids */
  auth0UserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['Datetime'];
  /** All of this user's emails */
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
  id: Scalars['Int'];
  linkedIdentities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Full name of the user */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Note`. */
  notes: NotesConnection;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  tenantSyncPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type UserNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

export type UserDto = {
  __typename?: 'UserDto';
  createTimestamp?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isGrowFlow: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `auth0UserIds` field. */
  auth0UserIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emails` field. */
  emails?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `globalUserId` field. */
  globalUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `tenantSyncPermissions` field. */
  tenantSyncPermissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type UserFilterInput = {
  accessFailedCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  accounts?: InputMaybe<ListFilterInputTypeOfAccountUserFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  isGrowFlow?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  storeComplianceId?: InputMaybe<StringOperationFilterInput>;
  storeFrontVendors?: InputMaybe<ListFilterInputTypeOfStoreFrontUserVendorFilterInput>;
  storeVerified?: InputMaybe<BooleanOperationFilterInput>;
  twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  /** All of this user's auth0 ids */
  auth0UserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All of this user's emails */
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
  /** Full name of the user */
  name: Scalars['String'];
  notesUsingId?: InputMaybe<NotesUserIdFkeyInverseInput>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tenantSyncPermissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserLicense = {
  __typename?: 'UserLicense';
  LicenseName?: Maybe<Scalars['String']>;
  LicenseNumber?: Maybe<Scalars['String']>;
};

/** A connection to a list of `UserLinkedIdentity` values. */
export type UserLinkedIdentitiesConnection = {
  __typename?: 'UserLinkedIdentitiesConnection';
  /** A list of edges which contains the `UserLinkedIdentity` and cursor to aid in pagination. */
  edges: Array<UserLinkedIdentitiesEdge>;
  /** A list of `UserLinkedIdentity` objects. */
  nodes: Array<Maybe<UserLinkedIdentity>>;
  /** The count of *all* `UserLinkedIdentity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserLinkedIdentity` edge in the connection. */
export type UserLinkedIdentitiesEdge = {
  __typename?: 'UserLinkedIdentitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserLinkedIdentity` at the end of the edge. */
  node?: Maybe<UserLinkedIdentity>;
};

/** Methods to use when ordering `UserLinkedIdentity`. */
export enum UserLinkedIdentitiesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LinkedGlobalUserIdAsc = 'LINKED_GLOBAL_USER_ID_ASC',
  LinkedGlobalUserIdDesc = 'LINKED_GLOBAL_USER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryGlobalUserIdAsc = 'PRIMARY_GLOBAL_USER_ID_ASC',
  PrimaryGlobalUserIdDesc = 'PRIMARY_GLOBAL_USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** @omit\nThis table houses a mapping for merged users. It maps the legacy/collapsed user's global_user_id to the new global_user_id. */
export type UserLinkedIdentity = Node & {
  __typename?: 'UserLinkedIdentity';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A global_user_id that has been merged into the corresponding primary_global_user_id. A linked_global_user_id should only ever show up once and it should only be allowed to map to one primary_global_user_id. */
  linkedGlobalUserId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** A global_user_id that is currently in use. A primary_global_user_id can show up more than once because multiple linked_global_user_ids can map to it. */
  primaryGlobalUserId: Scalars['UUID'];
};

/** A filter to be used against `UserLinkedIdentity` object types. All fields are combined with a logical ‘and.’ */
export type UserLinkedIdentityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserLinkedIdentityFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `linkedGlobalUserId` field. */
  linkedGlobalUserId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserLinkedIdentityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserLinkedIdentityFilter>>;
  /** Filter by the object’s `primaryGlobalUserId` field. */
  primaryGlobalUserId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `UserLinkedIdentity` */
export type UserLinkedIdentityInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A global_user_id that has been merged into the corresponding primary_global_user_id. A linked_global_user_id should only ever show up once and it should only be allowed to map to one primary_global_user_id. */
  linkedGlobalUserId: Scalars['UUID'];
  /** A global_user_id that is currently in use. A primary_global_user_id can show up more than once because multiple linked_global_user_ids can map to it. */
  primaryGlobalUserId: Scalars['UUID'];
};

/** Represents an update to a `UserLinkedIdentity`. Fields that are set will be updated. */
export type UserLinkedIdentityPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A global_user_id that has been merged into the corresponding primary_global_user_id. A linked_global_user_id should only ever show up once and it should only be allowed to map to one primary_global_user_id. */
  linkedGlobalUserId?: InputMaybe<Scalars['UUID']>;
  /** A global_user_id that is currently in use. A primary_global_user_id can show up more than once because multiple linked_global_user_ids can map to it. */
  primaryGlobalUserId?: InputMaybe<Scalars['UUID']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `user` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnNoteForNotesUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `note` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `note` being updated. */
  patch: NotePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnNoteForNotesUserIdFkeyUsingUsersGlobalUserIdKeyUpdate = {
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnNoteForNotesUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnNoteForNotesUserIdFkeyUsingUsersPkeyUpdate = {
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnNoteForNotesUserIdFkeyPatch;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  /** All of this user's auth0 ids */
  auth0UserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All of this user's emails */
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** This is also-known-as global_analytics_id */
  globalUserId?: InputMaybe<Scalars['UUID']>;
  /** Full name of the user */
  name?: InputMaybe<Scalars['String']>;
  notesUsingId?: InputMaybe<NotesUserIdFkeyInverseInput>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tenantSyncPermissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserRoleDto = {
  __typename?: 'UserRoleDto';
  licenseNumber?: Maybe<Scalars['String']>;
  roleNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  employee?: InputMaybe<EmployeeSortInput>;
  employeeId?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isGrowFlow?: InputMaybe<SortEnumType>;
  lastLogin?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  storeComplianceId?: InputMaybe<SortEnumType>;
  storeVerified?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

/** The fields on `user` to look up the row to connect. */
export type UserUsersGlobalUserIdKeyConnect = {
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
};

/** The fields on `user` to look up the row to delete. */
export type UserUsersGlobalUserIdKeyDelete = {
  /** This is also-known-as global_analytics_id */
  globalUserId: Scalars['UUID'];
};

/** The fields on `user` to look up the row to connect. */
export type UserUsersPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `user` to look up the row to delete. */
export type UserUsersPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Auth0UserIdsAsc = 'AUTH0_USER_IDS_ASC',
  Auth0UserIdsDesc = 'AUTH0_USER_IDS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailsAsc = 'EMAILS_ASC',
  EmailsDesc = 'EMAILS_DESC',
  GlobalUserIdAsc = 'GLOBAL_USER_ID_ASC',
  GlobalUserIdDesc = 'GLOBAL_USER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PermissionsAsc = 'PERMISSIONS_ASC',
  PermissionsDesc = 'PERMISSIONS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TenantSyncPermissionsAsc = 'TENANT_SYNC_PERMISSIONS_ASC',
  TenantSyncPermissionsDesc = 'TENANT_SYNC_PERMISSIONS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type VehicleCreateDtoInput = {
  color?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  plate?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type VehicleDto = {
  __typename?: 'VehicleDto';
  color?: Maybe<Scalars['String']>;
  complianceId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type VehicleDtoConnection = {
  __typename?: 'VehicleDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VehicleDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VehicleDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VehicleDtoEdge = {
  __typename?: 'VehicleDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VehicleDto;
};

export type VehicleFilterInput = {
  and?: InputMaybe<Array<VehicleFilterInput>>;
  color?: InputMaybe<StringOperationFilterInput>;
  complianceId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  make?: InputMaybe<StringOperationFilterInput>;
  model?: InputMaybe<StringOperationFilterInput>;
  nickname?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VehicleFilterInput>>;
  plate?: InputMaybe<StringOperationFilterInput>;
  vendorId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  vin?: InputMaybe<StringOperationFilterInput>;
  year?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type VehicleSortInput = {
  color?: InputMaybe<SortEnumType>;
  complianceId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  make?: InputMaybe<SortEnumType>;
  model?: InputMaybe<SortEnumType>;
  nickname?: InputMaybe<SortEnumType>;
  plate?: InputMaybe<SortEnumType>;
  vendorId?: InputMaybe<SortEnumType>;
  vin?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type VehicleUpdateDtoInput = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  plate?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  City?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ComplianceId?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  CreateBy?: Maybe<Scalars['String']>;
  CreateTimestamp?: Maybe<Scalars['String']>;
  DBA?: Maybe<Scalars['String']>;
  ExpiredDate?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  IsDeleted?: Maybe<Scalars['Boolean']>;
  IsGrowFlow?: Maybe<Scalars['Boolean']>;
  IsLab?: Maybe<Scalars['Boolean']>;
  IsLicenseActiveOnTraceability?: Maybe<Scalars['Boolean']>;
  IsProcessor?: Maybe<Scalars['Boolean']>;
  IsProducer?: Maybe<Scalars['Boolean']>;
  IsRetail?: Maybe<Scalars['Boolean']>;
  IsTraining?: Maybe<Scalars['Boolean']>;
  IsTransporter?: Maybe<Scalars['Boolean']>;
  Jurisdiction?: Maybe<Scalars['Int']>;
  Latitude?: Maybe<Scalars['Float']>;
  LicenseStatus?: Maybe<Scalars['Int']>;
  LicenseType?: Maybe<Scalars['String']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Int']>;
  Type?: Maybe<Scalars['Int']>;
  TypeOfJurisdictionId?: Maybe<Scalars['Int']>;
  UBI16?: Maybe<Scalars['String']>;
  UpdateBy?: Maybe<Scalars['String']>;
  UpdateTimestamp?: Maybe<Scalars['String']>;
};

export type VendorDto = {
  __typename?: 'VendorDto';
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteTimestamp?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  isTransportAllowed?: Maybe<Scalars['Boolean']>;
  jurisdiction?: Maybe<JurisdictionDto>;
  lastOrder?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<LicenseTypeDto>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  pricingId?: Maybe<Scalars['Int']>;
  qBCustomerId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  ubi?: Maybe<Scalars['String']>;
  ubi16?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['String']>;
  updateTimestamp?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type VendorDtoConnection = {
  __typename?: 'VendorDtoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VendorDtoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VendorDto>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VendorDtoEdge = {
  __typename?: 'VendorDtoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VendorDto;
};

export type VendorFilterInput = {
  and?: InputMaybe<Array<VendorFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createBy?: InputMaybe<StringOperationFilterInput>;
  createTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deleteBy?: InputMaybe<StringOperationFilterInput>;
  deleteTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  globalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  jurisdiction?: InputMaybe<JurisdictionOperationFilterInput>;
  licenseNumber?: InputMaybe<StringOperationFilterInput>;
  line1?: InputMaybe<StringOperationFilterInput>;
  line2?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VendorFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postalCode?: InputMaybe<StringOperationFilterInput>;
  qBCustomerId?: InputMaybe<StringOperationFilterInput>;
  region?: InputMaybe<StringOperationFilterInput>;
  updateBy?: InputMaybe<StringOperationFilterInput>;
  updateTimestamp?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type VendorSortInput = {
  city?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  createBy?: InputMaybe<SortEnumType>;
  createTimestamp?: InputMaybe<SortEnumType>;
  deleteBy?: InputMaybe<SortEnumType>;
  deleteTimestamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  globalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  jurisdiction?: InputMaybe<SortEnumType>;
  licenseNumber?: InputMaybe<SortEnumType>;
  line1?: InputMaybe<SortEnumType>;
  line2?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  postalCode?: InputMaybe<SortEnumType>;
  qBCustomerId?: InputMaybe<SortEnumType>;
  region?: InputMaybe<SortEnumType>;
  updateBy?: InputMaybe<SortEnumType>;
  updateTimestamp?: InputMaybe<SortEnumType>;
};

export type VerifyUserEmailPayload = {
  __typename?: 'VerifyUserEmailPayload';
  verificationEmailSent: Scalars['Boolean'];
};

export type Where = {
  fieldPath?: InputMaybe<Scalars['String']>;
  opStr?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type WholesaleLicense = {
  __typename?: 'WholesaleLicense';
  AccountId?: Maybe<Scalars['String']>;
  LicenseNumber?: Maybe<Scalars['String']>;
  Roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WholesaleOrganization = {
  __typename?: 'WholesaleOrganization';
  JurisdictionId?: Maybe<Scalars['Int']>;
  Licenses?: Maybe<Array<Maybe<WholesaleLicense>>>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationKey?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `feature` being updated. */
export type UpdateFeatureOnPriceForPricesFeatureIdFkeyPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  includedLicenseCount?: InputMaybe<Scalars['Int']>;
  jurisdiction?: InputMaybe<Scalars['String']>;
  livemode?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  planType?: InputMaybe<PlanType>;
  pricesUsingId?: InputMaybe<PricesFeatureIdFkeyInverseInput>;
  productType?: InputMaybe<ProductType>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
export type UpdateFeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch = {
  featureUsageToFeatureusage?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInput>;
  license?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<FeatureUsageLicenseLicenseFkeyInput>;
};

/** An object where the defined keys will be set on the `featureUsageLicense` being updated. */
export type UpdateFeatureUsageLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyPatch = {
  featureUsageToFeatureusage?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInput>;
  featureusage?: InputMaybe<Scalars['Int']>;
  licenseToLicense?: InputMaybe<FeatureUsageLicenseLicenseFkeyInput>;
};

/** An object where the defined keys will be set on the `featureUsage` being updated. */
export type UpdateFeatureUsageOnFeatureUsageForFeatureUsagesPriceIdFkeyPatch = {
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInverseInput>;
  override?: InputMaybe<Scalars['Int']>;
  priceToPriceId?: InputMaybe<FeatureUsagesPriceIdFkeyInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['Int']>;
  stripeSubscriptionToStripeSubscriptionId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInput>;
};

/** An object where the defined keys will be set on the `featureUsage` being updated. */
export type UpdateFeatureUsageOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch = {
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInverseInput>;
  override?: InputMaybe<Scalars['Int']>;
  priceId?: InputMaybe<Scalars['String']>;
  priceToPriceId?: InputMaybe<FeatureUsagesPriceIdFkeyInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionToStripeSubscriptionId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInput>;
};

/** An object where the defined keys will be set on the `featureUsage` being updated. */
export type UpdateFeatureUsageOnFeatureUsageLicenseForFeatureUsageLicenseFeatureusageFkeyPatch = {
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseFeatureusageFkeyInverseInput>;
  override?: InputMaybe<Scalars['Int']>;
  priceId?: InputMaybe<Scalars['String']>;
  priceToPriceId?: InputMaybe<FeatureUsagesPriceIdFkeyInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['Int']>;
  stripeSubscriptionToStripeSubscriptionId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInput>;
};

/** An object where the defined keys will be set on the `license` being updated. */
export type UpdateLicenseOnFeatureUsageLicenseForFeatureUsageLicenseLicenseFkeyPatch = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId?: InputMaybe<Scalars['String']>;
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `license` being updated. */
export type UpdateLicenseOnLicenseForLicensesOrganizationId2FkeyPatch = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId?: InputMaybe<Scalars['String']>;
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `license` being updated. */
export type UpdateLicenseOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyPatch = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId?: InputMaybe<Scalars['String']>;
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `license` being updated. */
export type UpdateLicenseOnProductUsageStatForProductUsageStatsLicenseIdFkeyPatch = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId?: InputMaybe<Scalars['String']>;
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `license` being updated. */
export type UpdateLicenseOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyPatch = {
  /** This field represents a license account id for wholesale organizations or a store id for retail organizations */
  accountId?: InputMaybe<Scalars['String']>;
  activationStatus?: InputMaybe<LicenseActivationStatus>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  featureUsageLicensesUsingId?: InputMaybe<FeatureUsageLicenseLicenseFkeyInverseInput>;
  licenseName?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  lockoutGracePeriodsUsingId?: InputMaybe<LockoutGracePeriodLicenseFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<LicensesOrganizationId2FkeyInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
  productUsageStatsUsingId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInverseInput>;
  reupPurchaseOrdersUsingId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInverseInput>;
  state?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `lockoutGracePeriod` being updated. */
export type UpdateLockoutGracePeriodOnLockoutGracePeriodForLockoutGracePeriodLicenseFkeyPatch = {
  gracePeriodEndAt?: InputMaybe<Scalars['Datetime']>;
  gracePeriodStartAt?: InputMaybe<Scalars['Datetime']>;
  licenseToLicense?: InputMaybe<LockoutGracePeriodLicenseFkeyInput>;
  lockoutBehavior?: InputMaybe<LockoutGracePeriodBehavior>;
  note?: InputMaybe<Scalars['String']>;
  requestor?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `note` being updated. */
export type UpdateNoteOnNoteForNotesOrganizationId2FkeyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  note?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<NotesOrganizationId2FkeyInput>;
  userId?: InputMaybe<Scalars['Int']>;
  userToUserId?: InputMaybe<NotesUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `note` being updated. */
export type UpdateNoteOnNoteForNotesUserIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  note?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<NotesOrganizationId2FkeyInput>;
  userToUserId?: InputMaybe<NotesUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnLicenseForLicensesOrganizationId2FkeyPatch = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnNoteForNotesOrganizationId2FkeyPatch = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnProductUsageStatForProductUsageStatsOrganizationId2FkeyPatch = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch = {
  _initialMigrationNeedsManualDataResolution?: InputMaybe<Scalars['Boolean']>;
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** This field represents the database id/environment name for a specific application (ex. wholesale organization db row ID or retail organization mongo database name) */
  environmentId?: InputMaybe<Scalars['String']>;
  /** ID for the customer in external billing system (Recurly) */
  externalCustomerId?: InputMaybe<Scalars['String']>;
  historicalStripeCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  licensesUsingKey?: InputMaybe<LicensesOrganizationId2FkeyInverseInput>;
  name?: InputMaybe<Scalars['String']>;
  notesUsingKey?: InputMaybe<NotesOrganizationId2FkeyInverseInput>;
  productUsageStatsUsingKey?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInverseInput>;
  /** The Stripe customer ID for this organization */
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionsUsingKey?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInverseInput>;
};

/** An object where the defined keys will be set on the `price` being updated. */
export type UpdatePriceOnFeatureUsageForFeatureUsagesPriceIdFkeyPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureId?: InputMaybe<Scalars['String']>;
  featureToFeatureId?: InputMaybe<PricesFeatureIdFkeyInput>;
  featureUsagesUsingId?: InputMaybe<FeatureUsagesPriceIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['String']>;
  livemode?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  recurringIntervalAmount?: InputMaybe<Scalars['Int']>;
  unitAmount?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `price` being updated. */
export type UpdatePriceOnPriceForPricesFeatureIdFkeyPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  featureToFeatureId?: InputMaybe<PricesFeatureIdFkeyInput>;
  featureUsagesUsingId?: InputMaybe<FeatureUsagesPriceIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['String']>;
  livemode?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  recurringInterval?: InputMaybe<Scalars['String']>;
  recurringIntervalAmount?: InputMaybe<Scalars['Int']>;
  unitAmount?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `productUsageStat` being updated. */
export type UpdateProductUsageStatOnProductUsageStatForProductUsageStatsLicenseIdFkeyPatch = {
  activeUsers?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  licenseToLicenseId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInput>;
  salesCents?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `productUsageStat` being updated. */
export type UpdateProductUsageStatOnProductUsageStatForProductUsageStatsOrganizationId2FkeyPatch = {
  activeUsers?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  licenseId?: InputMaybe<Scalars['Int']>;
  licenseToLicenseId?: InputMaybe<ProductUsageStatsLicenseIdFkeyInput>;
  organizationToOrganizationId?: InputMaybe<ProductUsageStatsOrganizationId2FkeyInput>;
  salesCents?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `reupLineItem` being updated. */
export type UpdateReupLineItemOnReupLineItemForLineItemsPurchaseOrderIdFkeyPatch = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderSessionId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  receivedQuantity?: InputMaybe<Scalars['Int']>;
  requestedQuantity?: InputMaybe<Scalars['Int']>;
  requiredQuantity?: InputMaybe<Scalars['Int']>;
  reupPurchaseOrderToPurchaseOrderId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInput>;
  suggestedQuantity?: InputMaybe<Scalars['Int']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
  unitPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** An object where the defined keys will be set on the `reupPurchaseOrder` being updated. */
export type UpdateReupPurchaseOrderOnReupLineItemForLineItemsPurchaseOrderIdFkeyPatch = {
  buyerId?: InputMaybe<Scalars['Int']>;
  displayPoNumber?: InputMaybe<Scalars['String']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  licenseToBuyerId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInput>;
  matchedTransferId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  note?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  reupLineItemsUsingId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInverseInput>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** An object where the defined keys will be set on the `reupPurchaseOrder` being updated. */
export type UpdateReupPurchaseOrderOnReupPurchaseOrderForPurchaseOrdersBuyerIdFkeyPatch = {
  displayPoNumber?: InputMaybe<Scalars['String']>;
  externalReferenceNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  licenseToBuyerId?: InputMaybe<PurchaseOrdersBuyerIdFkeyInput>;
  matchedTransferId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  note?: InputMaybe<Scalars['String']>;
  organizationKey?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  reupLineItemsUsingId?: InputMaybe<LineItemsPurchaseOrderIdFkeyInverseInput>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['BigFloat']>;
};

/** An object where the defined keys will be set on the `stripeSubscription` being updated. */
export type UpdateStripeSubscriptionOnFeatureUsageForFeatureUsagesStripeSubscriptionIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** ID for the subscription in external billing system (Recurly) */
  externalId?: InputMaybe<Scalars['String']>;
  featureUsagesUsingId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationToOrganizationId?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInput>;
  pauseBehavior?: InputMaybe<Scalars['String']>;
  pauseReason?: InputMaybe<Scalars['String']>;
  pauseResumesAt?: InputMaybe<Scalars['Datetime']>;
  pausedAt?: InputMaybe<Scalars['Datetime']>;
  scheduleId?: InputMaybe<Scalars['String']>;
  stripeId?: InputMaybe<Scalars['String']>;
  trialEnd?: InputMaybe<Scalars['Datetime']>;
  trialStart?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `stripeSubscription` being updated. */
export type UpdateStripeSubscriptionOnStripeSubscriptionForStripeSubscriptionsOrganizationId2FkeyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** ID for the subscription in external billing system (Recurly) */
  externalId?: InputMaybe<Scalars['String']>;
  featureUsagesUsingId?: InputMaybe<FeatureUsagesStripeSubscriptionIdFkeyInverseInput>;
  organizationToOrganizationId?: InputMaybe<StripeSubscriptionsOrganizationId2FkeyInput>;
  pauseBehavior?: InputMaybe<Scalars['String']>;
  pauseReason?: InputMaybe<Scalars['String']>;
  pauseResumesAt?: InputMaybe<Scalars['Datetime']>;
  pausedAt?: InputMaybe<Scalars['Datetime']>;
  scheduleId?: InputMaybe<Scalars['String']>;
  stripeId?: InputMaybe<Scalars['String']>;
  trialEnd?: InputMaybe<Scalars['Datetime']>;
  trialStart?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnNoteForNotesUserIdFkeyPatch = {
  /** All of this user's auth0 ids */
  auth0UserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All of this user's emails */
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** This is also-known-as global_analytics_id */
  globalUserId?: InputMaybe<Scalars['UUID']>;
  /** Full name of the user */
  name?: InputMaybe<Scalars['String']>;
  notesUsingId?: InputMaybe<NotesUserIdFkeyInverseInput>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tenantSyncPermissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateReupPurchaseOrderMutationVariables = Exact<{
  input: CreateReupPurchaseOrderInput;
}>;


export type CreateReupPurchaseOrderMutation = { __typename?: 'Mutation', purchaseOrder?: { __typename?: 'CreateReupPurchaseOrderPayload', buyer?: { __typename?: 'License', id: number } | null, reupPurchaseOrder?: { __typename?: 'ReupPurchaseOrder', id: number, poNumber?: string | null, displayPoNumber?: string | null, supplierId?: string | null, supplierName?: string | null, note?: string | null, totalPrice?: unknown | null, createdAt: string, updatedAt?: string | null, buyer?: { __typename?: 'License', id: number, accountId: string, licenseName: string, licenseNumber: string } | null } | null } | null };

export type GetReupPurchaseOrdersQueryVariables = Exact<{
  poFilter?: InputMaybe<ReupPurchaseOrderFilter>;
}>;


export type GetReupPurchaseOrdersQuery = { __typename?: 'Query', purchaseOrders?: { __typename?: 'ReupPurchaseOrdersConnection', nodes: Array<{ __typename?: 'ReupPurchaseOrder', id: number, supplierId?: string | null, supplierName?: string | null, poNumber?: string | null, status?: PurchaseOrderStatus | null, displayPoNumber?: string | null, note?: string | null, buyerId?: number | null, totalPrice?: unknown | null, createdAt: string, updatedAt?: string | null, organizationKey?: string | null, buyer?: { __typename?: 'License', id: number, licenseName: string, licenseNumber: string } | null, reupLineItems: { __typename?: 'ReupLineItemsConnection', nodes: Array<{ __typename?: 'ReupLineItem', id: number, unitPrice?: unknown | null, isDeleted?: boolean | null, totalPrice?: unknown | null, purchaseOrderId: number, requiredQuantity?: number | null, requestedQuantity?: number | null, suggestedQuantity?: number | null, productId: string, productName?: string | null } | null> } } | null> } | null };

export type SaveReupDraftOrdersMutationVariables = Exact<{
  input: Array<InputMaybe<ReupOrderInput>> | InputMaybe<ReupOrderInput>;
}>;


export type SaveReupDraftOrdersMutation = { __typename?: 'Mutation', purchaseOrders?: Array<{ __typename?: 'ReupPurchaseOrder', id: number, poNumber?: string | null, displayPoNumber?: string | null, createdAt: string, updatedAt?: string | null, supplierId?: string | null, supplierName?: string | null, isDeleted?: boolean | null, status?: PurchaseOrderStatus | null, buyer?: { __typename?: 'License', licenseName: string, licenseNumber: string, id: number } | null, reupLineItems: { __typename?: 'ReupLineItemsConnection', nodes: Array<{ __typename?: 'ReupLineItem', id: number, unitPrice?: unknown | null, totalPrice?: unknown | null, purchaseOrderId: number, requiredQuantity?: number | null, requestedQuantity?: number | null, suggestedQuantity?: number | null, productId: string, productName?: string | null } | null> } } | null> | null };

export type UpdatePurchaseOrderMutationVariables = Exact<{
  input: UpdateReupPurchaseOrderInput;
}>;


export type UpdatePurchaseOrderMutation = { __typename?: 'Mutation', purchaseOrderQuery?: { __typename?: 'UpdateReupPurchaseOrderPayload', reupPurchaseOrder?: { __typename?: 'ReupPurchaseOrder', id: number, reupLineItems: { __typename?: 'ReupLineItemsConnection', nodes: Array<{ __typename?: 'ReupLineItem', id: number } | null> } } | null } | null };

export type CreateLineItemMutationVariables = Exact<{
  input: CreateReupLineItemInput;
}>;


export type CreateLineItemMutation = { __typename?: 'Mutation', lineItem?: { __typename?: 'CreateReupLineItemPayload', reupLineItem?: { __typename?: 'ReupLineItem', id: number, productId: string, purchaseOrderId: number, requestedQuantity?: number | null } | null } | null };

export type UpdateLineItemMutationVariables = Exact<{
  input: UpdateReupLineItemInput;
}>;


export type UpdateLineItemMutation = { __typename?: 'Mutation', lineItem?: { __typename?: 'UpdateReupLineItemPayload', reupLineItemEdge?: { __typename?: 'ReupLineItemsEdge', node?: { __typename?: 'ReupLineItem', id: number, requestedQuantity?: number | null, purchaseOrder?: { __typename?: 'ReupPurchaseOrder', id: number } | null } | null } | null } | null };

export type GetLicensesQueryVariables = Exact<{
  filter?: InputMaybe<LicenseFilter>;
}>;


export type GetLicensesQuery = { __typename?: 'Query', licenses?: { __typename?: 'LicensesConnection', nodes: Array<{ __typename?: 'License', id: number, licenseName: string, licenseNumber: string, createdAt: string, city?: string | null, postalCode?: string | null, accountId: string, productType: ProductType, addressLine1?: string | null, addressLine2?: string | null, activationStatus?: LicenseActivationStatus | null } | null> } | null };

export type GetLowStockProductsQueryVariables = Exact<{
  orgkey: Scalars['String'];
  storekey: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetLowStockProductsQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Reup_Product', key: string, ProductName: string, QuantityOnHand?: number | null, DefaultEstimatedStockOut?: string | null, EstimatedDailyLoss?: number | null }> | null };

export type GetReupProductsQueryVariables = Exact<{
  orgkey: Scalars['String'];
  storekey: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetReupProductsQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Reup_Product', key: string, OrganizationKey: string, StoreId: string, StoreName: string, LicenseNum?: string | null, ProductName: string, CategoryType: string, StrainType?: string | null, StoreKey: string, CustomCategory?: string | null, QuantityOnHand?: number | null, Velocity_12Weeks?: number | null, Velocity_2Weeks?: number | null, Velocity_1Week?: number | null, Velocity_7Days_Actual?: number | null, EstimatedStockOut_1Week?: string | null, EstimatedStockOut_2Weeks?: string | null, EstimatedStockOut_12Weeks?: string | null, DefaultEstimatedStockOut?: string | null, EstimatedDailyLoss?: number | null, UnitPrice?: number | null, MedianPrice?: number | null, Suppliers?: Array<{ __typename?: 'Reup_ProductSupplier', SupplierId: string, SupplierName: string, SupplierLicenseNumber?: string | null, UnitCost?: number | null }> | null }> | null };

export type GetReupSuppliersQueryVariables = Exact<{
  orgkey: Scalars['String'];
}>;


export type GetReupSuppliersQuery = { __typename?: 'Query', suppliers?: Array<{ __typename?: 'Reup_Supplier', SupplierId: string, SupplierName: string, SupplierLicenseNumber?: string | null }> | null };
